import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import { sliderrigtside } from '../data/Owlcarouseloptions';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/PaymentDetails.css';
import { Domain_Url } from '../components/Url/Url';

const PaymentDetails = () => {
  return (
    <>
      {/* ========= */}
   
    {/* ====== */}
       {/* wallet section start */}
       <section className="wallet_section">
        <div className="container">
            {/* row start */}
            <div className="row">



               {/*=====================col start right side col end 9 =============================*/}
               <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                 <div className="payment_details_main_div">
                    <form action="">
                   <h1>Payment Details</h1>
                   {/* table div payment  */}
                   <div className="payment_dt_flx_div">

                     {/* flx item div */}
                     <div className="payment_flex_itmes">
                        <h3>Total Amount</h3>
                        <h4>&#8377; 25.00</h4>
                    </div>
                     {/* flx item div end */}

                      {/* flx item div */}
                      <div className="payment_flex_itmes">
                        <h3>Total Amount</h3>
                        <h4>&#8377; 25.00</h4>
                    </div>
                     {/* flx item div end */}


                      {/* flx item div */}
                      <div className="payment_flex_itmes">
                        <h3>Total Amount</h3>
                        <h4>&#8377; 25.00</h4>
                    </div>
                     {/* flx item div end */}


                      {/* flx item div */}
                      <div className="payment_flex_itmes">
                        <h3>Total Amount</h3>
                        <h4>&#8377; 25.00</h4>
                    </div>
                     {/* flx item div end */}


                      {/* flx item div */}
                      <div className="payment_flex_itmes">
                        <h3>Total Amount</h3>
                        <h4>&#8377; 25.00</h4>
                    </div>
                     {/* flx item div end */}

                   </div>

<div className="payment_pay_btn_div">
    <Link to="">Pay Now !</Link>
</div>

                   {/* table div payment end */}
                   </form>
                 </div>
               </div>
               {/*=====================col start right side col end 9 =============================*/}


{/* ===================col start right side 3 col========================  */}
<div className="col-lg-3 col-md-3 col-sm-12 col-12">
  <div className="right_side_main_div_sticky mb-2">

    {/* slider start */}
    <div className="sidebar_main_div_sld">
  <OwlCarousel className="owl-theme" {...sliderrigtside}>


{/* item div start */}
<div className="item">
<div className="sideadd_img_box">
        <Link to="/horoscope/today-horoscope"><img src="../../images/sld03.png" alt="astrologer"/></Link> 
    </div> 
</div>
{/* item div end */}

{/* item div start */}
<div className="item">
<div className="sideadd_img_box">
        <Link to="/panchang"><img src="../../images/sld04.png" alt="astrologer"/></Link> 
    </div> 
</div>
{/* item div end */}


{/* item div start */}
<div className="item">
<div className="sideadd_img_box">
        <Link to="/kundli-matching"><img src="../../images/sld05.png" alt="astrologer"/></Link> 
    </div> 
</div>
{/* item div end */}

</OwlCarousel>
</div>
  {/* slider end */}

  {/* slider start */}
  <div className="sidebar_main_div_sld2">
  <OwlCarousel className="owl-theme" {...sliderrigtside}>
{/* item div start */}
<div className="item">
<div className="sideadd_img_box">
        <Link to="/horoscope/today-horoscope"><img src="../../images/small01.png" alt="today horoscope"/></Link> 
    </div> 
</div>
{/* item div end */}


{/* item div start */}
<div className="item">
<div className="sideadd_img_box">
        <Link to="/kundli-matching"><img src="../../images/small03.png" alt="kundlimatching"/></Link> 
    </div> 
</div>
{/* item div end */}


{/* item div start */}
<div className="item">
<div className="sideadd_img_box">
        <Link to="/panchang"><img src="../../images/small04.png" alt="panchang"/></Link> 
    </div> 
</div>
{/* item div end */}
</OwlCarousel>
</div>
  {/* slider end */}

  
  {/* app div */}
  <div className="app_download">
    <h2>TalkndHeal on Mobile</h2>
    <a href>Download Now</a>
  </div>
  {/*  */}
  {/* left icons start */}
  <div className="left_icons_so">
  <Link target="_blank" to="https://www.facebook.com/talkndhealofficial/"><img src={`${Domain_Url}/images/fb.png`} alt="Facebook" /></Link>
    <Link target="_blank" to="https://www.instagram.com/talkndhealdotcom/"><img src={`${Domain_Url}/images/insta.png`} alt="Instagram" /></Link>
    <Link target="_blank" to="https://x.com/TalkndHeal"><img src={`${Domain_Url}/images/twitter.png`} alt="Twitter" /></Link>
    <Link target="_blank" to="https://www.linkedin.com/company/talk-nd-heal/"><img src={`${Domain_Url}/images/linkedin.png`} alt="LinkedIn" /></Link>
    <Link target="_blank" to="https://www.youtube.com/@TalkndHeal"><img src={`${Domain_Url}/images/yt.png`} alt="Youtube" /></Link> 

  
    <Link className='tooltip-container' to="javascript:void(0);">
    <img src={`${Domain_Url}/images/android.png`} alt="Android App" />
    <span className="tooltip-text">Coming Soon...</span>
    </Link>
    <Link className='tooltip-container' to="javascript:void(0);">
    <img src={`${Domain_Url}/images/apple.png`} alt="iOS App" />
    <span className="tooltip-text">Coming Soon...</span>
    </Link>
   

  </div>
  {/* left icons end */}
  </div>
</div>
{/* ===================col end right side 3 col========================  */}


               </div>
            {/* row end */}
        </div>
      </section>
      {/* wallet section end */}
 
    </>
  )
}

export default PaymentDetails
