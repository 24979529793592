import React, { useEffect, useState } from 'react';
import { calculateTzApi } from '../../AllApi/AllApi';

const Dasha2 = (props) => {
    const kundaliDataReport=props?.kundaliDataReportt
    const initialData=props?.initialDatas
    // { initialData },{kundaliDataReport} 
     
  const [dashas, setDashas] = useState(initialData);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [planetName, setPlanetName] = useState('');
  const [planetName2, setPlanetName2] = useState('');
  const [planetName3, setPlanetName3] = useState('');
  const [planetName4, setPlanetName4] = useState('');
  const  [calculatedTimeZoneData,setCalculatedTimeZoneData] =useState()
  

  const getAuthorizationHeader = () => {
    const username = 626533;
    const password = "a02cadfe7b8fd4f2f878c8d7552331df";;
    return 'Basic ' + btoa(username + ':' + password);
 };
 
 
 const headers = {
           
    'Authorization': getAuthorizationHeader(),
    'Content-Type': 'application/json',
  };
  const fetchDashas = async (url, data) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      });

      const result = await response.json();
      return result;
    } catch (error) {
       
      return [];
    }
  };
  async function calculateTimeZoneForBoy(lat,lon,date){
    const reqSendBoyData={lat,lon,date}
     
    const res =await calculateTzApi(reqSendBoyData)
     
    setCalculatedTimeZoneData(res)
  }

  useEffect(()=>{
    const now = new Date();
 
    const day = String(now.getDate()).padStart(2, '0'); // Get day and pad with 0 if needed
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Get month (0-indexed) and pad with 0
    const year = String(now.getFullYear()).slice(-2); // Get last two digits of the year
    
    const formattedDate = `${month}-${day}-${year}`;
    
   const latForKundliData=kundaliDataReport?.latForKundli
   const lonForKundliData=kundaliDataReport?.lonForKundli
    calculateTimeZoneForBoy(latForKundliData,lonForKundliData,formattedDate)
  },[])

 

  const handlePlanetRowClick = async (planet) => {
      // Determine the next level
  const nextLevel = (currentLevel + 1) % 4; // Cycle through 0, 1, 2, 3
   
  // Prepare request data
  const hours = kundaliDataReport?.hours ?? '02';
  const minutes = kundaliDataReport?.minutes ?? '01';
  const seconds = kundaliDataReport?.seconds ?? '02';
  const latForKundliData = kundaliDataReport?.latForKundli;
  const lonForKundliData = kundaliDataReport?.lonForKundli;
  const res2ReqSend = {
    day: kundaliDataReport?.day,
    month: kundaliDataReport?.month,
    year: kundaliDataReport?.year,
    hour: hours,
    min: minutes,
    lat: latForKundliData,
    lon: lonForKundliData,
    tzone: calculatedTimeZoneData?.timezone || 5.5,
  };
    setPlanetName(planet);

    const response = await fetchDashas(`https://json.astrologyapi.com/v1/sub_vdasha/${planet}`,res2ReqSend);
    setDashas(response);
    setCurrentLevel(1); // Move to the next level
  };

  const handleSubDashaRowClick = async (planet) => {
     // Determine the next level
  const nextLevel = (currentLevel + 1) % 4; // Cycle through 0, 1, 2, 3
   
  // Prepare request data
  const hours = kundaliDataReport?.hours ?? '02';
  const minutes = kundaliDataReport?.minutes ?? '01';
  const seconds = kundaliDataReport?.seconds ?? '02';
  const latForKundliData = kundaliDataReport?.latForKundli;
  const lonForKundliData = kundaliDataReport?.lonForKundli;
  const res2ReqSend = {
    day: kundaliDataReport?.day,
    month: kundaliDataReport?.month,
    year: kundaliDataReport?.year,
    hour: hours,
    min: minutes,
    lat: latForKundliData,
    lon: lonForKundliData,
    tzone: calculatedTimeZoneData?.timezone || 5.5,
  };
    
    setPlanetName2(planet);
    const response = await fetchDashas(`https://json.astrologyapi.com/v1/sub_sub_vdasha/${planetName}/${planet}`,res2ReqSend);
   
 
    setDashas(response);
    setCurrentLevel(2);
  };

  const handleSubSubDashaRowClick = async (planet) => {
      // Determine the next level
  const nextLevel = (currentLevel + 1) % 4; // Cycle through 0, 1, 2, 3

  // Prepare request data
  const hours = kundaliDataReport?.hours ?? '02';
  const minutes = kundaliDataReport?.minutes ?? '01';
  const latForKundliData = kundaliDataReport?.latForKundli;
  const lonForKundliData = kundaliDataReport?.lonForKundli;
  const res2ReqSend = {
    day: kundaliDataReport?.day,
    month: kundaliDataReport?.month,
    year: kundaliDataReport?.year,
    hour: hours,
    min: minutes,
    lat: latForKundliData,
    lon: lonForKundliData,
    tzone: calculatedTimeZoneData?.timezone || 5.5,
  };
    setPlanetName3(planet);
    const response = await fetchDashas(`https://json.astrologyapi.com/v1/sub_sub_sub_vdasha/${planetName}/${planetName2}/${planet}`,res2ReqSend);
    setDashas(response);
    setCurrentLevel(3);
  };

  const handleSubSubSubDashaRowClick = async (planet) => {
     // Determine the next level
  const nextLevel = (currentLevel + 1) % 4; // Cycle through 0, 1, 2, 3
   
  // Prepare request data
  const hours = kundaliDataReport?.hours ?? '02';
  const minutes = kundaliDataReport?.minutes ?? '01';
  const seconds = kundaliDataReport?.seconds ?? '02';
  const latForKundliData = kundaliDataReport?.latForKundli;
  const lonForKundliData = kundaliDataReport?.lonForKundli;
  const res2ReqSend = {
    day: kundaliDataReport?.day,
    month: kundaliDataReport?.month,
    year: kundaliDataReport?.year,
    hour: hours,
    min: minutes,
    lat: latForKundliData,
    lon: lonForKundliData,
    tzone: calculatedTimeZoneData?.timezone || 5.5,
  };
    setPlanetName4(planet);
    const response = await fetchDashas(`https://json.astrologyapi.com/v1/sub_sub_sub_sub_vdasha/${planetName}/${planetName2}/${planetName3}/${planet}`,res2ReqSend
   );
    setDashas(response);
    setCurrentLevel(4);
  };
//   endpoint = `https://json.astrologyapi.com/v1/sub_vdasha/${planet}`;
//   break;
// case 1:
//   endpoint = `https://json.astrologyapi.com/v1/sub_sub_vdasha/${planetName}/${planet}`;
//   break;
// case 2:
//   endpoint = `https://json.astrologyapi.com/v1/sub_sub_sub_vdasha/${planetName}/${planetName2}/${planet}`;
//   break;
// case 3:
//   endpoint = `https://json.astrologyapi.com/v1/sub_sub_sub_sub_vdasha/${planetName}/${planetName2}/${planetName3}/${planet}`;
  const handleGoBack = async () => {
         // Prepare request data
  const hours = kundaliDataReport?.hours ?? '02';
  const minutes = kundaliDataReport?.minutes ?? '01';
  const latForKundliData = kundaliDataReport?.latForKundli;
  const lonForKundliData = kundaliDataReport?.lonForKundli;
  const res2ReqSend = {
    day: kundaliDataReport?.day,
    month: kundaliDataReport?.month,
    year: kundaliDataReport?.year,
    hour: hours,
    min: minutes,
    lat: latForKundliData,
    lon: lonForKundliData,
    tzone: calculatedTimeZoneData?.timezone || 5.5,
  };
    switch (currentLevel) {
      case 1:
        setDashas(initialData);
        setCurrentLevel(0);
        break;
      case 2:
    
        const response1 = await fetchDashas(`https://json.astrologyapi.com/v1/sub_vdasha/${planetName}`,res2ReqSend);
        setDashas(response1);
        setCurrentLevel(1);
        break;
      case 3:
        const response2 = await fetchDashas(`https://json.astrologyapi.com/v1/sub_sub_vdasha/${planetName}/${planetName2}`,res2ReqSend );
        setDashas(response2);
        setCurrentLevel(2);
        break;
      case 4:
        const response3 = await fetchDashas(`https://json.astrologyapi.com/v1/sub_sub_sub_vdasha/${planetName}/${planetName2}/${planetName3}`,res2ReqSend);
        setDashas(response3);
        setCurrentLevel(3);
        break;
      default:
        break;
    }
  };

  return (
    <div className="tableWrapper dasha_table" style={{ textAlign: 'center' }}>
      <table className="table table-bordered table-striped text-center" id="astroTable">
        <thead>
          <tr>
            <th>Planets</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody id="dashas">
          {dashas?.map((planet, index) => {
            const dateParts = planet.end.split('-');
            const formattedDate = `${dateParts[0]}-${dateParts[1]}-${dateParts[2].split(' ')[0]}`;

            switch (currentLevel) {
              case 0:
                return (
                  <tr key={index} className="planetRow" onClick={() => handlePlanetRowClick(planet.planet)}>
                    <td className="planet" data-name={planet.planet}>
                      {planet.planet.substr(0, 2)}
                    </td>
                    <td>{formattedDate}</td>
                  </tr>
                );
              case 1:
                return (
                  <tr key={index} className="subDashaRow" onClick={() => handleSubDashaRowClick(planet.planet)}>
                    <td className="subDasha" data-name={planet.planet}>
                      {planetName.substr(0, 2)}/{planet.planet.substr(0, 2)}
                    </td>
                    <td>{formattedDate}</td>
                  </tr>
                );
              case 2:
                return (
                  <tr key={index} className="subSubDashaRow" onClick={() => handleSubSubDashaRowClick(planet.planet)}>
                    <td className="subSubDasha" data-name={planet.planet}>
                      {planetName.substr(0, 2)}/{planetName2.substr(0, 2)}/{planet.planet.substr(0, 2)}
                    </td>
                    <td>{formattedDate}</td>
                  </tr>
                );
              case 3:
                return (
                  <tr key={index} className="subSubSubDashaRow" onClick={() => handleSubSubSubDashaRowClick(planet.planet)}>
                    <td className="subSubSubDasha" data-name={planet.planet}>
                      {planetName.substr(0, 2)}/{planetName2.substr(0, 2)}/{planetName3.substr(0, 2)}/{planet.planet.substr(0, 2)}
                    </td>
                    <td>{formattedDate}</td>
                  </tr>
                );

                case 4:
                    return (
                      <tr key={index} className="subSubSubSubDashaRow" >
                        <td className="subSubSubSubDasha" data-name={planet.planet}>
                          {planetName.substr(0, 2)}/{planetName2.substr(0, 2)}/{planetName3.substr(0, 2)}/{planetName4.substr(0, 2)}/{planet.planet.substr(0, 2)}
                        </td>
                        <td>{formattedDate}</td>
                      </tr>
                    );
              default:
                return null;
            }
          })}
        </tbody>
      </table>
      <button className="btn btn-danger" onClick={handleGoBack}>Back</button>
    </div>
  );
};

export default Dasha2;
