import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);  // Navigate to the previous page
  };

  return (
    <div className="back_btn_div">
    <button className='btn' onClick={handleBackClick}> Go Back </button>
  </div>
  );
};

export default BackButton;
