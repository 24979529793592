import React, { useState, useEffect, useContext } from 'react';
import './Header.css';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Sidenavbar from './Sidenavbar';
import Searchcomponent from './Searchcomponent';
// import { allServicesApi } from '../data/AllapiData';
import Login from './Login/Login';
import { decryptData, Domain_Url, getDecryptedCookie, isAuthenticated, isUserId } from './Url/Url';
import MyContext from '../Context/MyContext';
import { getUserDetailsApi } from '../AllApi/AllApi';
// import { decrypt } from '../Utils/Utils';
import Cookies from 'js-cookie';
import videoCallImage from "../components/assets/img/videocall.png";
import voiceCallImage from "../components/assets/img/audiocall.png";
import chatImage from "../components/assets/img/chat.png";
import WebShare from './WebShare/WebShare';


const Header = () => {


  const handleLogoClick = () => {
   navigate('/')
  };
  // const [error, setError] = useState(null); // State to store any error
  const navigate=useNavigate()
const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
const { updateUserDetails } = useContext(MyContext);
const [loginRes, setLoginRes] = useState("");
const formatAmount = (amount) => {
  if (isNaN(amount) || amount === null || amount === undefined) {
    return '-';
  }
  return new Intl.NumberFormat("en-IN").format(amount);
};
const [userData, setUserData] = useState(null);
async function userDetails(){
  const decToken= isAuthenticated  
  const userId=isUserId
  
  const  res= await getUserDetailsApi(decToken,userId)
  setLoginRes(res)
  setUserData(res);
  updateUserDetails(res)
  if(res?.data?.name==''){
    navigate('/editprofile')

  }
  
  }
  useEffect(()=>{
  userDetails()
  },[])





function  handleLogOut(){
  Cookies?.remove('token')
  window.location.href = '/';

}


// const  [allServicesData,setAllServicesData]=useState()
// async function fetchAllServices() {
//   try {
//       const res = await allServicesApi();
//       if (res?.success == true) {
//           setAllServicesData(res?.data);
//       } else {
//           // Handle case where success is false or data is not as expected
//           setError('Failed to fetch services');
//       }
//   } catch (error) {
//       // Handle network or unexpected errors

//       setError('An error occurred while fetching services');
//   }
// }

// useEffect(() => {
//   fetchAllServices();
// }, []);

function toggleOpenLoginModal() {
setIsOpenLoginModal(!isOpenLoginModal);
}

// top bar chat, call, video 
const texts = [
  
  {
  text: "Call with astrologer",
  image: voiceCallImage,
  link: "/call",
  },
  {
  text: "Chat with astrologer",
  image: chatImage,
  link: "/chat",
  },

  {
    text: "Videocall with astrologer",
    image: videoCallImage,
    link: "/video",
    }
  ];
function modifySpaceToDash(name) {
// Replace spaces with hyphen
return name.replace(/\s+/g, '-');
}
const [index, setIndex] = useState(0);

useEffect(() => {
const intervalId = setInterval(() => {
setIndex((prevIndex) => (prevIndex + 1) % texts.length);
}, 2000); // Change text every 2 seconds

return () => clearInterval(intervalId); // Cleanup on unmount
}, []);


const [showButton, setshowButton] = useState(false);
const webScroll = () =>{
if(window.scrollY > 100){
setshowButton(true);
}

else{
setshowButton(false);
}
};

useEffect(() => {
  const webScroll = (event) => {
    // Your scroll logic here
    // Use preventDefault if needed
    event.preventDefault();
   
  };

  window.addEventListener('scroll', webScroll);

  return () => {
    window.removeEventListener('scroll', webScroll);
  };
}, []);

// scroll website then button show end
const [marginBottom, setMarginBottom] = useState('0px');

useEffect(() => {
  const handleScroll = () => {
    if (window.innerWidth <= 1024) { // Check if the screen width is 1024px or less
      if (window.scrollY > 50) {
        setMarginBottom('30px'); // Adjust the value as needed
      } else {
        setMarginBottom('0px'); // Remove margin when at the top
      }
    } else {
      setMarginBottom('0px'); // Remove margin for screens wider than 1024px
    }
  };

  window.addEventListener('scroll', handleScroll, { passive: true });

  // Cleanup event listener on component unmount
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

const [toggleSearch, setToggleSearch] = useState(false);
const { updateMobileSearch } = useContext(MyContext);
useEffect(() => {
    updateMobileSearch(toggleSearch); // Update mobile search whenever searchState changes
}, [toggleSearch]);
function toggleSearchBar() {
    setToggleSearch(!toggleSearch);
    // updateMobileSearch(true)
}

const locationUrl = window.location.pathname;


const urlSegments = locationUrl.split('/');



const splitedastrologersDetails = urlSegments[1]; // Index 3 contains 'astrologersDetails'



return (
<>
{/*  */}
<div className="alert_msg_main_div">
<div className="alert alert-warning alert-dismissible fade show" role="alert">
<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
<div className="msg_alert_div">
<strong>TalkndHeal App</strong>
<Link to="">Download Now</Link>
</div>   
</div>
</div>
{/*  */}


{/* top bar section start */}
<section className="top_bar_section">
<div className="container">
<div className="row">
{/* col start */}
<div className="col-lg-12 col-12">
<div className="top_left_flexdiv">
{/*  */}
<div className="top_left_links">
<Link to="/free-kundli-online" title="Free Kundali Software & Kundali Matching">Kundli</Link>
{/*<a to="yearlyHoroscope">Horoscope 2024</a>*/}
<Link to="/horoscope/today-horoscope">Today's Horoscope</Link>
<Link to="/panchang">Panchang</Link>
</div>
{/*  */}
{/*  */}

{/*  */}

<div className="top_signin_btn_div">
<div className="top_middle_chat_box">
{/* 
<Link to={texts[index].link}>
<img src={texts[index].image} alt={texts[index].text} style={{ height: "15px", width: "15px" }}/>
<span id="content-container">
<b id="content"> {texts[index].text} </b>
</span>
<img src={`${Domain_Url}/images/ic_online.gif`} alt="Online Status" className="img-fluid" />
</Link> */}

{isAuthenticated && <span className="wallet_div">
<Link to="/wallet">
<span className="wallet_img_box">
<img src={`${Domain_Url}/images/wallet-2.png`} alt="wallet" />
<span> ₹ </span>
</span>
<span className="amount_div" >{formatAmount(loginRes?.data?.wallet)}</span>
</Link>
</span>}



<span className="share_btn"><WebShare /></span>
</div>
{!isAuthenticated? <Link to=""className='' onClick={toggleOpenLoginModal}>SIGN IN/ SIGN UP</Link>:
<>
<Link  to="#" role="button" id="dropdownMenuLink" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"> {loginRes?.data?.name  }</Link>
<ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
<li><Link className="dropdown-item" to="/userprofile">Profile</Link></li>
{/* <li><Link className="dropdown-item" to="/notifications">Notification</Link></li> */}
<li><Link className="dropdown-item" to="/payment">Wallet Transactions</Link></li>
{/* <li><Link className="dropdown-item" to="/orderhistory">Order History</Link></li> */}
<li><Link className="dropdown-item" to="/ticket">Customer Support</Link></li>
{/* <li><Link className="dropdown-item" to="/blockedastro">Reported Astrologers</Link></li>  */}
<li onClick={handleLogOut}><Link className="dropdown-item">Sign Out</Link></li>
</ul>
</>}
</div>
{/*  */}
</div>
</div>
{/* col end */}
</div>
</div>
</section>
{/* top bar end */}
{/* logo navbar section start */}
<div className="header_wrapper_div_main">
<section className="logo_navbar" style={{ marginBottom }}>
<div className="container">
<div className="row">
{/* col start */}
<div className="col-lg-12 col-12">
<div className="logo_navr_main_div">
{/*  */}
<div className="logo_div">
<Link to="/"><img src={`${Domain_Url}/logo/logo.png`}  alt="talkndheal logo" className="img-fluid" /></Link>
</div>
{/*  */}

{/*  */}
<div className="logo_navlinks">
<NavLink to="/services/astrology" activeClassName="active">Astrology</NavLink>
<NavLink to="/services/tarot" activeClassName="active">Tarot</NavLink>
<NavLink to="/services/psychologist" activeClassName="active">Psychologist</NavLink>
<NavLink to="/services/kp-system" activeClassName="active">KP System</NavLink>
<NavLink to="/services/lal-Kitab" activeClassName="active">Lal Kitab</NavLink>
<NavLink to="/services/palmistry" activeClassName="active">Palmistry</NavLink>
<NavLink to="/services/vastu" >Vastu</NavLink>
<NavLink to="/services/pranayama" activeClassName="active">Pranayama</NavLink>
<NavLink to="/services/yoga-mudras" activeClassName="active">Yoga mudras</NavLink>
<NavLink to="/services/sun-rays-healing" activeClassName="active">Sun Rays Healing</NavLink>
<NavLink to="/services/crystal-healing" activeClassName="active">Crystal Healing</NavLink>
<NavLink to="/services/acupressure-healing" activeClassName="active">Acupressure Healing</NavLink>
<NavLink to="/services/magnet-healing" activeClassName="active">Magnet Healing</NavLink>
{/* // top bar chat, call, video end
//     
//       
// Healing 
//  Healing Healing Healing Healing
// scroll website then button show */}
</div>
<div className="sign_in_btn" style={{ display: showButton ? 'block' : 'none' }}>
{/* <Link to="" data-bs-toggle="modal" data-bs-target="#login">SIGN IN/ SIGN UP</Link> */}
{/* <Link  to="#" role="button" id="dropdownMenuLink" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleOpenLoginModal}> SIGN IN/ SIGN UP</Link> */}
{!isAuthenticated?<Link onClick={toggleOpenLoginModal} > SIGN IN/ SIGN UP</Link>:
<Link  className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"> {loginRes?.data?.name}</Link>}
<ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
<li><Link className="dropdown-item" to="/userprofile">Profile</Link></li>
{/* <li><Link className="dropdown-item" to="/notifications">Notification</Link></li> */}
<li><Link className="dropdown-item" to="/payment">Wallet Transactions</Link></li>
{/* <li><Link className="dropdown-item" to="/orderhistory">Order History</Link></li> */}
<li><Link className="dropdown-item" to="/ticket">Customer Support</Link></li>
{/* <li><Link className="dropdown-item" to="/blockedastro">Reported Astrologers</Link></li>  */}
<li><Link className="dropdown-item" to="/" onClick={handleLogOut}>Sign Out</Link></li>
</ul>
</div>
{/*  */}
<div className="navbar_button_div">
{isAuthenticated && <span className="navbar_wallet_div">
<Link to="/wallet">
<span className="navbar_wallet_img_box">
<img src="../../images/wallet.svg" alt="wallet" />
<span> ₹ </span>
</span>
<span className="navbar_amount_div">{formatAmount(loginRes?.data?.wallet)}</span>
</Link>
</span>}

{
splitedastrologersDetails!='astrologersDetails' &&   <span className="" onClick={toggleSearchBar}>
<i className="mobile_search_icon" aria-hidden="true">
<img src="../../images/searchicon.png" alt="search icon"className="img-fluid"/>
</i>
</span>
}
<button className="fa fa-bars bars_btn" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions" />
{/*  */}

</div>

</div>
</div>
{/* col end */}
</div>
</div>
{/*  */}

</section>
{/* logo navbar section end */}
{/* navbar section */}
<section className="navbar_section_navbar">
<nav className="navbar navbar-expand-lg bg-light">
<div className="container">
<div className="collapse navbar-collapse" id="navbarNavDropdown">
<ul className="navbar-nav navbar-childs">
<li className="nav-item"><NavLink className="nav-link" exact to="/" activeClassName="active">Home</NavLink></li>
<li className="nav-item"><NavLink className="nav-link" to="/free-kundli-online" activeClassName="active">Kundli</NavLink></li>
<li className="nav-item"><NavLink className="nav-link" to="/kundli-matching" activeClassName="active">Kundli Matching</NavLink></li>
{/* mega menu *
<li className="nav-item dropdown">
<NavLink className="nav-link" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" activeClassName="active">Horoscopes <span className="fa fa-angle-down" /></NavLink>
<ul className="dropdown-menu">
<NavLink to="/horoscope/today-horoscope">Today's Horoscope</NavLink>
<NavLink to="/horoscope/tomorrow-horoscope">Tomorrow's Horoscope</NavLink>
<NavLink to="/horoscope/monthly-horoscope">Monthly Horoscope</NavLink>
</ul>
</li>
* mega menu end*/}

<li className="nav-item"><NavLink className="nav-link" to="/panchang" activeClassName="active">Panchang</NavLink></li>
<li className="nav-item"><NavLink className="nav-link" to="/horoscope/today-horoscope" activeClassName="active">Today's Horoscope</NavLink></li>
<li className="nav-item"><NavLink className="nav-link" to="/horoscope/tomorrow-horoscope" activeClassName="active">Tomorrow's Horoscope</NavLink></li>
<li className="nav-item"><NavLink className="nav-link" to="/horoscope/monthly-horoscope" activeClassName="active">Monthly Horoscope</NavLink></li>
<li className="nav-item"><NavLink className="nav-link" to="/numerology" activeClassName="active">Numerology</NavLink></li>
{/* <li className="nav-item"><Link className="nav-link" to="/chat">Chat with Astrologer</Link></li>
<li className="nav-item"><Link className="nav-link" to="/call">Call with Astrologer</Link></li>
<li className="nav-item"><Link className="nav-link" to="/video">Video call with Astrologer</Link></li> */}
<li className="nav-item"><NavLink className="nav-link" to="/join-as-astrologer" activeClassName="active">Join As An Astrologer</NavLink></li>
{/* <li className="nav-item"><Link className="nav-link" to="/samuhikpuja">Samuhik Puja</Link></li> */}
</ul>
</div>
</div>
</nav>
{/*  */}
<div className="serachicon_div">
<div className="list_main_div">
<Searchcomponent />
<div className="suggestion_list">
<ul>
<li><Link to="">Suggestionlist</Link></li>
</ul>
</div>
</div>
</div>

{/*  */}
</section>
</div>
{/* navbar section end */}


{/*  */}
<Sidenavbar userData={userData}/>

{/*  */}

{isOpenLoginModal && <Login closeModal={toggleOpenLoginModal} />}
</>
)
}

export default Header



