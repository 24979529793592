import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import AgoraRTC from 'agora-rtc-sdk-ng'; // Ensure this package is correctly installed
// import { AgoraRTCProvider } from 'agora-rtc-react';

const root = ReactDOM.createRoot(document.getElementById('root'));



// In the video call scenario, set mode to "rtc"
// const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });


root.render(
  
    // <AgoraRTCProvider client={client}>
      <App />
    // </AgoraRTCProvider>
  
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
