import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import { Link, useParams } from 'react-router-dom'
import { sliderrigtside, todayhoroscopecarousel } from '../data/Owlcarouseloptions';
import { monthlyHoroscopePredictioApi, monthlyHoroscopePredictionApi, todayHoroscopePredictionApi, tomorrowHoroscopePredictionApi } from '../data/AllapiData';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/Horoscope.css';

const MonthlyHoroscopeDetails = () => {
  const [allHoroscopeData, setAllHoroscopeData] = useState(null);
  const { id, horosName } = useParams();
    
  // Generate timezone
  const timezone =  new Date().getTimezoneOffset() / -60; //

  const fetchTodayHoroscope = async () => {
    const dataSend = { timezone, horosName };
    try {
      const res = await todayHoroscopePredictionApi(dataSend);
       
      setAllHoroscopeData(res);
    } catch (error) {
       
    }
  };

  const fetchTomorrowHoroscope = async () => {
    const dataSend = { timezone, horosName };
    try {
      const res = await tomorrowHoroscopePredictionApi(dataSend);
      setAllHoroscopeData(res);
    } catch (error) {
       
    }
  };

  const fetchMonthlyHoroscope = async () => {
    const dataSend = { timezone, horosName };
    try {
      const res = await monthlyHoroscopePredictionApi(dataSend);
      setAllHoroscopeData(res);
    } catch (error) {
       
    }
  };

 


  return (
    <>
      {/* ========= */}
   
    {/* ====== */}
     {/* horoscope details section start */}
     <section className="horoscope_details_section">
        <div className="container">
            {/* row start */}
            <div className="row">
                {/*======================= col start 9============================= */}
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                   
                    <div className="horoscope_dt_title_img_box">
                            <div className="horoscope_img_box_dt">
                                <img src="http://localhost:3000/logo/logo.png" alt="July Prediction For Gemini" className='img-fluid'/>
                            </div>
                            <h1>July Prediction For Gemini</h1>
                            <hr />
                        </div>
                    

                    
                    <div className="maindiv_horoscope_details_leftsode">
                      
                         <div className="main_div_txt">
                            <div className="horoscope_txt_flx">
                                <img src="../images/horoscope1.png" alt="Personal Life" className='img-fluid'/>
                                <h2>Personal Life</h2>
                            </div>
                            <p>{allHoroscopeData?.prediction?.personal_life}</p>
                        </div>  
                       

                       
                        <div className="main_div_txt">
                            <div className="horoscope_txt_flx">
                            <img src="../images/horoscope2.png" alt="Profession" className='img-fluid'/>
                                <h2>Profession</h2>
                            </div>
                            <p>{allHoroscopeData?.prediction?.profession}</p>
                        </div>  
                       

                         
                         <div className="main_div_txt">
                            <div className="horoscope_txt_flx">
                            <img src="../images/horoscope3.png" alt="Health" className='img-fluid'/>
                                <h2>Health</h2>
                            </div>
                            <p>{allHoroscopeData?.prediction?.health}</p>
                        </div>  
                       {/*  */}


                         {/*  */}
                         <div className="main_div_txt">
                            <div className="horoscope_txt_flx">
                            <img src="../images/horoscope4.png" alt="Travel" className='img-fluid'/>
                                <h2>Travel</h2>
                            </div>
                            <p>{allHoroscopeData?.prediction?.travel}</p>
                        </div>  
                       {/*  */}


                         {/*  */}
                         <div className="main_div_txt">
                            <div className="horoscope_txt_flx">
                            <img src="../images/horoscope5.png" alt="Luck" className='img-fluid'/>
                                <h2>Luck</h2>
                            </div>
                            <p>भाग्य आपके पक्ष में है; अतएव आपका मूड बहुत अच्छा रहेगा । आप के लॉटरी जीतने या सट्टा बाजार में लाभ हासिल करने की अच्छी संभावनाएं हैं ।</p>
                        </div>  
                       {/*  */}

                          {/*  */}
                          <div className="main_div_txt">
                            <div className="horoscope_txt_flx">
                            <img src="../images/horoscope6.png" alt="Emotion" className='img-fluid'/>
                                <h2>Emotion</h2>
                            </div>
                            <p>{allHoroscopeData?.prediction?.emotions}</p>
                        </div>  
                       {/*  */}


                    </div>
                    {/*  */}
                </div> 
          {/*======================= col end 9============================= */}

          

{/* ===================col end right side 3 col========================  */}

            </div>
            {/* row end */}
        </div>
     </section>
     {/* horoscope details section end */}


{/*  */}
<section className="todayhoroscope_section">
  <div className="container">
  
    <div className="row">
      <div className="col-lg-12">
        {/*  */}
          <div className="todayhoroscope_service">
          <OwlCarousel className="owl-theme" {...todayhoroscopecarousel}>

{/* item div start */}
 {/*  */}

            <div className="item">
            <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/1.png" alt='Aries'/>
            </div>
              <h3>Aries</h3>
          </div>
        </Link>
            </div>
          
          {/*  */}
          {/*  */}
         
            <div className="item">
            <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/2.png" alt='Taurus'/>
            </div>
              <h3>Taurus</h3>
          </div>
        </Link>
            </div>
         
          {/*  */}
          {/*  */}
          
            <div className="item">
            <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/3.png" alt='Gemini'/>
            </div>
              <h3>Gemini</h3>
          </div>
        </Link>
            </div>
         
          {/*  */}

          {/*  */}
            <div className="item">
            <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/4.png" alt='Cancer'/>
            </div>
              <h3>Cancer</h3>
          </div>
        </Link>
            </div>
          {/*  */}

          
          {/*  */}
          <div className="item">
          <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/5.png" alt='Leo'/>
            </div>
              <h3>Leo</h3>
          </div>
        </Link>
            </div>
          {/*  */}


          
          {/*  */}
          <div className="item">
          <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/6.png" alt='Virgo'/>
            </div>
              <h3>Virgo</h3>
          </div>
        </Link>
            </div>
          {/*  */}


          
          {/*  */}
          <div className="item">
          <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/7.png" alt='Libra'/>
            </div>
              <h3>Libra</h3>
          </div>
        </Link>
            </div>
          {/*  */}


          
          {/*  */}
          <div className="item">
          <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/8.png" alt='Scorpio'/>
            </div>
              <h3>Scorpio</h3>
          </div>
        </Link>
            </div>
          {/*  */}

             {/*  */}
             <div className="item">
             <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/9.png" alt='Sagittarius'/>
            </div>
              <h3>Sagittarius</h3>
          </div>
        </Link>
            </div>
          {/*  */}

            {/*  */}
            <div className="item">
            <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/10.png" alt='Capricorn'/>
            </div>
              <h3>Capricorn</h3>
          </div>
        </Link>
            </div>
          {/*  */}


            {/*  */}
            <div className="item">
            <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/11.png" alt='Aquarius'/>
            </div>
              <h3>Aquarius</h3>
          </div>
        </Link>
            </div>
          {/*  */}

          
            {/*  */}
            <div className="item">
            <Link to="">
          <div className="today_horoscope_main_div">
            <div className='today_horoscope_img_box'>
              <img src="../images/12.png" alt='Pisces'/>
            </div>
              <h3>Pisces</h3>
          </div>
        </Link>
            </div>
          {/*  */}




</OwlCarousel>
</div>  
       
      </div>
    </div>
  </div>
</section>
{/*  */}

 

    </>
  )
}

export default MonthlyHoroscopeDetails
