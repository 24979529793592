import React, { useState, useEffect, useRef } from 'react';

const SearchComponent = () => {
  const [showSearchInput, setShowSearchInput] = useState(false);
  const inputRef = useRef(null);

  const handleSearchIconClick = (e) => {
    e.preventDefault();  // Prevent form submission on click
    setShowSearchInput(!showSearchInput);
  };

  const handleClickOutside = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setShowSearchInput(false);
    }
  };

  useEffect(() => {
    // Define the event handler function
    const handleClickOutside = (event) => {
      // Your logic to handle clicks outside
    };
  
    if (showSearchInput) {
      // Add event listener when showSearchInput is true
      document.addEventListener('mousedown', handleClickOutside, { capture: true, passive: true });
    } else {
      // Remove event listener when showSearchInput is false
      document.removeEventListener('mousedown', handleClickOutside, { capture: true, passive: true });
    }
  
    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, { capture: true, passive: true });
    };
  }, [showSearchInput]);
  

  return (
    <div className="input-group" ref={inputRef}>
      {showSearchInput && (
        <input type="text" placeholder="Search..." className="form-control" />
      )}
      <button className="btn" onClick={handleSearchIconClick}>
        {showSearchInput ? <i className='bx bx-x'></i> : <i className='bx bx-search-alt'></i>}
      </button>
    </div>
  );
};

export default SearchComponent;
