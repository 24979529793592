import moment from "moment";
import { isAuthenticated } from "../components/Url/Url";

const REACT_APP_JSON_API_URL = process.env.REACT_APP_JSON_API_URL;
const REACT_APP_KUNDALI_CLICK_API_URL =
  process.env.REACT_APP_KUNDALI_CLICK_API_URL;
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const REACT_APP_USER_NAME = process.env.REACT_APP_USER_NAME;
const REACT_APP_USER_PASSWORD = process.env.REACT_APP_USER_PASSWORD;
const REACT_APP_AUTH_CODE = process.env.REACT_APP_AUTH_CODE;
const REACT_APP_USER_ID = process.env.REACT_APP_KUNDALI_CLICK_USER_ID;

//All services Api's
export const singleServicesApi = async (Data) => {
  
  try {
    const res = await fetch(`${API_URL}/fetch_single_expertise`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apikey: API_KEY, // Replace with your actual API key
        Authorization: `Bearer ${Data?.token}`,
      },
      body: JSON.stringify({ service: Data?.data }), // Send the service data in the request body
    });

    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }

    return await res.json();
  } catch (error) {
   
    throw error; // Optionally re-throw the error if you want to handle it further up the call chain
  }
};

export const saveKundaliApi = async (Data) => {
  try {
    const res = await fetch(`${API_URL}/save_kundli`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apikey: API_KEY, // Replace with your actual API key
        Authorization: `Bearer ${Data?.userdata?.token}`,
      },
      body: JSON.stringify({
        userid: Data?.userdata?.user,
        Name: Data?.Name,
        day: Data?.day,
        month: Data?.month,
        year: Data?.year,
        hour: Data?.hours,
        minute: Data?.minutes,
        second: Data?.seconds,
        PlaceofBirth: Data?.placeofBirth,
        placeofbirthcity: Data?.placeofbirthcity,
        Gender: Data?.gender,
        latitude: Data?.latitude ? parseFloat(Data?.latitude).toFixed(2) : null,
        longitude: Data?.longitude ? parseFloat(Data?.longitude).toFixed(2) : null,
        timezone:Data?.timezone
      }), // Send the service data in the request body
    });

    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }

    return await res.json();
  } catch (error) {
    throw error; // Optionally re-throw the error if you want to handle it further up the call chain
  }
};
export const EditKundaliApi = async (Data, token) => {

  try {
    const res = await fetch(`${API_URL}/edit-saved-kundli`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apikey: API_KEY,  // Replace with your actual API key
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        
        savedId: Data?.savedId || 2,
        Name: Data?.Name,
        PlaceofBirth: Data?.cityId,
        userid: String(Data?.userid),
        day: String(Data?.day),
        month: String(Data?.month),
        year: String(Data?.year),
        hour: Data?.hour,
        minute: String(Data?.minutes),
        second: Data?.second,
        placeofbirthcity: Data?.city || 110043,
        latitude: Data?.latitude ? parseFloat(Data?.latitude).toFixed(2) : null,
        longitude: Data?.longitude ? parseFloat(Data?.longitude).toFixed(2) : null,
        timezone: Data?.timezone,
        Gender: Data?.Gender,
      }),
    });

    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }

    return await res.json();
  } catch (error) {
 
    throw error;
  }
};


// Raise Ticket Apis 

export const generateTicketApi = async (formData) => {
  try {
    const bToken = isAuthenticated; // Assuming `isAuthenticated` is defined elsewhere
    if (!bToken) {
      throw new Error("User is not authenticated.");
    }

   

    const res = await fetch(`${API_URL}/generate-ticket`, {
      method: "POST",
      headers: {
        apiKey: API_KEY, // You don't need "Content-Type" for FormData
        Authorization: `Bearer ${bToken}`,
      },
      body: formData, // Directly sending formData object
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the response (assuming JSON is returned)
    const responseData = await res.json();
   

    return responseData;
  } catch (error) {
   
    return { error: "Failed to create ticket." };
  }
};

export const fetchTicketList = async (mobile, filter, data) => {
  try {
   
    const bToken = isAuthenticated;
    const response = await fetch(`${API_URL}/ticket_list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apiKey: API_KEY,
        Authorization: `Bearer ${bToken}`, // Make sure token is passed correctly
      },
      body: JSON.stringify({
        mobile: mobile,
        filter: filter,
      }),
    });

    if (!response.ok) {
      
      throw new Error("Network response was not ok");
    }

    const result = await response.json();
   
    return result;
  } catch (error) {
   
    return { error: "Failed to fetch data" };
  }
};


export const FetchSingleTicket = async (ticketId) => {
  try {
    const bToken = isAuthenticated;
    if (!bToken) {
      throw new Error("User is not authenticated.");
    }

    const formData = new FormData();
    formData.append("ticketid", ticketId);  // Add ticketId to FormData

    const res = await fetch(`${API_URL}/fetch_ticket_history`, {
      method: "POST",
      headers: {
        apiKey: API_KEY,
        Authorization: `Bearer ${bToken}`,
        // Note: No need for "Content-Type" header when sending FormData
      },
      body: formData,
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await res.json();
  
    return responseData;
  } catch (error) {
   
    return { error: "Failed to fetch ticket." };
  }
};

export const ReOpenSingleTicket = async (ticketId) => {
  try {
    const bToken = isAuthenticated;
    if (!bToken) {
      throw new Error("User is not authenticated.");
    }

    const formData = new FormData();
    formData.append("ticketid", ticketId);  // Add ticketId to FormData

    const res = await fetch(`${API_URL}/reopen`, {
      method: "POST",
      headers: {
        apiKey: API_KEY,
        Authorization: `Bearer ${bToken}`,
        // Note: No need for "Content-Type" header when sending FormData
      },
      body: formData,
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await res.json();
   
    return responseData;
  } catch (error) {
    
    return { error: "Failed to fetch ticket." };
  }
};
// Raise Ticket Apis 


export const deleteKundaliApi = async (id, token) => {
   
  try {
    const res = await fetch(`${API_URL}/delete-saved-kundli`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apikey: API_KEY, // Replace with your actual API key
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        SavedID: id,
      }), // Send the service data in the request body
    });

    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }

    return await res.json();
  } catch (error) {
     
    throw error; // Optionally re-throw the error if you want to handle it further up the call chain
  }
};
export const allServicesApi = async (data) => {
  try {
    const res = await fetch(`${API_URL}/fetch_expertise`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        apikey: API_KEY, // Replace with your actual API key
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }

    return await res.json();
  } catch (error) {
     
    throw error; // Optionally re-throw the error if you want to handle it further up the call chain
  }
};

export const allAllPricing = async (data) => {
  try {
    const res = await fetch(`${API_URL}/fetch_pricing`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        apikey: API_KEY, // Replace with your actual API key
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }

    return await res.json();
  } catch (error) {
     
    throw error; // Optionally re-throw the error if you want to handle it further up the call chain
  }
};

export const getPlaceOfBirth = async (city) => {
  try {
    const bToken = isAuthenticated;
    const res = await fetch(`${API_URL}/fetch_placeOfbirth_API`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apikey: API_KEY, // Replace with your actual API key
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify({ search: city }),
    });

    if (!res.ok) {
      throw new Error(`Network response was not ok: ${res.statusText}`);
    }

    return await res.json();
  } catch (error) {
     
    throw error; // Re-throw the error for further handling
  }
};

export const fetchAstroCategory = async () => {
  try {
    const res = await fetch(
      `${API_URL}/custom_api/astrologerAPIs/fetch_astro_category`,
      {
        method: "GET",
        headers: {
          apiKey: API_KEY,
          "Content-type": "application/json",
        },
      }
    );

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    return await res.json();
  } catch (error) {
    throw error; // Re-throw the error after logging it, or handle it accordingly
  }
};

export const LiveChatAssistantPostApi = async (data, token) => {};

export const LiveChatHistoryInsertApi = async (data, token) => {
  try {
    const res = await fetch(
      `${API_URL}/AssistantChatAPIs/get_assistant_chat_history`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`, // Include the Bearer token in the header
        },
        body: JSON.stringify(data), // Convert data to JSON format
      }
    );

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    return await res.json(); // Return the response as JSON
  } catch (error) {
     
    return {
      success: false,
      message: "An error occurred while processing the request.",
    }; // Return a default error response
  }
};

export const getLiveChatAssistantApi = async (chatid) => {
  try {
    const res = await fetch(
      `${API_URL}/AssistantChatAPIs/get_assistant_chat_history`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ chatid: chatid }), // Wrap chatid in an object
      }
    );

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    return await res.json();
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while fetching assistant chat history.",
    }; // Return a default error response
  }
};

export const getAllAstrologerChatApi = async (data) => {
  try {
    const res = await fetch(
      `${API_URL}/astrologerAPIs/fetch_astrologers_chat`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          id: data?.id ?? "",
          language: data?.language ?? "",
          expertise: data?.experties ?? "",
          rating: data?.rating ?? "",
          pricing: data?.pricing ?? "",
          category: data?.category ?? "",
          experience: data?.experience ?? "",
        }),
      }
    );

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    return await res.json();
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while fetching astrologer chats.",
    }; // Return a default error response
  }
};

export const getAllAstrologerCallApi = async (token) => {
  const res = await fetch(`${API_URL}/astrologerAPIs/fetch_astrologers_call`, {
    method: "POST",
    headers: {
      apikey: API_KEY,

      "Content-Type": "application/json",
    },
  });

  return await res.json();
};

export const getAllAstrologerVideoCallApi = async (token) => {
  const res = await fetch(
    `${API_URL}/astrologerAPIs/fetch_astrologers_videochat`,
    {
      method: "POST",
      headers: {
        apikey: API_KEY,

        "Content-Type": "application/json",
      },
    }
  );
  return await res.json();
};

// hero slider banner
export const SliderHerobanner = async () => {
  try {
    const res = await fetch(`${API_URL}/fetchdesk-slider`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        apikey: API_KEY, // Replace with your actual API key
      },
    });

    if (!res.ok) {
      throw new Error(`Network response was not ok: ${res.statusText}`);
    }

    const data = await res.json();
    return data;
  } catch (error) {
     
    return {
      success: false,
      message: "An error occurred while fetching the hero banner.",
    }; // Return a default error response
  }
};

// hero slider banner end

// hero slider banner
export const SliderHerobannerMobile = async () => {
  try {
    const res = await fetch(`${API_URL}/fetch-slider`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        apikey: API_KEY, // Replace with your actual API key
      },
    });

    if (!res.ok) {
      throw new Error(`Network response was not ok: ${res.statusText}`);
    }

    const data = await res.json();
    return data;
  } catch (error) {
     
    return {
      success: false,
      message: "An error occurred while fetching the hero banner.",
    }; // Return a default error response
  }
};

// hero slider banner end

// Testimonials Start
export const TestimonialsApi = async () => {
  try {
    const res = await fetch(`${API_URL}/fetch_testimonial`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        apikey: API_KEY, // Replace with your actual API key
      },
    });

    if (!res.ok) {
      throw new Error(`Network response was not ok: ${res.statusText}`);
    }

    const data = await res.json();
    return data;
  } catch (error) {
     
    return {
      success: false,
      message: "An error occurred while fetching the hero banner.",
    }; // Return a default error response
  }
};
// Testimonials end

// Healingservice Start
export const HealingserviceApi = async () => {
  try {
    const res = await fetch(`${API_URL}/fetch_healingexpertise`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        apikey: API_KEY, // Replace with your actual API key
      },
    });

    if (!res.ok) {
      throw new Error(`Network response was not ok: ${res.statusText}`);
    }

    const data = await res.json();
    return data;
  } catch (error) {
     
    return {
      success: false,
      message: "An error occurred while fetching the hero banner.",
    }; // Return a default error response
  }
};
// Healingservice end

// astroservice Start
export const astroserviceApi = async () => {
  try {
    const res = await fetch(`${API_URL}/fetch_astrologyexpertise`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        apikey: API_KEY, // Replace with your actual API key
      },
    });

    if (!res.ok) {
      throw new Error(`Network response was not ok: ${res.statusText}`);
    }

    const data = await res.json();
    return data;
  } catch (error) {
     
    return {
      success: false,
      message: "An error occurred while fetching the hero banner.",
    }; // Return a default error response
  }
};
// astroservice end

// samuhik puja start

export const samuhikpooja = async () => {
  try {
    const res = await fetch(`${API_URL}/samuhikPoojaAPIs/samuhik-pooja`, {
      method: "GET",
      headers: {
        apiKey: API_KEY,
        "Content-type": "application/json",
      },
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    return await res.json();
  } catch (error) {
    throw error; // Re-throw the error after logging it, or handle it accordingly
  }
};

// end

export const fetchAstrologerReviewApi = async (astrologerId) => {
  try {
    const res = await fetch(
      `${API_URL}/astrologerAPIs/fetch_astrologer_review`,
      {
        method: "POST",
        headers: {
          apiKey: API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          astroId: astrologerId,
        }),
      }
    );

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    return await res.json();
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while fetching the astrologer's reviews.",
    }; // Return a default error response
  }
};

export const filterAstrologerApi = async (data) => {
  const apiUrl = `${API_URL}/astrologerAPIs/fetch_astrologers_chat`;

  const res = await fetch(apiUrl, {
    method: "POST",
    headers: {
      apiKey: API_KEY,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      search: data?.id || "",
      language: data?.language || "",
      expertise: data?.experties || "",
      rating: data?.rating || "",
      pricing: data?.pricing || "",
      category: data?.category || "",
      experience: data?.experience || "",
    }),
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  return await res.json();
};

export const filterAstrologerAudioApi = async (data) => {
  const apiUrl = `${API_URL}/astrologerAPIs/fetch_astrologers_call`;

  const res = await fetch(apiUrl, {
    method: "POST",
    headers: {
      apiKey: API_KEY,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      search: data?.id || "",
      language: data?.language || "",
      expertise: data?.experties || "",
      rating: data?.rating || "",
      pricing: data?.pricing || "",
      category: data?.category || "",
      experience: data?.experience || "",
    }),
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  return await res.json();
};

export const filterAstrologerVideoApi = async (data) => {
  const apiUrl = `${API_URL}/astrologerAPIs/fetch_astrologers_videochat`;

  const res = await fetch(apiUrl, {
    method: "POST",
    headers: {
      apiKey: API_KEY,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      search: data?.id || "",
      language: data?.language || "",
      expertise: data?.experties || "",
      rating: data?.rating || "",
      pricing: data?.pricing || "",
      category: data?.category || "",
      experience: data?.experience || "",
    }),
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  return await res.json();
};

export const chatAssistantRequestSend = async (data) => {};

export const waitListRequestSend = async (data) => {};

export const waitListRequestCancel = async (data) => {};

export const loginWithPhoneNumberApi = async (data) => {
  try {
    const res = await fetch(`${API_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apikey: API_KEY, // Replace with your actual API key
      },
      body: JSON.stringify(data), // Include the data in the request body
    });

    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
    }

    return await res.json();
  } catch (error) {
    return { error: "Failed to fetch data" }; // Return a default error response
  }
};
export const loginResendOtpWithPhoneNumberApi = async (data) => {
  try {
    const res = await fetch(`${API_URL}/resendotp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apikey: API_KEY, // Replace with your actual API key
      },
      body: JSON.stringify(data), // Include the data in the request body
    });

    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
    }

    return await res.json();
  } catch (error) {
    return { error: "Failed to fetch data" }; // Return a default error response
  }
};
export const otpVerifiedApi = async (data) => {
  try {
    const url = `${API_URL}/verify_login`;

    // Prepare the data in JSON format
    const requestData = {
      mobile: data?.loginData.mobile,
      otpmanual: data?.loginData.otp,
      device: "Web",
      FCMtoken: "kk",
    };

    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apikey: API_KEY, // Replace with your actual API key
      },
      body: JSON.stringify(requestData),
    });

    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
    }

    return await res.json();
  } catch (error) {
    return { error: "Failed to fetch data" }; // Return a default error response
  }
};

export const weeklyScheduleGetApi = async (astroid) => {
  try {
    const res = await fetch(`${API_URL}/fetch_weekly_schedule`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ astroId: astroid }),
    });

    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
    }

    return await res.json();
  } catch (error) {
    return { error: "Failed to fetch data" }; // Return a default error response
  }
};

export const fetchAllSimilarAstrologers = async (Data) => {
  try {
    const res = await fetch(
      "`${API_URL}/astrologerAPIs/fetch_similar_astrologers",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          apikey: API_KEY, // Replace with your actual API key
        },
        body: JSON.stringify({ astrologer_id: Data?.data }), // Sending the astrologerId in the request body
      }
    );

    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
    }

    const responseData = await res.json(); // Using a different variable name to avoid conflict
    return responseData;
  } catch (error) {
     
    return { error: "Failed to fetch data" };
  }
};

export const reportedAstrologers = async (token, data) => {
   
  try {
    const res = await fetch(`${API_URL}/blocked_astro_fetch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apikey: API_KEY, // Replace with your actual API key
        Authorization: `Bearer ${token}`, // Include the Bearer token in the header
      },
      body: JSON.stringify({ userid: data }), // Serialize the data object to JSON
    });

    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
    }

    return await res.json();
  } catch (error) {
     
    return { error: "Failed to fetch data" }; // Return a default error response
  }
};

export const postReportedAstrologers = async (token, data) => {
   
  try {
    const res = await fetch(`${API_URL}/block_astrologers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apikey: API_KEY, // Replace with your actual API key
        Authorization: `Bearer ${token}`, // Include the Bearer token in the header
      },
      body: JSON.stringify({
        userid: data?.userId,
        astroid: data?.astroId,
        message: data?.message,
      }), // Serialize the data object to JSON
    });

    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
    }

    return await res.json();
  } catch (error) {
     
    return { error: "Failed to fetch data" }; // Return a default error response
  }
};

// export const postEditProfile = async (token, data) => {
//      
//      
//     try {
//         const formData = new FormData();
//         formData.append('userid', data?.id);
//         formData.append('title', data?.title);
//         formData.append('Name', data?.name);
//         formData.append('email', data?.email);
//         formData.append('day', data?.day);
//         formData.append('month', data?.month);
//         formData.append('year', data?.year);
//         formData.append('hour', data?.hour);
//         formData.append('minute', data?.minute);
//         formData.append('second', data?.second);
//         formData.append('profile', data?.profile);
//         formData.append('place_of_birth', data?.PlaceOfBirth);
//         // formData.append('place_of_birth', data?.place_of_birth);
//         formData.append('Marital', data?.maritalstatus);
//         formData.append('Occupation', data?.occupation);
//         formData.append('Gender', data?.gender);
//         formData.append('cntrycode', data?.countrycode);

//         // If there are files, append them to FormData
//         // formData.append('file', fileInput.files[0]); // Example for a file input

//         const res = await fetch(`${API_URL}/edit-profile`, {
//             method: "POST",
//             headers: {
//                 "apikey": API_KEY, // Replace with your actual API key
//                 'Authorization': `Bearer ${token}` // Include the Bearer token in the header
//                 // Do not set Content-Type header for FormData, it will be set automatically
//             },
//             body: formData // Use FormData as the body of the request
//         });

//         if (!res.ok) {
//             throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
//         }

//         return await res.json();
//     } catch (error) {
//          
//         return { error: "Failed to fetch data" }; // Return a default error response
//     }
// };

export const postEditProfile = async (token, formData) => {
  try {
    for (let [key, value] of formData.entries()) {
       
    }
    const response = await fetch(`${API_URL}/edit-profile`, {
      method: "POST",
      headers: {
        apikey: API_KEY, // Replace with your actual API key
        Authorization: `Bearer ${token}`, // Include the Bearer token in the header
      },
      body: formData, // Use FormData as the body of the request
    });

    const data = await response.json();

    if (response.ok) {
      return data; // Return parsed response data
    } else {
      // Handle possible errors
      throw new Error(data.message || "Failed to update profile");
    }
  } catch (error) {
     
    throw error;
  }
};

//All services Api's

const getAuthorizationHeader = () => {
  const username = REACT_APP_USER_NAME;
  const password = REACT_APP_USER_PASSWORD;
  return "Basic " + btoa(username + ":" + password);
};

const handleApiResponse = async (response) => {
  if (!response.ok) {
    throw new Error(
      `Failed to fetch: ${response.status} ${response.statusText}`
    );
  }
  return await response.json();
};

export const todayHoroscopePredictionApi = async (data) => {
   

  try {
    const res = await fetch(
      `${REACT_APP_JSON_API_URL}/sun_sign_prediction/daily/${data?.horosName}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorizationHeader(),
          "Accept-Language": "hi", // Set language to Hindi
        },
        body: JSON.stringify({ timezone: data?.timezone }), // Include timezone if needed
      }
    );

    return await handleApiResponse(res);
  } catch (error) {
     
    return { error: "Failed to fetch data" };
  }
};

export const tomorrowHoroscopePredictionApi = async (data) => {
   
  try {
    const res = await fetch(
      `${REACT_APP_JSON_API_URL}/sun_sign_prediction/daily/next/${data?.horosName}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "hi", // Set language to Hindi,
          Authorization: getAuthorizationHeader(),
        },
        body: JSON.stringify({ timezone: data?.timezone }),
      }
    );

    return await handleApiResponse(res);
  } catch (error) {
     
    return { error: "Failed to fetch data" };
  }
};

export const monthlyHoroscopePredictionApi = async (data) => {
  try {
    const res = await fetch(
      `${REACT_APP_JSON_API_URL}/horoscope_prediction/monthly/${data?.horosName}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "hi", // Set language to Hindi,
          Authorization: getAuthorizationHeader(),
        },
        body: JSON.stringify({ timezone: data?.timezone }),
      }
    );

    return await handleApiResponse(res);
  } catch (error) {
     
    return { error: "Failed to fetch data" };
  }
};

export const joinasAstrologer = async (data) => {
   
  try {
    const url = `${API_URL}/join-as-astrologer`;
     

    const formData = new FormData();

    // Append text fields to FormData
    formData.append("title", data?.title || "");
    formData.append("name", data?.Name || "");
    formData.append("gender", data?.gender || "");
    formData.append("email", data?.email || "");
    formData.append("mobile", data?.mobile || "");
    formData.append("day", data?.day || "");
    formData.append("month", data?.month || "");
    formData.append("year", data?.year || "");
    formData.append("qualification", data?.qualification || "");
    formData.append(
      "expertise",
      Array.isArray(data?.expertises)
        ? data.expertises.join(",")
        : data?.expertises || ""
    );
    formData.append("experience", data?.experience || "");
    formData.append("experiencemonth", data?.experiencemonth || "");
    formData.append(
      "language",
      Array.isArray(data?.languages)
        ? data.languages.join(",")
        : data?.languages || ""
    );
    formData.append("state", data?.state || "");
    formData.append("countrycode", data?.country_code || "");
    formData.append("resume", data?.resume || "");
    formData.append("photo", data?.photo || "");

    const res = await fetch(url, {
      method: "POST",
      // No need for 'apiKey' header if it's not required

      body: formData,
    });

    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
    }

    return await res.json();
  } catch (error) {
     
    return { error: "Failed to fetch data" }; // Return a default error response
  }
};

// join as astrologer api end

// terms and condition api start
export const termsConditionApi = async () => {
  try {
    const res = await fetch("`${API_URL}/fetchterms", {
      method: "GET",
      headers: {
        apiKey: API_KEY,
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${apiKey}` // Add the API key to the headers
      },
    });

    if (!res.ok) {
      // Detailed error information
      const errorText = await res.text();
      throw new Error(
        `Failed to fetch: ${res.status} ${res.statusText} - ${errorText}`
      );
    }

    return await res.json();
  } catch (error) {
     
    return { error: error.message }; // Return the detailed error message
  }
};
// term and condition api end

//privacy policy api start
export const privacyPolicyApi = async () => {
  try {
    const res = await fetch(`${API_URL}/fetchprivacy`, {
      method: "GET",
      headers: {
        apiKey: API_KEY,
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${apiKey}` // Add the API key to the headers
      },
    });

    if (!res.ok) {
      // Detailed error information
      const errorText = await res.text();
      throw new Error(
        `Failed to fetch: ${res.status} ${res.statusText} - ${errorText}`
      );
    }

    return await res.json();
  } catch (error) {
     
    return { error: error.message }; // Return the detailed error message
  }
};
//privacy policy api end

// pricing policy api start
export const pricingPolicyApi = async () => {
  try {
    const res = await fetch(`${API_URL}/fetchprivacy`, {
      method: "GET",
      headers: {
        apiKey: API_KEY,
        "Content-Type": "application/json",
        // "Authorization": `Bearer ${apiKey}` // Add the API key to the headers
      },
    });

    if (!res.ok) {
      // Detailed error information
      const errorText = await res.text();
      throw new Error(
        `Failed to fetch: ${res.status} ${res.statusText} - ${errorText}`
      );
    }

    return await res.json();
  } catch (error) {
     
    return { error: error.message }; // Return the detailed error message
  }
};
// pricing policy api end

// Utility function for making API calls
const today = new Date();
const defaultData = {
  day: today.getDate(),
  month: today.getMonth() + 1, // Months are zero-based in JavaScript
  year: today.getFullYear(),
  hour: today.getHours(),
  min: today.getMinutes(),
  lat: 25.7,
  lon: 82.6,
   tzone: 5.5,
};

const isValidNumber = (value) => !isNaN(value) && isFinite(value);

const validateData = (data) => {
  return (
    isValidNumber(data.day) &&
    isValidNumber(data.month) &&
    isValidNumber(data.year) &&
    isValidNumber(data.hour) &&
    isValidNumber(data.min) &&
    isValidNumber(data.lat) &&
    isValidNumber(data.lon) &&
    isValidNumber(data.tzone)
  );
};

const makeApiCall = async (url, data = {}) => {
   
  const finalData = {
    day: Number(data.day) || defaultData.day,
    month: Number(data.month) || defaultData.month,
    year: Number(data.year) || defaultData.year,
    hour: Number(data.hour) || defaultData.hour,
    min: Number(data.min) || defaultData.min,
    lat: Number(data.lat) || defaultData.lat,
    lon: Number(data.lon) || defaultData.lon,
    tzone: Number(data.tzone) || defaultData.tzone,
  };

  if (!validateData(finalData)) {
     
    return { error: "Invalid data format" };
  }

   

  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "en",
        Authorization: getAuthorizationHeader(),
      },
      body: JSON.stringify(finalData),
    });

    return await handleApiResponse(res);
  } catch (error) {
     
    return { error: error.message || "Failed to fetch data" };
  }
};

// All Panchang API functions
export const getBasicPanchang = (data) =>
  makeApiCall(`${REACT_APP_JSON_API_URL}/basic_panchang`, data);
export const getBasicPanchangSunrise = (data) =>
  makeApiCall(`${REACT_APP_JSON_API_URL}/basic_panchang/sunrise`, data);
export const getAdvancedPanchang = (data) =>
  makeApiCall(`${REACT_APP_JSON_API_URL}/advanced_panchang`, data);
export const getAdvancedPanchangSunrise = (data) =>
  makeApiCall(`${REACT_APP_JSON_API_URL}/advanced_panchang/sunrise`, data);
export const getPlanetPanchang = (data) =>
  makeApiCall(`${REACT_APP_JSON_API_URL}/advanced_panchang/sunrise`, data);
export const getPlanetPanchangSunrise = (data) =>
  makeApiCall(`${REACT_APP_JSON_API_URL}/planet_panchang/sunrise`, data);
export const getChaughadiyaMuhurta = (data) =>
  makeApiCall(`${REACT_APP_JSON_API_URL}/chaughadiya_muhurta`, data);
export const panchangLagnaTable = (data) =>
  makeApiCall(`${REACT_APP_JSON_API_URL}/panchang_lagna_table`, data);
export const monthlyPanchang = (data) =>
  makeApiCall(`${REACT_APP_JSON_API_URL}/monthly_panchang`, data);
export const getHoraMuhurta = (data) =>
  makeApiCall(`${REACT_APP_JSON_API_URL}/hora_muhurta`, data);

// export const getKpcuspMuhurta = (data) => makeApiCall(`${REACT_APP_JSON_API_URL}/chart-kpcusp`, data);

// All Panchang Api

export const sliderApi = async () => {
  try {
    // Attempt to fetch data from the API
    const res = await fetch(`${API_URL}/fetch_banner`, {
      method: "GET",
      headers: {
        apiKey: API_KEY,
        "Content-Type": "application/json",
      },
    });

    // Check if the response is not successful (e.g., HTTP status code 4xx or 5xx)
    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    // Parse the response body as JSON and return it
    return await res.json();
  } catch (error) {
    // If an error occurs during the fetch or parsing
     
    throw error; // Throw the error further to handle it elsewhere if needed
  }
};

export const getAstro_details = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.latForKundli),
    lon: Number(data?.lonForKundli),
     tzone: Number(data?.timezone),
  };

   

  try {
    const response = await fetch(`${REACT_APP_JSON_API_URL}/astro_details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
      },
      body: JSON.stringify(sendFinalData), // Send data directly
    });

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
       
       
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
   
    return { error: "Failed to fetch data" };
  }
};

export const getBirth_details = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.latForKundli),
    lon: Number(data?.lonForKundli),
    tzone: Number(data?.timezone),
    //  tzone: Number(data?.timezone),
  };

   

  try {
    const response = await fetch(`${REACT_APP_JSON_API_URL}/birth_details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
      },
      body: JSON.stringify(sendFinalData), // Send data directly
    });

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
     
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
     
    return { error: "Failed to fetch data" };
  }
};

export const sadhesati_life_details = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.latForKundli),
    lon: Number(data?.lonForKundli),
     tzone: Number(data?.timezone),
  };

   

  try {
    const response = await fetch("${REACT_APP_JSON_API_URL}/astro_details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
      },
      body: JSON.stringify(sendFinalData), // Send data directly
    });

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
    
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
     
    return { error: "Failed to fetch data" };
  }
};

export const sadhesati_Into_details = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.latitude),
    lon: Number(data?.longitude),
     tzone: Number(data?.timezone),
  };

   

  try {
    const response = await fetch(
      "${REACT_APP_JSON_API_URL}/sadhesati_remedies",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
        },
        body: JSON.stringify(sendFinalData), // Send data directly
      }
    );

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
      
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
    
    return { error: "Failed to fetch data" };
  }
};

export const sadhesati_Current_details = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.latitude),
    lon: Number(data?.longitude),
     tzone: Number(data?.timezone),
  };

   

  try {
    const response = await fetch(
      "${REACT_APP_JSON_API_URL}/sadhesati_current_status",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
        },
        body: JSON.stringify(sendFinalData), // Send data directly
      }
    );

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
      
     
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
    
    return { error: "Failed to fetch data" };
  }
};

// Remedies
export const rudraksha_suggestion = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.latitude),
    lon: Number(data?.longitude),
     tzone: Number(data?.timezone),
  };

   

  try {
    const response = await fetch(
      `${REACT_APP_JSON_API_URL}/rudraksha_suggestion`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
        },
        body: JSON.stringify(sendFinalData), // Send data directly
      }
    );

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
      
     
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
   
    return { error: "Failed to fetch data" };
  }
};

export const Gemstones = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.latitude),
    lon: Number(data?.longitude),
     tzone: Number(data?.timezone),
  };

   

  try {
    const response = await fetch(
      `${REACT_APP_JSON_API_URL}/basic_gem_suggestion`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
        },
        body: JSON.stringify(sendFinalData), // Send data directly
      }
    );

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
     
      
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
  
    return { error: "Failed to fetch data" };
  }
};

export const mantra = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.latForKundli),
    lon: Number(data?.lonForKundli),
     tzone: Number(data?.timezone),
  };

   

  try {
    const response = await fetch(
      `${REACT_APP_JSON_API_URL}/numero_fav_mantra`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
        },
        body: JSON.stringify(sendFinalData), // Send data directly
      }
    );

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
    
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
    
    return { error: "Failed to fetch data" };
  }
};

export const yantra = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.latitude),
    lon: Number(data?.longitude),
     tzone: Number(data?.timezone),
  };

   

  try {
    const response = await fetch(`${REACT_APP_JSON_API_URL}/ayanamsha`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
      },
      body: JSON.stringify(sendFinalData), // Send data directly
    });

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
    
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
   
    return { error: "Failed to fetch data" };
  }
};

export const kalsarpa_details = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.latForKundli),
    lon: Number(data?.lonForKundli),
     tzone: Number(data?.timezone),
  };

   

  try {
    const response = await fetch(`${REACT_APP_JSON_API_URL}/kalsarpa_details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
      },
      body: JSON.stringify(sendFinalData), // Send data directly
    });

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
    
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
    
    return { error: "Failed to fetch data" };
  }
};

export const kalsarpa_remedy = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.latitude),
    lon: Number(data?.longitude),
     tzone: Number(data?.timezone),
  };

   

  try {
    const response = await fetch("${REACT_APP_JSON_API_URL}/kalsarpa_remedy", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
      },
      body: JSON.stringify(sendFinalData), // Send data directly
    });

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
     
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
   
    return { error: "Failed to fetch data" };
  }
};

export const manglik = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.latitude),
    lon: Number(data?.longitude),
     tzone: Number(data?.timezone),
  };

   

  try {
    const response = await fetch(`${REACT_APP_JSON_API_URL}/manglik`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
      },
      body: JSON.stringify(sendFinalData), // Send data directly
    });

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
      
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
   
    return { error: "Failed to fetch data" };
  }
};

export const manglik_remedy = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.latitude),
    lon: Number(data?.longitude),
     tzone: Number(data?.timezone),
  };

   

  try {
    const response = await fetch(`${REACT_APP_JSON_API_URL}/manglik_remedy`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
      },
      body: JSON.stringify(sendFinalData), // Send data directly
    });

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
     
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
    
    return { error: "Failed to fetch data" };
  }
};

export const kp_birth_chart = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.latForKundli),
    lon: Number(data?.lonForKundli),
     tzone: Number(data?.timezone),
  };

   

  try {
    const response = await fetch(`${REACT_APP_JSON_API_URL}/kp_birth_chart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
      },
      body: JSON.stringify(sendFinalData), // Send data directly
    });

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
      
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
    
    return { error: "Failed to fetch data" };
  }
};

export const kp_house_cuspsApi = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.lat),
    lon: Number(data?.lon),
    tzone: Number(data?.tzone),
  };

   

  try {
    const response = await fetch(`${REACT_APP_JSON_API_URL}/kp_house_cusps`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
      },
      body: JSON.stringify(sendFinalData), // Send data directly
    });

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
      
      
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
    
    return { error: "Failed to fetch data" };
  }
};

export const kp_planets_Api = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.lat),
    lon: Number(data?.lon),
    tzone: Number(data?.tzone),
  };

   

  try {
    const response = await fetch(`${REACT_APP_JSON_API_URL}/kp_planets`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
      },
      body: JSON.stringify(sendFinalData), // Send data directly
    });

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
     
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
   
    return { error: "Failed to fetch data" };
  }
};

export const kp_planets_Significator_Api = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.lat),
    lon: Number(data?.lon),
    tzone: Number(data?.tzone),
  };

   

  try {
    const response = await fetch(
      `${REACT_APP_JSON_API_URL}/kp_planet_significator`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
        },
        body: JSON.stringify(sendFinalData), // Send data directly
      }
    );

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
     
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

export const kp_House_Significator_Api = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.lat),
    lon: Number(data?.lon),
    tzone: Number(data?.tzone),
  };

   

  try {
    const response = await fetch(
      `${REACT_APP_JSON_API_URL}/kp_house_significator`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
        },
        body: JSON.stringify(sendFinalData), // Send data directly
      }
    );

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
      
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

export const panchang_lagna_table = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.latForKundli),
    lon: Number(data?.lonForKundli),
     tzone: Number(data?.timezone),
  };

   

  try {
    const response = await fetch(
      `${REACT_APP_JSON_API_URL}/panchang_lagna_table`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
        },
        body: JSON.stringify(sendFinalData), // Send data directly
      }
    );

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
     
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

export const match_planet_details = async (data) => {
  const sendFinalData = {
    m_day: Number(data?.day),
    m_month: Number(data?.month),
    m_year: Number(data?.year),
    m_hour: Number(data?.hours),
    m_min: Number(data?.minutes),
    m_lat: Number(data?.latitude),
    m_lon: Number(data?.longitude),
    m_tzone: 5.5,
    f_day: Number(data?.day),
    f_month: Number(data?.month),
    f_year: Number(data?.year),
    f_hour: Number(data?.hours),
    f_min: Number(data?.minutes),
    f_lat: Number(data?.latitude),
    f_lon: Number(data?.longitude),
    f_tzone: 5.5,
  };

   

  try {
    const response = await fetch(
      `${REACT_APP_JSON_API_URL}/match_planet_details`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
        },
        body: JSON.stringify(sendFinalData), // Send data directly
      }
    );

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
     
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

export const current_vdasha_all = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.latitude),
    lon: Number(data?.longitude),
     tzone: Number(data?.timezone),
  };

   

  try {
    const response = await fetch(
      `${REACT_APP_JSON_API_URL}/current_vdasha_all`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
        },
        body: JSON.stringify(sendFinalData), // Send data directly
      }
    );

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
    
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

export const sarvashtak = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.latitude),
    lon: Number(data?.longitude),
     tzone: Number(data?.tzone),
  };

   

  try {
    const response = await fetch(`${REACT_APP_JSON_API_URL}/sarvashtak`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
      },
      body: JSON.stringify(sendFinalData), // Send data directly
    });

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
     
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

export const major_vdasha_Api = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.lon),
    lon: Number(data?.lat),
     tzone: Number(data?.timezone),
  };

   

  try {
    const response = await fetch(`${REACT_APP_JSON_API_URL}/major_vdasha`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
      },
      body: JSON.stringify(sendFinalData), // Send data directly
    });

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
     
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

// Krishnamurti Paddati
export const astrologyKpChart = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.latitude),
    lon: Number(data?.longitude),
     tzone: Number(data?.timezone),
  };

   

  try {
    const response = await fetch(`${REACT_APP_JSON_API_URL}/sarvashtak`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
      },
      body: JSON.stringify(sendFinalData), // Send data directly
    });

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
     
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

// Krishnamurti Paddati
export const AstrologyApiAshtak = async (data) => {
   
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.lat),
    lon: Number(data?.lon),
    tzone: Number(data?.tzone),
  };

   

  try {
    const response = await fetch(
      `${REACT_APP_JSON_API_URL}/planet_ashtak/mars`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
        },
        body: JSON.stringify(sendFinalData), // Send data directly
      }
    );

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
     
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

export const AstrologyYoginiDasha = async (data) => {
   
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.lat),
    lon: Number(data?.lon),
    tzone: Number(data?.tzone),
  };

   

  try {
    const response = await fetch(`${REACT_APP_JSON_API_URL}/sub_yogini_dasha`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
      },
      body: JSON.stringify(sendFinalData), // Send data directly
    });

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
     
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

export const general_ascendant_report = async (data) => {
  const sendFinalData = {
    day: Number(data?.day), // Ensure these are numbers
    month: Number(data?.month),
    year: Number(data?.year),
    hour: Number(data?.hours),
    min: Number(data?.minutes),
    lat: Number(data?.latitude),
    lon: Number(data?.longitude),
     tzone: Number(data?.timezone),
  };

   

  try {
    const response = await fetch(
      `${REACT_APP_JSON_API_URL}/general_ascendant_report`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
        },
        body: JSON.stringify(sendFinalData), // Send data directly
      }
    );

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
     
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

// Remedies

// Ensure handleApiResponse is defined and implemented
// function handleApiResponse(response) {
//     return response.json(); // Adjust based on API response structure
// }

// Define or adjust handleApiResponse if necessary
// function handleApiResponse(response) {
//     return response.json(); // Adjust based on API response structure
// }

export const sadhesati_report = async (data) => {
   
  const userid = REACT_APP_USER_ID;
  const authcode = REACT_APP_AUTH_CODE;

  const formData = new FormData();
  formData.append("d", data?.date || "");
  formData.append("t", data?.time || "");
  formData.append("lat", data?.lat || "");
  formData.append("lon", data?.lon || "");
  formData.append("tz", data?.tzone || "");
  formData.append("userid", userid);
  formData.append("authcode", authcode);

  try {
    const response = await fetch(
      `${REACT_APP_KUNDALI_CLICK_API_URL}/sadesati`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
     
      throw new Error(
        `HTTP error! Status: ${response.status}, Message: ${errorText}`
      );
    }

    return await response.json(); // Adjust this based on your actual response format
  } catch (error) {
    // Optionally handle the error or return a specific error object
    return { error: "Failed to fetch data", details: error.message };
  }
};

export const lagnaKundli = async (data) => {
   
  const userid = REACT_APP_USER_ID;
  const authcode = REACT_APP_AUTH_CODE;

  const formData = new FormData();
  formData.append("d", data?.date || "");
  formData.append("t", data?.time || "");
  formData.append("lat", data?.lat || "");
  formData.append("lon", data?.lon || "");
  formData.append("tz", data?.tzone || "");
  formData.append("userid", userid);
  formData.append("authcode", authcode);

  try {
    const response = await fetch(
      `${REACT_APP_KUNDALI_CLICK_API_URL}/chart-planet`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
    
      throw new Error(
        `HTTP error! Status: ${response.status}, Message: ${errorText}`
      );
    }

    return await response.json(); // Adjust this based on your actual response format
  } catch (error) {
    // Optionally handle the error or return a specific error object
    return { error: "Failed to fetch data", details: error.message };
  }
};

// export const lagnaKundali = async (data) => {
//     const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/chart-planet`;
//     return makeApiCallOfKundali(url, data);
// };

export const fetch_sadhesati_life_report = async (data) => {
   
  const userid = REACT_APP_USER_ID;
  const authcode = REACT_APP_AUTH_CODE;

  const formData = new FormData();
  formData.append("d", data?.date || "");
  formData.append("t", data?.time || "");
  formData.append("lat", data?.lat || "");
  formData.append("lon", data?.lon || "");
  formData.append("tz", data?.tzone || "");
  formData.append("userid", userid);
  formData.append("authcode", authcode);

  try {
    const response = await fetch(
      `${REACT_APP_KUNDALI_CLICK_API_URL}/sadesati-lifereport`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      
      throw new Error(
        `HTTP error! Status: ${response.status}, Message: ${errorText}`
      );
    }

    return await response.json(); // Adjust this based on your actual response format
  } catch (error) {
    // Optionally handle the error or return a specific error object
    return { error: "Failed to fetch data", details: error.message };
  }
};
export const fetch_sadhesati_remedies = async (data) => {
   
  const userid = REACT_APP_USER_ID;
  const authcode = REACT_APP_AUTH_CODE;

  const formData = new FormData();
  formData.append("d", data?.date || "");
  formData.append("t", data?.time || "");
  formData.append("lat", data?.lat || "");
  formData.append("lon", data?.lon || "");
  formData.append("tz", data?.tzone || "");
  formData.append("userid", userid);
  formData.append("authcode", authcode);

  try {
    const response = await fetch(
      `${REACT_APP_KUNDALI_CLICK_API_URL}/sadesati-remedies`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
    
      throw new Error(
        `HTTP error! Status: ${response.status}, Message: ${errorText}`
      );
    }

    return await response.json(); // Adjust this based on your actual response format
  } catch (error) {
    // Optionally handle the error or return a specific error object
    return { error: "Failed to fetch data", details: error.message };
  }
};

export const getDownloadKundli = async (data) => {
   
  const userid = REACT_APP_USER_ID;
  const authcode = REACT_APP_AUTH_CODE;

  const formData = new FormData();
  formData.append("d", data?.date || "");
  formData.append("name", data?.Name || "");
  formData.append("pob", data?.pob || "");
  formData.append("gender", data?.gender || "");
  formData.append("t", data?.time || "");
  formData.append("lat", data?.lat || "");
  formData.append("lon", data?.lon || "");
  formData.append("tz", data?.tzone || "");
  formData.append("userid", userid);
  formData.append("authcode", authcode);

  try {
    const response = await fetch(
      `${REACT_APP_KUNDALI_CLICK_API_URL}/pdf-kundli`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
    
      throw new Error(
        `HTTP error! Status: ${response.status}, Message: ${errorText}`
      );
    }

    return await response.json(); // Adjust this based on your actual response format
  } catch (error) {
    // Optionally handle the error or return a specific error object
    return { error: "Failed to fetch data", details: error.message };
  }
};

export const chakraSudarshan = async (data) => {
   

  const date = `${data?.day}/${data?.month}/${data?.year}`;
  const time = `${data?.hour}:${data?.min}:${data?.second}`;
  const userid = REACT_APP_USER_ID;
  const authcode = REACT_APP_AUTH_CODE;

  const formData = new FormData();
  formData.append("d", date || "1/1/1900");
  formData.append("t", time || "02:01:02");
  formData.append("lat", data?.lat || defaultData?.lat);
  formData.append("lon", data?.lon || defaultData?.lon);
  formData.append("tz", data?.tzone || defaultData?.tzone);
  formData.append("userid", userid);
  formData.append("authcode", authcode);

  try {
    const response = await fetch(
      `${REACT_APP_KUNDALI_CLICK_API_URL}/chakra-sudarshan`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
     
      throw new Error(
        `HTTP error! Status: ${response.status}, Message: ${errorText}`
      );
    }

    return await response.json(); // Adjust this based on your actual response format
  } catch (error) {
    // Optionally handle the error or return a specific error object
    return { error: "Failed to fetch data", details: error.message };
  }
};

// Utility function to make API calls
const makeApiCallOfKundali = async (url, data) => {
   
  const userid = REACT_APP_USER_ID;
  const authcode = REACT_APP_AUTH_CODE;

  const formData = new FormData();
  formData.append("d", data?.date || "");
  formData.append("t", data?.time || "");
  formData.append("lat", data?.lat || "");
  formData.append("lon", data?.lon || "");
  formData.append("tz", data?.tzone || "5.5");
  formData.append("plnt_no", data?.ele || "2");
  formData.append("userid", userid);
  formData.append("authcode", authcode);
  formData.append("div", data?.ele || "10");
  formData.append("planet_name", data?.planet);

  try {
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      const errorText = await response.text();
     
      throw new Error(
        `HTTP error! Status: ${response.status}, Message: ${errorText}`
      );
    }

    return await response.json(); // Adjust this based on your actual response format
  } catch (error) {
    return { error: "Failed to fetch data", details: error.message };
  }
};
export const mantraRemediesApi = (data) => {
   
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/remedy-mantra`;
  return makeApiCallOfKundali(url, data);
};
export const getKpcuspMuhurta = (data) => {
   
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/chart-kpcusp`;
  return makeApiCallOfKundali(url, data);
};
export const chalitKundali = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/chart-chalit`;
  return makeApiCallOfKundali(url, data);
};
export const lagnaKundali = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/chart-asc`;
  return makeApiCallOfKundali(url, data);
};

export const chandraKundali = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/chart-planet`;
  return makeApiCallOfKundali(url, data);
};

export const getAscPredictions = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/asc-predictions`;
  return makeApiCallOfKundali(url, data);
};

export const NavamnshaKundali = async (data) => {
   
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/chart-dx`;
  return makeApiCallOfKundali(url, data);
};

//  Chart-Dx New Api Created

export const NavamnshaKundliDxChart = async (data) => {
   

  const userid = REACT_APP_USER_ID;
  const authcode = REACT_APP_AUTH_CODE;
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/chart-dx`;

  const formData = new FormData();
  formData.append("d", data?.date || "");
  formData.append("t", data?.time || "");
  formData.append("lat", data?.lat || "");
  formData.append("lon", data?.lon || "");
  formData.append("tz", data?.tzone || "5.5");
  formData.append("userid", userid);
  formData.append("authcode", authcode);
  formData.append("div", "9");

  try {
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      const errorText = await response.text();
     
      throw new Error(
        `HTTP error! Status: ${response.status}, Message: ${errorText}`
      );
    }

    return await response.json(); // Adjust this based on your actual response format
  } catch (error) {
    return { error: "Failed to fetch data", details: error.message };
  }
};






// NavamnshaKundali
export const dreshkhanKundali = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/chart-dx`;
  return makeApiCallOfKundali(url, data);
};
export const chathurthamanshaKundali = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/chart-dx`;
  return makeApiCallOfKundali(url, data);
};
export const saptamansha = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/chart-dx`;
  return makeApiCallOfKundali(url, data);
};
export const chathurthamansha = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/chart-dx`;
  return makeApiCallOfKundali(url, data);
};

export const gocharKundali = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/chart-transit`;
  return makeApiCallOfKundali(url, data);
};

export const luckGemstone = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/gemstone-lucky`;
  return makeApiCallOfKundali(url, data);
};

export const healthGemstone = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/gemstone-health`;
  return makeApiCallOfKundali(url, data);
};

export const educationGemstone = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/gemstone-education`;
  return makeApiCallOfKundali(url, data);
};

export const daanGemstone = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/gemstone-daan`;
  return makeApiCallOfKundali(url, data);
};

export const yantraRemedies = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/remedy-yantra`;
  return makeApiCallOfKundali(url, data);
};

export const rudrakshaRemedies = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/remedy-rudraksha`;
  return makeApiCallOfKundali(url, data);
};

export const getVinMahadashaPridictions = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/vinmahadasha-predictions`;
  return makeApiCallOfKundali(url, data);
};

// export const getVinMahadashaPridictions = (data) => makeApiCall(`${REACT_APP_JSON_API_URL}/vinmahadasha-predictions`, data);

// export const sarvashtakKundali = async (data) => {
//     const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/sarvashtakvarga`;
//     return makeApiCallOfKundali(url, data);
// };
export const astakKundali = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/ashtakvarga`;
  return makeApiCallOfKundali(url, data);
};
export const manglikDosha = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/manglikyog-report`;
  return makeApiCallOfKundali(url, data);
};

export const kaalSarpDosha = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/kaalsarpyog-report`;
  return makeApiCallOfKundali(url, data);
};

export const getPlanetinhousePredictions = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/planetinhouse-predictions`;
  return makeApiCallOfKundali(url, data);
};
export const getPlanetinrashiPredictions = async (data) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/planetinrashi-predictions`;
  return makeApiCallOfKundali(url, data);
};

// Add more functions as needed

// Helper function to make the API call
const makeApiCallOfKundaliMachingReport = async (url, data1, data2) => {
   
  const userid = REACT_APP_USER_ID;
  const authcode = REACT_APP_AUTH_CODE;
   
  const dateOfBoy = `${data1?.day}/${data1?.month}/${data1?.year}`;
  const dateOfGirl = `${data2?.day}/${data2?.month}/${data2?.year}`;
  const timeOfBoy = `${data1?.hours}:${data1?.minutes}:${data1?.seconds}`;
  const timeOfGirl = `${data2?.hours}:${data2?.minutes}:${data2?.seconds}`;

  const formData = new FormData();
  formData.append("md", dateOfBoy);
  formData.append("fd", dateOfGirl);
  formData.append("mt", timeOfBoy);
  formData.append("ft", timeOfGirl);
  formData.append("mlat", data1?.latBoy);
  formData.append("flat", data2?.latGirl);
  formData.append("mlon", data1?.lonBoy);
  formData.append("flon", data2?.lonGirl);

  formData.append("mtz", data1?.BoyPlaceTimeZone || "5.5");
  formData.append("ftz", data2?.GirlPlaceTimeZone || "5.5");
  formData.append("userid", userid);
  formData.append("authcode", authcode);
   
  try {
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      const errorText = await response.text();
     
      throw new Error(
        `HTTP error! Status: ${response.status}, Message: ${errorText}`
      );
    }

    return await response.json();
  } catch (error) {
    return { error: "Failed to fetch data", details: error.message };
  }
};

// Function to get Lagna Kundali
export const manglikReportForKundaliMatching = async (
  data1,
  data2,
  lat,
  lon
) => {
   
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/manglik-dosha`;
  return makeApiCallOfKundaliMachingReport(url, data1, data2, lat, lon);
};

export const fetchAshtkootPoint = async (data1, data2, lat, lon) => {
  const url = `${REACT_APP_KUNDALI_CLICK_API_URL}/ashtkoot-points`;
  return makeApiCallOfKundaliMachingReport(url, data1, data2, lat, lon);
};

export const matchMakingReport = async (data1 = {}, data2 = {}) => {
  const sendFinalData = {
    m_day: Number(data1.day || 1), // Default to 1 if day is missing
    f_day: Number(data2.day || 1),
    m_month: Number(data1.month || 1), // Default to January (1) if month is missing
    f_month: Number(data2.month || 1),
    m_year: Number(data1.year || 2000), // Default to 2000 if year is missing
    f_year: Number(data2.year || 2000),
    m_hour: Number(data1.hours || 0), // Default to 0 if hours are missing
    f_hour: Number(data2.hours || 0),
    m_min: Number(data1.minutes || 0), // Default to 0 if minutes are missing
    f_min: Number(data2.minutes || 0),
    m_lat: Number(data1.latBoy || 0), // Default to 0 if latitude is missing
    f_lat: Number(data2.latGirl || 0),
    m_lon: Number(data1.lonBoy || 0), // Default to 0 if longitude is missing
    f_lon: Number(data2.lonGirl || 0),
    m_tzone: data1?.BoyPlaceTimeZone,
    f_tzone: data2?.GirlPlaceTimeZone,
  };

   

  try {
    const authHeader = getAuthorizationHeader(); // Ensure this returns a valid token
    if (!authHeader) {
      throw new Error("Authorization header is missing");
    }

    const response = await fetch(
      `${REACT_APP_JSON_API_URL}/match_making_report`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
        body: JSON.stringify(sendFinalData),
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Handle the API response
    return await handleApiResponse(response);
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

export const match_ashtakoot_report = async (data1 = {}, data2 = {}) => {
  const sendFinalData = {
    m_day: Number(data1.day || 1), // Default to 1 if day is missing
    f_day: Number(data2.day || 1),
    m_month: Number(data1.month || 1), // Default to January (1) if month is missing
    f_month: Number(data2.month || 1),
    m_year: Number(data1.year || 2000), // Default to 2000 if year is missing
    f_year: Number(data2.year || 2000),
    m_hour: Number(data1.hours || 0), // Default to 0 if hours are missing
    f_hour: Number(data2.hours || 0),
    m_min: Number(data1.minutes || 0), // Default to 0 if minutes are missing
    f_min: Number(data2.minutes || 0),
    m_lat: Number(data1.latBoy || 0), // Default to 0 if latitude is missing
    f_lat: Number(data2.latGirl || 0),
    m_lon: Number(data1.lonBoy || 0), // Default to 0 if longitude is missing
    f_lon: Number(data2.lonGirl || 0),
    m_tzone: data1?.BoyPlaceTimeZone,
    f_tzone: data2?.GirlPlaceTimeZone,
  };

   

  try {
    const authHeader = getAuthorizationHeader(); // Ensure this returns a valid token
    if (!authHeader) {
      throw new Error("Authorization header is missing");
    }

    const response = await fetch(
      `${REACT_APP_JSON_API_URL}/match_ashtakoot_report`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
        body: JSON.stringify(sendFinalData),
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
     
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Handle the API response
    return await handleApiResponse(response);
  } catch (error) {
    
    return { error: "Failed to fetch data" };
  }
};

export const calculateTzApi = async (data) => {
  const sendFinalData = {
    latitude: data?.latForKundli, // Correct key based on the previous API call
    longitude: data?.lonForKundli, // Correct key based on the previous API call
    date: data?.date || new Date().toISOString(), // Default to current date if missing
  };

   

  try {
    const authHeader = getAuthorizationHeader(); // Ensure this returns a valid token
    if (!authHeader) {
      throw new Error("Authorization header is missing");
    }

    const response = await fetch(
      `${REACT_APP_JSON_API_URL}/timezone_with_dst`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
        },
        body: JSON.stringify(sendFinalData),
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
    
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Handle the API response
    return await handleApiResponse(response);
  } catch (error) {
   
    return { error: "Failed to fetch data" };
  }
};

export const matchAstrologyMakingReport = async (data1 = {}, data2 = {}) => {
   

  const sendFinalData = {
    m_day: Number(data1.day || 1), // Default to 1 if day is missing
    f_day: Number(data2.day || 1),
    m_month: Number(data1.month || 1), // Default to January (1) if month is missing
    f_month: Number(data2.month || 1),
    m_year: Number(data1.year || 2000), // Default to 2000 if year is missing
    f_year: Number(data2.year || 2000),
    m_hour: Number(data1.hours || 0), // Default to 0 if hours are missing
    f_hour: Number(data2.hours || 0),
    m_min: Number(data1.minutes || 0), // Default to 0 if minutes are missing
    f_min: Number(data2.minutes || 0),
    m_lat: Number(data1.latBoy || 0), // Default to 0 if latitude is missing
    f_lat: Number(data2.latGirl || 0),
    m_lon: Number(data1.lonBoy || 0), // Default to 0 if longitude is missing
    f_lon: Number(data2.lonGirl || 0),
    m_tzone: data1?.BoyPlaceTimeZone,
    f_tzone: data2?.GirlPlaceTimeZone,
  };

   

  try {
    const response = await fetch(
      `${REACT_APP_JSON_API_URL}/match_ashtakoot_points`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
        },
        body: JSON.stringify(sendFinalData), // Send data directly
      }
    );

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
     
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
    
    return { error: "Failed to fetch data" };
  }
};

export const matchAstrologyManglikDoshaReport = async (
  data1 = {},
  data2 = {}
) => {
   

  const sendFinalData = {
    m_day: Number(data1.day || 1), // Default to 1 if day is missing
    f_day: Number(data2.day || 1),
    m_month: Number(data1.month || 1), // Default to January (1) if month is missing
    f_month: Number(data2.month || 1),
    m_year: Number(data1.year || 2000), // Default to 2000 if year is missing
    f_year: Number(data2.year || 2000),
    m_hour: Number(data1.hours || 0), // Default to 0 if hours are missing
    f_hour: Number(data2.hours || 0),
    m_min: Number(data1.minutes || 0), // Default to 0 if minutes are missing
    f_min: Number(data2.minutes || 0),
    m_lat: Number(data1.latitude || 0), // Default to 0 if latitude is missing
    f_lat: Number(data2.latitude || 0),
    m_lon: Number(data1.longitude || 0), // Default to 0 if longitude is missing
    f_lon: Number(data2.longitude || 0),
    m_tzone: data1?.BoyPlaceTimeZone,
    f_tzone: data2?.GirlPlaceTimeZone,
  };

   

  try {
    const response = await fetch(
      `${REACT_APP_JSON_API_URL}/match_manglik_report`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorizationHeader(), // Ensure this returns a valid token
        },
        body: JSON.stringify(sendFinalData), // Send data directly
      }
    );

    if (!response.ok) {
      const errorText = await response.text(); // Get error response text
    
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Return the result of handleApiResponse function
    return await handleApiResponse(response);
  } catch (error) {
    
    return { error: "Failed to fetch data" };
  }
};

export const astrologerExpertiseApis = async () => {
  try {
    const res = await fetch(`${API_URL}/astro_expertise`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        apiKey: API_KEY,
      },
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    return await res.json();
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while fetching astrologer expertise.",
    }; // Return a default error response
  }
};

export const astrologerLanguageApis = async () => {
  try {
    const res = await fetch(`${API_URL}/astro_language`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        apiKey: API_KEY,
      },
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    return await res.json();
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while fetching astrologer languages.",
    }; // Return a default error response
  }
};

export const fetchAllLiveAstrologers = async (reqid) => {
  try {
    const res = await fetch(
      `${API_URL}/astrologerAPIs/fetch_live_astrologers`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          apiKey: API_KEY,
        },
      }
    );

    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
    }

    return await res.json();
  } catch (error) {
    return { error: "Failed to fetch data" }; // Return a default error response
  }
};

// Send gift

export const PostAllGiftApi = async (data) => {
  try {
    const response = await fetch(`${API_URL}/add-gift`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apiKey: API_KEY,
        Authorization: `Bearer ${data?.token}`,
      },
      body: JSON.stringify({
        userid: data?.userid,
        astroid: data?.astroid,
        giftamount: data?.amount,
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to post gift: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    return { error: error.message }; // Return the error message
  }
};

// get All Gifts
export const getAllGiftApi = async () => {
  try {
    const res = await fetch(`${API_URL}/gift_plan`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        apiKey: API_KEY,
      },
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    return await res.json();
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while fetching gift plans.",
    }; // Return a default error response
  }
};

export const getAllBlockAstrologers = async (data) => {
  try {
    const res = await fetch(`${API_URL}/block_astrologers`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ userid: data.userid }),
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    return await res.json();
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while fetching blocked astrologers.",
    }; // Return a default error response
  }
};

export const BlockAstrologers = async (data) => {
  try {
    const res = await fetch(`${API_URL}/block_astrologers`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userid: data.userid,
        astroid: data.astrologerid,
        message: data.message,
      }),
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    return await res.json();
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while blocking the astrologer.",
    }; // Return a default error response
  }
};
export const unblockAstrologers = async (data) => {
  try {
    const res = await fetch(`${API_URL}/unblock_astrologer`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ userid: data.userid, astroid: data.astrologerid }),
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    return await res.json();
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while unblocking the astrologer.",
    }; // Return a default error response
  }
};

export const getSingleAstrologerApi = async (data) => {
  try {
    const res = await fetch(
      `${API_URL}/astrologerAPIs/fetch_astrologer_profile`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          apiKey: API_KEY,
        },
        body: JSON.stringify({
          astroId: data?.id || data?.astroId || data?.mainIId,
          userId: data?.userid || data.userId,
        }),
      }
    );

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    return await res.json();
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while fetching the astrologer's profile.",
    };
  }
};

export const getSingleAstrologerLiveApi = async (data) => {
  try {
    const res = await fetch(`${API_URL}/astrologerAPIs/fetch_live_astro`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        astro: data,
      }),
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    return await res.json();
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while fetching the astrologer's profile.",
    };
  }
};

export const searchAstrologerApi = async (data) => {
  try {
    const res = await fetch(
      `${API_URL}/astrologerAPIs/fetch_astrologers_search`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          apikey: API_KEY, // Replace with your actual API key
        },
        body: JSON.stringify({ search: data }),
      }
    );

    if (!res.ok) {
      const errorMessage = `Network response was not ok. Status: ${res.status}, Status Text: ${res.statusText}`;
      throw new Error(errorMessage);
    }

    return await res.json();
  } catch (error) {
   
    return {
      success: false,
      message:
        error.message || "An error occurred while searching for astrologers.",
    };
  }
};

export const postAstrologerReviewApi = async (data) => {
  try {
    const res = await fetch(`${API_URL}/give-rating`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apiKey: API_KEY,
      },
      body: JSON.stringify({
        astroid: data.astroId,
        userid: data.useridh,
        rating: data.rating,
        review: data.review || "", // Default to an empty string if review is null or undefined
      }),
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    return await res.json();
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while posting the review.",
    };
  }
};

//

export const GiftSendApi = async (data) => {
  try {
    const res = await fetch(`${API_URL}/add-gift`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apiKey: API_KEY,
        Authorization: `Bearer ${data?.token}`,
      },
      body: JSON.stringify({
        userid: data.userid,
        astroid: data.astrologer,
        giftamount: data?.amount,
      }),
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    return await res.json();
  } catch (error) {
    return {
      success: false,
      
    };
  }
};

// samuhik puja deatils start

export const samuhikpoojaDetails = async (Data) => {
  try {
    const res = await fetch(
      `${API_URL}/samuhikPoojaAPIs/samuhik-pooja-details=${Data}`,
      {
        method: "POST",
        headers: {
          apiKey: API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          poojaId: "8",
        }),
      }
    );

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    return await res.json();
  } catch (error) {
 
    throw error; // Re-throw the error after logging it, or handle it accordingly
  }
};

// end

export const fetchChatStatusChange = async (reqid) => {
  try {
    const res = await fetch(`${API_URL}/chatAPIs/get_new_status_refresh`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apiKey: API_KEY,
      },
      body: JSON.stringify({ requestId: reqid }),
    });

    if (!res.ok) {
      throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
    }

    return await res.json();
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

export const FollowUnfollowApi = async (data) => {
  try {
    const res = await fetch(`${API_URL}/followAPIs/follow_API`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apiKey: API_KEY,
        Authorization: `Bearer ${data?.token}`,
      },
      body: JSON.stringify({
        userid: data?.userid,
        astrologer_id: data?.astroid,
      }),
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    return await res.json();
  } catch (error) {
    throw error; // Propagate error to the caller
  }
};

// Fetch Api
export const freeKundaliApi = async (id) => {
  try {
    const res = await fetch(`${API_URL}/fetch-saved-kundli`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apiKey: API_KEY,
      },
      body: JSON.stringify({ userid: id }),
    });

    if (!res.ok) {
      const errorDetails = await res.text();
      throw new Error(`Network response was not ok: ${errorDetails}`);
    }

    return await res.json();
  } catch (error) {
    throw error; // Propagate error to the caller
  }
};

export const alreadyReviewApi = async (data) => {
  try {
    const res = await fetch(
      `${API_URL}/astrologerAPIs/fetch_astro_rating_already`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          apiKey: API_KEY,
        },
        body: JSON.stringify({
          astroId: data?.astroid,
          userid: data?.userid,
        }),
      }
    );

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    return await res.json();
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

//
export const RechargefetchApi = async (data) => {
  try {
    const res = await fetch(`${API_URL}/recharge_fetch`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${data?.token}`,
        "Content-Type": "application/json",
        apiKey: API_KEY,
      },
      body: JSON.stringify({
        userid: data?.userId,
      }),
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    return await res.json();
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};
//

export const walletTransactionApi = async (data) => {
  try {
    const res = await fetch(`${API_URL}/wallet_transaction`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${data?.token}`,
        "Content-Type": "application/json",
        apiKey: API_KEY,
      },
      body: JSON.stringify({
        userid: data?.userId,
      }),
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    return await res.json();
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

export const subscribeNewsletterApi = async (data) => {
  try {
    const res = await fetch(`${API_URL}/newsletters`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apiKey: API_KEY,
      },
      body: JSON.stringify({
        email: data?.email,
        status: data?.status,
      }),
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    return await res.json();
  } catch (error) {
    return { error: "Failed to fetch data" };
  }
};

export const contactUsApi = async (data) => {
  try {
    const res = await fetch(`${API_URL}/contactus`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apiKey: API_KEY,
      },
      body: JSON.stringify({
        name: data?.name,
        email: data?.email,
        mobile: data?.phone, // Ensure the key matches the expected format
        message: data?.message,
      }),
    });

    if (!res.ok) {
      const errorData = await res.json();
      
      throw new Error("Network response was not ok");
    }

    return await res.json();
  } catch (error) {

    return { error: "Failed to fetch data" };
  }
};



export const getUserDetailsApi = async (token, id) => {
  if (id === null || token === null) {
    return { success: false, message: "User ID and token are required." };
  }
  try {
    const response = await fetch(`${API_URL}/view_profile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apikey: API_KEY,
        Authorization: `Bearer ${token}`, // Authorization header
      },
      body: JSON.stringify({ userid: id }), // Include the id in the request body with the correct key
    });

    if (!response.ok) {
      // Attempt to read and log the error message from the response
      const errorMessage = await response.text();

      throw new Error(`Network response was not ok: ${errorMessage}`);
    }

    try {
      const data = await response.json(); // Attempt to parse the JSON response

      return data;
    } catch (jsonError) {
      throw new Error("Error parsing JSON response: " + jsonError.message);
    }
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while fetching user details.",
    }; // Return a default error response
  }
};
