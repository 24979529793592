import React, { useEffect, useState } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import { decryptData, isAuthenticated, isUserId } from '../components/Url/Url';
import {  reportedAstrologers } from '../AllApi/AllApi';
const Blockedastro = () => {


  const [loginRes, setLoginRes] = useState("");
  const [blockAstroData, setBlockAstroData] = useState("");
   
  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-IN").format(amount);
    };
  
  async function fetchBlockAstro(){
  
    const decToken= isAuthenticated
    const userId=isUserId
     
    const  res= await reportedAstrologers(decToken,userId)
    setBlockAstroData(res)
    
    }
    useEffect(()=>{
      fetchBlockAstro()
    },[])
  return (
    <>
     {/* ========= */}
   
    {/* ====== */}
        {/* Reported Astrologers section start */}
        <section className="reported_astrologers_section">
  <div className="container">
    {/*  */}
    <div className="reported_astrologers_title_div">
  <h2>Reported Astrologers</h2>
  {blockAstroData && blockAstroData.length > 0 ? (
    blockAstroData.map((ele) => (
      <p key={ele?.id || ele?.name}>{ele?.name}</p>
    ))
  ) : (
    <p>No reported astrologers.</p>
  )}
</div>

   
  </div>
</section>
 
      {/* ticket section end */}
    </>
  )
}

export default Blockedastro
