import React, { useState } from 'react';
import axios from 'axios';
import { isAuthenticated } from '../components/Url/Url'; // Make sure to replace this with the actual path to your authentication utility

const InvoiceDownloadC = () => {
    const callbackurl=process.env.REACT_APP_DOMAIN_URL+'/wallet';
  const cancelurl=process.env.REACT_APP_DOMAIN_URL+'/wallet';
    const API_URL=process.env.REACT_APP_API_URL
    const [payment, setPayment] = useState('');
    const [userId, setUserId] = useState('');
  const [verifyRes,setVerifyRes]= useState('');
    const handlePayment = async () => {
        // Load the Razorpay script
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    
        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
    
        try {
            // Create a new order
            const { data } = await axios.post(
                `${API_URL}/razorpayAPIs/create-order`,
                { payment: payment, userId },
                {
                    headers: {
                        apiKey: process.env.REACT_APP_API_KEY,
                        Authorization: `Bearer ${isAuthenticated}`, // Include your actual authorization header here
                    },
                }
            );
    
            // Configure Razorpay options
            const options = {
                key: process.env.REACT_APP_RAZORPAY_TEST_KEY,
                amount: payment * 100, // Amount in paise
                currency: 'INR',
                name: 'Talkndheal',
                description: 'Payment for Order',
                order_id: data.order_id,
                handler: async (response) => {
                     
                    setVerifyRes(response);
                    try {
                        // Verify payment on backend
                        const verifyResponse = await axios.post(
                            `${API_URL}/razorpayAPIs/verify-payment`,
                            {
                                razorpay_order_id: response.razorpay_order_id,
                                razorpay_payment_id: response.razorpay_payment_id,
                                razorpay_signature: response.razorpay_signature,
                                user_id: userId,
                            },
                            {
                                headers: {
                                    apiKey: process.env.REACT_APP_API_KEY,
                                    Authorization: `Bearer ${isAuthenticated}`, // Include your actual authorization header here
                                },
                            }
                        );
                        
                         
                    } catch (error) {
                         
                        alert('Payment Verification Failed');
                    }
                },
                prefill: {
                    name: 'User Name', // Replace with user info
                    email: 'user@example.com', // Replace with user info
                    contact: '1234567890', // Replace with user info
                },
                notes: {
                    'shipping address': 'User Address', // Replace with user info
                },
                theme: {
                    color: '#F37254',
                },
                callback_url: callbackurl,
                cancel_url: cancelurl,
            };
    
            // Initialize and open the Razorpay checkout
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (error) {
            alert('Error in creating order');
        }
    };
    
   async function fetchDownloadInvoice(){
        const { data } = await axios.post(
            `${API_URL}/invoice`,
            { pay_id:verifyRes?.razorpay_payment_id  },
            {
                headers: {
                    apiKey: process.env.REACT_APP_API_KEY,
                    Authorization: `Bearer ${isAuthenticated}`, // Include your actual authorization header here
                },
            }
        );
        if(data?.success==true){
            const invoiceUrl = data.message; // Assuming this is the URL to the invoice
            window.open(invoiceUrl, '_blank'); // Opens the URL in a new tab
        }

    }
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.body.appendChild(script);
        });
    }

    return (
        <div>
            <h2>Pay with Razorpay</h2>
            <input
                type="number"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                placeholder="Enter User Id"
            />
            <input
                type="number"
                value={payment}
                onChange={(e) => setPayment(e.target.value)}
                placeholder="Enter Payment Amount"
            />
            <button onClick={handlePayment}>Pay Now</button>
            <div onClick={fetchDownloadInvoice}>
               download Invoice
            </div>
        </div>
    );
};

export default InvoiceDownloadC;
