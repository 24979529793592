import React, { useState, useRef, useEffect } from 'react';
import { joinasAstrologer } from '../data/AllapiData';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { toast, ToastContainer } from 'react-toastify';
import '../CssFiles/JoinAsastrologer.css';
import { isAuthenticated } from '../components/Url/Url';

const JoinAsastrologer = () => {
  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };
  
  // Array of month names
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  const [formData, setFormData] = useState({
    day: '',
    month: '',
    year: ''
  });

  const [daysInMonth, setDaysInMonth] = useState(31);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() => {
    if (formData.month && formData.year) {
      const days = new Date(formData.year, formData.month, 0).getDate();
      setDaysInMonth(days);
    }
  }, [formData.month, formData.year]);
  const getTodaysDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0'); // Day of the month, 2 digits
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Month, 2 digits (0-based index, so +1)
    const year = today.getFullYear(); // 4-digit year

    return { day, month, year };
  };

  // Initialize state with today's date
  const { day, month, year } = getTodaysDate();
  const today = new Date();
  const currentDay = today.getDate();
  const currentMonth = today.getMonth() + 1; // Months are 0-based, so add 1
  const currentYear = today.getFullYear();
  
  const [allAstrologerData, setAllAstrologerData] = useState({
    title: 'Mr',
    Name: '',
    gender: 'Male',
    email: '',
    country_code: '+91',
    mobile: '',
    day: currentDay,
    month: currentMonth,
    year: currentYear
  });
   
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedExpertises, setSelectedExpertises] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownVisibleExpertises, setDropdownVisibleExpertises] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownRefExpertises = useRef(null);

  const languages = [
      'Hindi', 'English', 'Kashmiri', 'Assamese', 'Telugu', 'Punjabi',
      'Gujarati', 'Kannada', 'Malayalam', 'Marathi', 'Odia', 'Tamil',
      'Bengali', 'Marwadi'
  ];

  const expertises = ['Tarot', 'Psychologist', 'Pranayama', 'Yoga mudras', 'Dietician', 'Sun Rays Healing', 'Sound and Words Healing', 'Vastu', 'Magnet Healing', 'Nature Healing', 'Palmistry', 'Acupressure Healing', 'Metal Healing', 'Crystal Healing', 'KP Syste', 'Lal Kitab', 'Vedic', 'Astrology'];

  const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setDropdownVisible(false);
      }
      if (dropdownRefExpertises.current && !dropdownRefExpertises.current.contains(event.target)) {
          setDropdownVisibleExpertises(false);
      }
  };

  const toggleDropdown = () => {
      setDropdownVisible(!dropdownVisible);
  };

  const toggleDropdownExpertises = () => {
      setDropdownVisibleExpertises(!dropdownVisibleExpertises);
  };

  const updateSelectedLanguages = (event) => {
      const { value, checked } = event.target;
      setSelectedLanguages((prevSelected) =>
          checked ? [...prevSelected, value] : prevSelected.filter((lang) => lang !== value)
      );
  };

  const updateSelectedExpertises = (event) => {
      const { value, checked } = event.target;
      setSelectedExpertises((prevSelected) =>
          checked ? [...prevSelected, value] : prevSelected.filter((expertise) => expertise !== value)
      );
  };

  const joinAstrologerChangeData = (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file') {
        // Handle file input
        setAllAstrologerData(prevData => ({
            ...prevData,
            [name]: files[0], // Assuming single file upload
        }));
    } else {
        // Handle text input
        setAllAstrologerData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    }
};


const joinAstrologerSubmitData = async (e) => {
  e.preventDefault();
  
  try {
    const token = isAuthenticated;

    // Prepare the data to be sent in the request
    const requestData = {
      ...allAstrologerData,
      token,
      languages: selectedLanguages,
      expertises: selectedExpertises,
    };

    // Send the data to the API
    const response = await joinasAstrologer(requestData);

    // Handle the API response
    if (response?.success) {
      toast.success(response?.message || 'Successfully joined as an astrologer');
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      toast.warn(response?.message || 'Warning: Could not join as an astrologer');
    }

  } catch (error) {
     
    toast.error('Failed to submit data');
  }
};

const componentRef = useRef(null); // Reference to the component
useEffect(() => {
  // Define the handleClickOutside function inside the useEffect
  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      // Handle the outside click event
     
    }
  };

  // Attach the event listener
  document.addEventListener('click', handleClickOutside, { passive: true });

  // Cleanup function to remove the event listener
  return () => {
    document.removeEventListener('click', handleClickOutside); // No options object in removeEventListener
  };
}, []); // Empty dependency array ensures it only runs once on mount/unmount


return (
<div ref={componentRef}>
    {/* ========= */}
    <ToastContainer/>
  
    {/* ====== */}
{/* join astrologer section start */}
<section className="joinastrologer_section">
<div className="container">
{/* join astrologer title start */}
<div className="joinastrologer_tittle">
<h1>Join As An Astrologer</h1>
<p>Are you a passionate and insightful astrologer/expert dedicated to helping others find clarity and purpose? We invite you to join our team at talkndheal! We're committed to using the wisdom of astrology to inspire personal growth and enhance well-being. Be a part of our mission to make a meaningful difference in people's lives.</p>
</div>
{/* join astrologer title end */}
{/* row start */}
<div className="row">
{/* col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12 order-lg-1 order-md-1 order-sm-2 order-2">
<div className="joinastrologer_txt_div">
<h2>Why Join talkndheal :</h2>
<p>Joining talkndheal as an astrologer/expert offers a multitude of benefits and opportunities that can significantly enhance your professional life while allowing you to contribute to the personal growth of others. Here are several compelling reasons why becoming a part of talkndheal’s online astrology platform is an excellent choice:</p>
<ol>
<li>
<strong>Enhance Personal Growth of People</strong>
<p> <b>Transformative Guidance:</b> As an astrologer/expert on talkndheal, you have the unique opportunity to guide individuals through their personal journeys, helping them gain clarity and insight into their life paths, relationships, and career choices. 
</p>
<p><b>Empowerment through Knowledge:</b> Your astrological readings and insights can empower clients to make informed decisions, understand their strengths and weaknesses, and unlock their true potential.</p>
</li>

<li>
<strong>Flexible Work Schedule</strong>
<p><b>Set Your Own Calendar:</b> Enjoy the freedom to set your own working hours, allowing you to balance your professional commitments with personal life and other interests.Work-Life Balance: Flexibility in scheduling helps you maintain a healthy work-life balance, enhancing your overall well-being and productivity.</p>
</li>

<li>
<strong>Support Diverse Community</strong>
<p><b>Inclusive Environment:</b> talkndheal fosters a diverse and inclusive community where people from various backgrounds and cultures come together, providing you with the opportunity to engage with a wide range of clients.</p>
<p><b>Global reach:</b> The platform allows you to connect with clients from around the world, broadening your impact and understanding of different cultural perspectives on astrology.</p>
</li>

<li>
<strong>Hi-Tech Platform</strong>
<p><b>Advanced Tools:</b> Leverage cutting-edge technology for accurate and detailed astrological readings. The platform’s advanced tools and resources enable you to deliver high-quality services efficiently.</p>
<p>User-Friendly Interface: The platform is designed for ease of use, both for astrologer/expert and clients, ensuring smooth and intuitive navigation for seamless interactions.</p>
</li>
</ol>
</div>
</div>
{/* col end */}

{/* col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12 order-lg-2 order-md-2 order-sm-1 order-1">
<div className="joinastrologer_main_div_form">
<form  onSubmit={joinAstrologerSubmitData} >
{/* nested row start */}
<div className="row">
{/* nested col start */}
<div className="col-lg-12 col-md-12 col-sm-12 col-12">
<div className="joinastrologer_from_title">
<h2>Join Our Team</h2>
<hr />
</div>
</div>
{/* nested col end */}

{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
<label htmlFor="name" className="form-label">Name <span style={{ color: 'red' }}>*</span></label>
<div className="input-group"> 
<select className="slct_form" name="title" onChange={joinAstrologerChangeData}  id="inputGroupSelect01">
{/*<option selected disabled>Select any one</option>*/}
<option selected value="Mr">Mr.</option>
<option value="Ms">Ms. </option>
<option value="Mrs">Mrs. </option>
</select>
<input type="text" name="Name" onChange={joinAstrologerChangeData} className="form-control"  placeholder="Name" required />
</div>
</div>
{/* nested col end */}

{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
<label for="inputState" className="form-label">Gender</label>
<select name="gender" onChange={joinAstrologerChangeData}  className="form-select">
<option selected value="Male">Male</option>
<option value="Female">Female</option>
<option value="Other">Other</option>
</select>
</div>
{/* nested col end */}

{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
<label for="email" className="form-label">Email <span style={{ color: 'red' }}>*</span></label>
<input type="email" name="email" className="form-control" onChange={joinAstrologerChangeData} placeholder="Email"   required/>
</div>
{/* nested col end */}

{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
<label for="mobile" className="form-label">Mobile <span style={{ color: 'red' }}>*</span></label>
<div className="input-group">
<select className="slct_form_country" name="countrycode" onChange={joinAstrologerChangeData} id="country_code" required value>
<option value="+91">+91 (India)</option>
<option  value="+93">+93 (Afghanistan)</option>
<option  value="+355">+355 (Albania)</option>
<option  value="+213">+213 (Algeria)</option>
<option  value="+376">+376 (Andorra)</option>
<option  value="+244">+244 (Angola)</option>
<option  value="+54">+54 (Argentina)</option>
<option  value="+374">+374 (Armenia)</option>
<option  value="+61">+61 (Australia)</option>
<option  value="+43">+43 (Austria)</option>
<option  value="+994">+994 (Azerbaijan)</option>
<option  value="+973">+973 (Bahrain)</option>
<option  value="+880">+880 (Bangladesh)</option>
<option  value="+375">+375 (Belarus)</option>
<option  value="+32">+32 (Belgium)</option>
<option  value="+501">+501 (Belize)</option>
<option  value="+229">+229 (Benin)</option>
<option  value="+975">+975 (Bhutan)</option>
<option  value="+591">+591 (Bolivia)</option>
<option  value="+387">+387 (Bosnia and Herzegovina)</option>
<option  value="+267">+267 (Botswana)</option>
<option  value="+55">+55 (Brazil)</option>
<option  value="+673">+673 (Brunei)</option>
<option  value="+359">+359 (Bulgaria)</option>
<option  value="+226">+226 (Burkina Faso)</option>
<option  value="+95">+95 (Myanmar)</option>
<option  value="+257">+257 (Burundi)</option>
<option  value="+855">+855 (Cambodia)</option>
<option  value="+237">+237 (Cameroon)</option>
<option  value="+1">+1 (Canada)</option>
<option  value="+238">+238 (Cape Verde)</option>
<option  value="+236">+236 (Central African Republic)</option>
<option  value="+235">+235 (Chad)</option>
<option  value="+56">+56 (Chile)</option>
<option  value="+86">+86 (China)</option>
<option  value="+57">+57 (Colombia)</option>
<option  value="+269">+269 (Comoros)</option>
<option  value="+242">+242 (Congo)</option>
<option  value="+243">+243 (Congo, Democratic Republic of the)</option>
<option  value="+506">+506 (Costa Rica)</option>
<option  value="+225">+225 (Ivory Coast)</option>
<option  value="+385">+385 (Croatia)</option>
<option  value="+53">+53 (Cuba)</option>
<option  value="+357">+357 (Cyprus)</option>
<option  value="+420">+420 (Czech Republic)</option>
<option  value="+45">+45 (Denmark)</option>
<option  value="+253">+253 (Djibouti)</option>
<option  value="+670">+670 (East Timor)</option>
<option  value="+593">+593 (Ecuador)</option>
<option  value="+20">+20 (Egypt)</option>
<option  value="+503">+503 (El Salvador)</option>
<option  value="+240">+240 (Equatorial Guinea)</option>
<option  value="+291">+291 (Eritrea)</option>
<option  value="+372">+372 (Estonia)</option>
<option  value="+251">+251 (Ethiopia)</option>
<option  value="+679">+679 (Fiji)</option>
<option  value="+358">+358 (Finland)</option>
<option  value="+33">+33 (France)</option>
<option  value="+241">+241 (Gabon)</option>
<option  value="+220">+220 (Gambia)</option>
<option  value="+995">+995 (Georgia)</option>
<option  value="+49">+49 (Germany)</option>
<option  value="+233">+233 (Ghana)</option>
<option  value="+30">+30 (Greece)</option>
<option  value="+299">+299 (Greenland)</option>
<option  value="+502">+502 (Guatemala)</option>
<option  value="+224">+224 (Guinea)</option>
<option  value="+245">+245 (Guinea-Bissau)</option>
<option  value="+592">+592 (Guyana)</option>
<option  value="+509">+509 (Haiti)</option>
<option  value="+504">+504 (Honduras)</option>
<option  value="+852">+852 (Hong Kong)</option>
<option  value="+36">+36 (Hungary)</option>
<option  value="+354">+354 (Iceland)</option>
<option selected value="+91">+91 (India)</option>
<option  value="+62">+62 (Indonesia)</option>
<option  value="+98">+98 (Iran)</option>
<option  value="+964">+964 (Iraq)</option>
<option  value="+353">+353 (Ireland)</option>
<option  value="+972">+972 (Israel)</option>
<option  value="+39">+39 (Italy)</option>
<option  value="+81">+81 (Japan)</option>
<option  value="+962">+962 (Jordan)</option>
<option  value="+7">+7 (Kazakhstan)</option>
<option  value="+254">+254 (Kenya)</option>
<option  value="+686">+686 (Kiribati)</option>
<option  value="+965">+965 (Kuwait)</option>
<option  value="+996">+996 (Kyrgyzstan)</option>
<option  value="+856">+856 (Laos)</option>
<option  value="+371">+371 (Latvia)</option>
<option  value="+961">+961 (Lebanon)</option>
<option  value="+266">+266 (Lesotho)</option>
<option  value="+231">+231 (Liberia)</option>
<option  value="+218">+218 (Libya)</option>
<option  value="+423">+423 (Liechtenstein)</option>
<option  value="+370">+370 (Lithuania)</option>
<option  value="+352">+352 (Luxembourg)</option>
<option  value="+853">+853 (Macau)</option>
<option  value="+389">+389 (Macedonia)</option>
<option  value="+261">+261 (Madagascar)</option>
<option  value="+265">+265 (Malawi)</option>
<option  value="+60">+60 (Malaysia)</option>
<option  value="+960">+960 (Maldives)</option>
<option  value="+223">+223 (Mali)</option>
<option  value="+356">+356 (Malta)</option>
<option  value="+692">+692 (Marshall Islands)</option>
<option  value="+222">+222 (Mauritania)</option>
<option  value="+230">+230 (Mauritius)</option>
<option  value="+52">+52 (Mexico)</option>
<option  value="+691">+691 (Micronesia)</option>
<option  value="+373">+373 (Moldova)</option>
<option  value="+377">+377 (Monaco)</option>
<option  value="+976">+976 (Mongolia)</option>
<option  value="+382">+382 (Montenegro)</option>
<option  value="+212">+212 (Morocco)</option>
<option  value="+258">+258 (Mozambique)</option>
<option  value="+264">+264 (Namibia)</option>
<option  value="+674">+674 (Nauru)</option>
<option  value="+977">+977 (Nepal)</option>
<option  value="+31">+31 (Netherlands)</option>
<option  value="+64">+64 (New Zealand)</option>
<option  value="+505">+505 (Nicaragua)</option>
<option  value="+227">+227 (Niger)</option>
<option  value="+234">+234 (Nigeria)</option>
<option  value="+47">+47 (Norway)</option>
<option  value="+968">+968 (Oman)</option>
<option  value="+92">+92 (Pakistan)</option>
<option  value="+680">+680 (Palau)</option>
<option  value="+507">+507 (Panama)</option>
<option  value="+675">+675 (Papua New Guinea)</option>
<option  value="+595">+595 (Paraguay)</option>
<option  value="+51">+51 (Peru)</option>
<option  value="+63">+63 (Philippines)</option>
<option  value="+48">+48 (Poland)</option>
<option  value="+351">+351 (Portugal)</option>
<option  value="+974">+974 (Qatar)</option>
<option  value="+40">+40 (Romania)</option>
<option  value="+7">+7 (Russia)</option>
<option  value="+250">+250 (Rwanda)</option>
<option  value="+685">+685 (Samoa)</option>
<option  value="+378">+378 (San Marino)</option>
<option  value="+239">+239 (Sao Tome and Principe)</option>
<option  value="+966">+966 (Saudi Arabia)</option>
<option  value="+221">+221 (Senegal)</option>
<option  value="+381">+381 (Serbia)</option>
<option  value="+248">+248 (Seychelles)</option>
<option  value="+232">+232 (Sierra Leone)</option>
<option  value="+65">+65 (Singapore)</option>
<option  value="+421">+421 (Slovakia)</option>
<option  value="+386">+386 (Slovenia)</option>
<option  value="+677">+677 (Solomon Islands)</option>
<option  value="+252">+252 (Somalia)</option>
<option  value="+27">+27 (South Africa)</option>
<option  value="+82">+82 (South Korea)</option>
<option  value="+211">+211 (South Sudan)</option>

</select>
<input type="text" name="mobile" onChange={joinAstrologerChangeData} className="form-control" maxlength="10" minlength="10" id="mobile" oninput="this.value = this.value.replace(/[^0-9]/g, '');" placeholder="Mobile" required/>
</div>
</div>
{/* nested col end */}

{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
      <label htmlFor="dob" className="form-label">
        Date Of Birth <span style={{ color: 'red' }}>*</span>
      </label>
      <div className="dob-inputs">
        <select
          className="form-select"
          id="day"
          name="day"
          value={formData.day}
          onChange={handleChange}
          required
        >
          <option disabled value="">DD</option>
          {generateOptions(1, daysInMonth)}
        </select>

        <select
          className="form-select"
          id="month"
          name="month"
          value={formData.month}
          onChange={handleChange}
          required
        >
          <option disabled value="">MM</option>
          {months.map((month, i) => (
            <option key={i + 1} value={i + 1}>
              {month}
            </option>
          ))}
        </select>

        <select
          className="form-select"
          id="year"
          name="year"
          value={formData.year}
          onChange={handleChange}
          required
        >
          <option disabled value="">YYYY</option>
          {generateOptions(1900, new Date().getFullYear())}
        </select>
      </div>
    </div>
{/* nested col end */}

{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
<label for="place" className="form-label">Qualification (Optional) </label>
<select name="qualification" onChange={joinAstrologerChangeData} className="form-select">
<option selected disabled>Select any one Qualification</option>
<option value="Primary education">Primary education</option>
<option value="Secondary education">Secondary education or high school</option>
<option value="Diploma Degree">Diploma Degree</option> 
<option value="Bachelor Degree">Bachelor Degree</option>
<option value="Master Degree">Master Degree</option>
<option value="Doctorate or higher">Doctorate or higher</option>
</select>
</div>
{/* nested col end */}


{/* nested col start */}

<div className="col-lg-6 col-md-6 col-sm-12 col-12">
      <div className="Strength_div_div">
        <div className="dropdown2" ref={dropdownRefExpertises}>
          <label htmlFor="place" className="form-label">
            Strength & Expertise <span style={{ color: 'red' }}>*</span>
          </label>
          <input type="text" className="form-control" name="expertise" id="selectedExpertises" value={selectedExpertises.join(', ')} placeholder="Select any one Expertise" readOnly onChange={joinAstrologerChangeData} onClick={toggleDropdownExpertises} required />
          {dropdownVisibleExpertises && (
            <div id="dropdownMenu2" className="dropdown-content2" onClick={(e) => e.stopPropagation()}>
            {expertises.map((expertise) => (
              <label key={expertise}>
                <input  type="checkbox" name="expertises" value={expertise} checked={selectedExpertises.includes(expertise)} onChange={(e) => {joinAstrologerChangeData(e);
                    updateSelectedExpertises(e); }} required/>
                {expertise}
              </label>
            ))}
          </div>
          )}
        </div>
      </div>
    </div>
{/* nested col end */}

{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
<label for="place" className="form-label">Experience   <span style={{ color: 'red' }}>*</span></label>
<div className="exprience_flx_div">
{/* <input type="text" className="form-control" placeholder="Year" name="experience" onChange={joinAstrologerChangeData} id="place" oninput="this.value = this.value.replace(/[^0-9.+]/g, '').replace(/(\..?)\../g, '$1');" required/> */}
<input
  type="text"
  className="form-control"
  placeholder="Year"
  name="experience"
  onChange={joinAstrologerChangeData}
  id="place"
  onInput={(e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
    if (e.target.value.length > 2) {
      e.target.value = e.target.value.slice(0, 2); // Limit input length to 2 characters
    }
  }}
  maxLength="2" // Limit input length to 2 characters
  required
/>
<input
  type="text"
  className="form-control"
  placeholder="Month"
  name="experiencemonth"
  onChange={joinAstrologerChangeData}
  id="place"
  onInput={(e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
    if (parseInt(e.target.value, 10) > 11) {
      e.target.value = "11"; // Set maximum limit to 11
    }
  }}
  maxLength="2" // Limit input length to 2 characters
  required
/>
{/* <input type="text" className="form-control" placeholder="Month" name="experiencemonth" onChange={joinAstrologerChangeData} id="place" oninput="this.value = this.value.replace(/[^0-9.+]/g, '').replace(/(\..?)\../g, '$1');" max="11" maxlength="2" required/> */}
</div>
</div>
{/* nested col end */}

{/* nested col start */}

<div className="col-lg-6 col-md-6 col-sm-12 col-12">
<div className="language_div_div">
      <div className="dropdown" ref={dropdownRef}>
        
        <label htmlFor="place" className="form-label">
          Languages Known <span style={{ color: 'red' }}>*</span>
        </label>
        <input type="text" className="form-control"  name="language" onChange={joinAstrologerChangeData}  id="selectedLanguages" value={selectedLanguages.join(', ')} placeholder="Select Languages" readOnly onClick={toggleDropdown}/>
        {dropdownVisible && (
          <div id="dropdownMenu" className="dropdown-content" onClick={(e) => e.stopPropagation()}>
            {languages.map((language) => (
              <label key={language}>
                <input type="checkbox" name="languages"  value={language} checked={selectedLanguages.includes(language)}
                 
                  onChange={(e) => {
                    joinAstrologerChangeData(e);
                    updateSelectedLanguages(e);
                  }}
                />
                {language}
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
    </div>
{/* nested col end */}

{/* nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
<label for="place" className="form-label">State <span style={{ color: 'red' }}>*</span></label>
<input type="text" className="form-control" placeholder="Delhi" name="state" onChange={joinAstrologerChangeData} id="place" required/>                        
</div>
{/* nested col end */}

{/* nested col start */}
<div className="col-lg-12 col-md-12 col-sm-12 col-12">
    <label htmlFor="photo" className="form-label">Photograph (Optional)</label>
    <input
        type="file"
        className="form-control"
        name="photo"
        onChange={joinAstrologerChangeData} // Updated event handler
        id="photo"
    />
</div>

{/* nested col end */}

{/* nested col start */}
<div className="col-lg-12 col-md-12 col-sm-12 col-12">
    <label htmlFor="resume" className="form-label">Resume (Optional)</label>
    <input
        type="file"
        className="form-control"
        name="resume"
        onChange={joinAstrologerChangeData} // Updated event handler
        id="resume"
    />
</div>

{/* nested col end */}

<div className="col-12">
<div className="joinastrologer_btn">
<button type="submit"  >Join Now !</button>
</div>
</div>

</div>
{/* nested row end */}
</form>
</div>
</div>

{/* col end */}

{/* col start */}
<div className="col-lg-12 col-md-12 col-sm-12 col-12 order-lg-3 order-md-3 order-sm-3 order-3">
<div className="joinastrologer_txt_div">
<ol start="5">
<li>
  <strong>Professional Development  </strong>
  <p><b>Continuous Learning:</b> Access a variety of resources, including workshops and webinars, to stay updated with the latest trends and knowledge in astrology and other fields , helping you refine your skills and expertise.  </p>
  <p><b>Networking Opportunities:</b> Connect with other professional Astrologer and experts in the field, fostering opportunities for collaboration and knowledge exchange.  </p>
</li>

<li>
  <strong> Increased Visibility and Credibility  </strong>
  <p><b>Platform Reputation:</b> Joining talkndheal enhances your credibility, as it is a well-established platform with a strong reputation for quality and trustworthiness in the online astrology community.  </p>
  <p><b>Marketing Support:</b> Benefit from the platform’s marketing efforts, which help you reach a larger audience without the need for extensive self-promotion.  </p>
</li>
<li>
  <strong> Client Management Made Easy  </strong>
  <p><b>Efficient Scheduling:</b> The platform’s scheduling tools make managing appointments straightforward, reducing administrative burdens and allowing you to focus on providing the best possible service.  </p>
  <p>Secure Payments: Enjoy hassle-free payment processing with a secure and reliable system that ensures timely compensation for your services.  </p>
</li>

<li>
  <strong> Positive Impact on Society  </strong>
  <p><b>Mental Well-being:</b> Your work contributes to the mental and emotional well-being of clients, offering them support and guidance during challenging times.  </p>
  <p>Cultural Enrichment: Astrology offers a unique perspective that enriches people’s understanding of themselves and the world around them, fostering a deeper connection to cultural and spiritual traditions.  </p>
</li>

<li>
  <strong> Community Engagement and Support  </strong>
  <p><b>Peer Support:</b> Engage with a community of like-minded professionals who share your passion for astrology & other expert services, offering a supportive environment where you can share experiences and seek advice.  </p>
  <p><b>Client Interaction:</b> Build meaningful relationships with clients, offering ongoing support and guidance, and making a lasting positive impact on their lives.  </p>
</li>

<li>
  <strong>Flexible Work Environment  </strong>
  <p><b>Remote Work:</b> Work from the comfort of your home or any location of your choice, eliminating the need for commuting and allowing you to create a workspace that suits your preferences.  </p>
  <p><b>Diverse Work Settings:</b> Whether you prefer working in a quiet home office or while traveling, the flexibility of remote work accommodates various lifestyles and preferences.  </p>
  <p>When seeking to join talkndheal as an astrologer/expert it is essential to meet specific criteria and possess certain qualities that align with the platform’s ethos and mission. Here’s a detailed outline of the requirements and expectations for astrologer/expert on this platform:
  </p>
</li>

</ol>

<ol>
  <li>
    <strong>Qualified & Experienced astrologer/expert    </strong>
    <p><b>Professional Certification:</b> Possess relevant qualifications or certifications or experience in astrology or other expert services, demonstrating a solid understanding of related field principles and practices.    </p>
    <p><b>Proven Experience:</b> Have a substantial track record of providing consultations, with experience in interpreting natal charts, forecasting, tarot reading and advising clients etc  related to respective expertise on various aspects of life.    </p>
    <p><b>Continual Learning:</b> Stay updated with the latest developments and trends, showing a commitment to continuous professional development.    </p>
  </li>

  <li>
    <strong>Helping Attitude    </strong>
    <p><b>Empathy and Compassion:</b> Show a genuine desire to help clients navigate their life challenges, offering support and understanding in a non-judgmental manner.    </p>
    <p>Client-Centered Approach: Prioritize the needs and concerns of clients, focusing on providing meaningful and impactful guidance that aligns with their personal growth and well-being.    </p>
  </li>

  <li>
    <strong> Good Communication Skills    </strong>
    <p><b>Clarity and Articulation:</b> Communicate complex concepts in a clear, concise, and understandable manner, ensuring clients can easily grasp the insights and advice provided.    </p>
    <p>Active Listening: Listen attentively to clients, understanding their questions and concerns, and responding thoughtfully to ensure effective communication.    </p>
  </li>

  <li>
    <strong>Patience    </strong>
    <p><b>Calm Demeanour:</b> Exhibit patience in dealing with clients, especially when explaining intricate astrological details or addressing repeated questions and concerns.    </p>
    <p>Supportive Guidance: Provide ongoing support, allowing clients the time they need to process and understand the information shared.    </p>
  </li>
  <li>
    <strong>Client Connection    </strong>
    <p><b>Building Rapport:</b> Establish a strong, trust-based relationship with clients, making them feel comfortable and valued during consultations.    </p>
    <p><b>Personalized Service:</b> Tailor readings and advice to each client's unique needs and circumstances, enhancing their overall experience and satisfaction.    </p>

  </li>

  <li>
    <strong> Pleasing Personality    </strong>
    <p><b>Positive Attitude:</b> Maintain a friendly and approachable demeanour, creating a welcoming atmosphere that encourages clients to open up and engage.    </p>
    <p><b>Professional Appearance:</b> Present yourself in a professional manner that reflects the high standards of the talkndheal platform.    </p>
  </li>
  <li>
    <strong>Ethical Practices    </strong>
    <p><b>Integrity:</b> Adhere to high ethical standards, ensuring honesty and transparency in all interactions with clients.    </p>
    <p><b>Confidentiality:</b> Respect client privacy by maintaining strict confidentiality regarding personal information and consultation details.    </p>
    <p><b>Ethical Consultations:</b> Conduct consultations ethically, avoiding any practices that could be considered manipulative or exploitative.    </p>
    <p><b>Responsible Advice:</b> Provide advice that is in the best interest of the client, avoiding any recommendations that could cause harm or undue stress.    </p>
  </li>

  <li>
    <strong>Imbibing Company Culture    </strong>
    <p><b>Alignment with Vision and Mission:</b> Embrace and reflect the vision and mission of talkndheal in his/her practice, focusing on enhancing the personal growth and well-being of clients.    </p>
    <p><b>Cultural Fit:</b> Integrate seamlessly into the talkndheal community, upholding the values and ethos that the platform represents.    </p>
    <p><b>Dedication to Client Growth:</b> Commit to helping clients achieve personal growth and clarity through ast, aligning with the platform's goal of fostering a supportive and transformative environment.    </p>
    <p><b>Contribution to Community:</b> Actively contribute to the talkndheal community, sharing knowledge and supporting peers in a collaborative and constructive manner.    </p>
  </li>
</ol>
<h3>Additional Considerations</h3>
<p><b>Technological Proficiency:</b> Be comfortable with the platform’s technology and tools, ensuring a smooth and professional online consultation experience, for better understanding an elaborate training will be provided.</p>
<p><b>Adaptability:</b> Be open to learning and adapting to new methods, technologies, and client needs to continually improve the quality of services provided.</p>
<p><b>Feedback Receptiveness:</b> Welcome feedback from clients and peers, using it as a tool for personal and professional growth.</p>
<p>By embodying these qualities and meeting these requirements, you will not only align with talkndheal’s standards but also contribute meaningfully to the platform’s mission of guiding individuals toward greater self-understanding and personal growth.</p>
</div>
</div>
{/* col end */}
</div>
{/* row end */}
</div>
</section>
{/* join astrologer section end */}
 
</div>
)
}

export default JoinAsastrologer
