import React, { useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MyContext from "../../Context/MyContext";

import { cookieDomain, decryptData, encryptData, setEncryptedCookie } from "../Url/Url";
import { loginResendOtpWithPhoneNumberApi, loginWithPhoneNumberApi, otpVerifiedApi } from "../../AllApi/AllApi";
import { Link } from "react-router-dom";
const Login = (props) => {
    const [loginData, setLoginData] = useState({
      countryCode: "+91", // Default value set here
      // other fields
    });
    const [otpPageSeen, setOtpPageSeen] = useState(false);
    const [otpData, setOtpData] = useState({});
    const [otpSentMessage, setOtpSentMessage] = useState("");
  

function loginChange(e) {
    setLoginData({
        ...loginData,
        [e.target.name]: e.target.value
      });
}

async function otpSubmit(event) {
    event.preventDefault();

    const dataSend = {
        loginData,
        otpData,

    };

    try {
        const res = await otpVerifiedApi(dataSend);

        if (res.success) {
          
            const tokenData = res.token;
            const userId = res.uniqueId; // Assuming userId is part of the API response
                        // Set token cookie
            setEncryptedCookie('token', tokenData);

            // Set userId cookie
            setEncryptedCookie('user', userId);

            // Close modal
            if (props?.closeModal) {
                // props.closeModal();
                window.location.reload();
            }

            // Show success message
            toast.success("Login successfully!");

            // Redirect with a delay
            // setTimeout(() => {
            //     window.location.reload();
            // }, 5000);
        } else {
            toast.error("Incorrect OTP!", {
                autoClose: 4000,
            });
        }
    } catch (error) {
        // Handle API call errors
        toast.error("An error occurred. Please try again.", {
            autoClose: 4000,
        });
    }
}


function toggleCloseLoginModal() {
props?.closeModal();
// window.location.reload();
}
const [disabled, setDisabled] = useState(false);
async function loginSubmit(event) {
  event.preventDefault();

  // Validate mobile number
  const mobileNumber = loginData.mobile;
  const countryCode = loginData.countryCode;

  if (!mobileNumber || !/^\d{10}$/.test(mobileNumber)) {
    toast.error("Please enter a valid 10-digit mobile number.", {
      autoClose: 4000,
    });
    return;
  }

  // Disable the button to prevent multiple submissions and start the timer
  setDisabled(true);
  setTimer(30); // Set the timer for 10 seconds

  try {
    // Show OTP input fields immediately
    setOtpPageSeen(true);
    setOtpSentMessage(`OTP sent to ${countryCode} ${mobileNumber}`);
    toast.success(`OTP sent to ${countryCode} ${mobileNumber}`, {
      autoClose: 4000,
    });

    // Call the API to resend OTP
    const res = await loginWithPhoneNumberApi(loginData);
    if (!res.success) {
      // Handle OTP send failure
      setOtpPageSeen(false);
      toast.error("Failed to send OTP. Please try again.");
    }
  } catch (error) {
    // Handle any errors during the OTP request
    setOtpPageSeen(false);
    toast.error("An error occurred. Please try again.");
  }
}

const [timer, setTimer] = useState(0); // Timer state

useEffect(() => {
  let interval;
  if (disabled && timer > 0) {
    // Set up a countdown interval when the button is disabled and timer is greater than 0
    interval = setInterval(() => {
      setTimer(prev => (prev > 0 ? prev - 1 : 0));
    }, 1000); // Countdown every second
  } else if (timer === 0 && disabled) {
    // Re-enable the button once the timer reaches 0
    setDisabled(false);
  }

  // Clean up the interval when the component is unmounted or timer is 0
  return () => clearInterval(interval);
}, [disabled, timer]);

async function reSendOtpSubmit(event) {
  event.preventDefault();

  // Validate mobile number
  const mobileNumber = loginData.mobile;
  const countryCode = loginData.countryCode;

  if (!mobileNumber || !/^\d{10}$/.test(mobileNumber)) {
    toast.error("Please enter a valid 10-digit mobile number.", {
      autoClose: 4000,
    });
    return;
  }

  // Disable the button to prevent multiple submissions and start the timer
  setDisabled(true);
  setTimer(30); // Set the timer for 10 seconds

  try {
    // Show OTP input fields immediately
    setOtpPageSeen(true);
    setOtpSentMessage(`OTP sent to ${countryCode} ${mobileNumber}`);
    toast.success(`OTP sent to ${countryCode} ${mobileNumber}`, {
      autoClose: 4000,
    });

    // Call the API to resend OTP
    const res = await loginResendOtpWithPhoneNumberApi(loginData);
    if (!res.success) {
      // Handle OTP send failure
      setOtpPageSeen(false);
      toast.error("Failed to send OTP. Please try again.");
    }
  } catch (error) {
    // Handle any errors during the OTP request
    setOtpPageSeen(false);
    toast.error("An error occurred. Please try again.");
  }
}


const countryCodes = [
{ code: "+91", name: "India" },
{ code: "+1", name: "USA" },
{ code: "+44", name: "UK" },
{ code: "+61", name: "Australia" },
{ code: "+81", name: "Japan" },
{ code: "+86", name: "China" },
{ code: "+49", name: "Germany" },
{ code: "+33", name: "France" },
{ code: "+39", name: "Italy" },
{ code: "+7", name: "Russia" },
{ code: "+20", name: "Egypt" },
{ code: "+34", name: "Spain" },
{ code: "+55", name: "Brazil" },
{ code: "+61", name: "Australia" },
{ code: "+66", name: "Thailand" },
{ code: "+27", name: "South Africa" },
{ code: "+52", name: "Mexico" },
{ code: "+47", name: "Norway" },
{ code: "+46", name: "Sweden" },
{ code: "+41", name: "Switzerland" },
{ code: "+31", name: "Netherlands" },
{ code: "+32", name: "Belgium" },
{ code: "+43", name: "Austria" },
{ code: "+48", name: "Poland" },
{ code: "+30", name: "Greece" },
{ code: "+90", name: "Turkey" },
{ code: "+351", name: "Portugal" },
{ code: "+353", name: "Ireland" },
{ code: "+358", name: "Finland" },
{ code: "+36", name: "Hungary" },
{ code: "+420", name: "Czech Republic" },
{ code: "+421", name: "Slovakia" },
{ code: "+386", name: "Slovenia" },
{ code: "+372", name: "Estonia" },
{ code: "+371", name: "Latvia" },
{ code: "+370", name: "Lithuania" },
{ code: "+375", name: "Belarus" },
{ code: "+380", name: "Ukraine" },
{ code: "+40", name: "Romania" },
{ code: "+359", name: "Bulgaria" },
{ code: "+385", name: "Croatia" },
{ code: "+381", name: "Serbia" },
{ code: "+387", name: "Bosnia" },
{ code: "+382", name: "Montenegro" },
{ code: "+383", name: "Kosovo" },
{ code: "+355", name: "Albania" },
{ code: "+389", name: "Macedonia" },
{ code: "+236", name: "Africa" },
{ code: "+243", name: "Congo" },
{ code: "+232", name: "Sierra Leone" },
{ code: "+221", name: "Senegal" },
{ code: "+254", name: "Kenya" },
{ code: "+255", name: "Tanzania" },
{ code: "+256", name: "Uganda" },
{ code: "+260", name: "Zambia" },
{ code: "+263", name: "Zimbabwe" },
{ code: "+234", name: "Nigeria" },
{ code: "+233", name: "Ghana" },
{ code: "+237", name: "Cameroon" },
{ code: "+250", name: "Rwanda" },
{ code: "+212", name: "Morocco" },
{ code: "+216", name: "Tunisia" },
{ code: "+213", name: "Algeria" },
{ code: "+218", name: "Libya" },
{ code: "+251", name: "Ethiopia" },
{ code: "+253", name: "Djibouti" },
{ code: "+252", name: "Somalia" },
{ code: "+260", name: "Zambia" },
{ code: "+266", name: "Lesotho" },
{ code: "+267", name: "Botswana" },
{ code: "+263", name: "Zimbabwe" },
{ code: "+62", name: "Indonesia" },
{ code: "+63", name: "Philippines" },
{ code: "+64", name: "New Zealand" },
{ code: "+65", name: "Singapore" },
{ code: "+673", name: "Brunei" },
{ code: "+60", name: "Malaysia" },
{ code: "+95", name: "Myanmar" },
{ code: "+855", name: "Cambodia" },
{ code: "+856", name: "Laos" },
{ code: "+84", name: "Vietnam" },
{ code: "+92", name: "Pakistan" },
{ code: "+93", name: "Afghanistan" },
{ code: "+94", name: "Sri Lanka" },
{ code: "+880", name: "Bangladesh" },
{ code: "+977", name: "Nepal" },
{ code: "+975", name: "Bhutan" },
{ code: "+960", name: "Maldives" },
{ code: "+976", name: "Mongolia" },
{ code: "+998", name: "Uzbekistan" },
{ code: "+995", name: "Georgia" },
{ code: "+994", name: "Azerbaijan" },
{ code: "+374", name: "Armenia" },
{ code: "+961", name: "Lebanon" },
{ code: "+962", name: "Jordan" },
{ code: "+963", name: "Syria" },
{ code: "+964", name: "Iraq" },
{ code: "+965", name: "Kuwait" },
{ code: "+966", name: "Saudi Arabia" },
{ code: "+968", name: "Oman" },
{ code: "+971", name: "UAE" },
{ code: "+973", name: "Bahrain" },
{ code: "+974", name: "Qatar" },
{ code: "+970", name: "Palestine" }
];


return (
<>
<ToastContainer />
{/*  */}
<div className="loing_modal_maon_div">
<div className="modal fade show" id="login" tabIndex={-1} style={{ display: "block" }}>
<div className="modal-backdrop fade show"></div>

<div className="modal-dialog modal-dialog-centered">
<div className="modal-content">
<div className="modal-header">
<h5 className="modal-title"> <i className="fa fa-users " /> Login Here !</h5>
<button type="button" className="fa fa-close" onClick={toggleCloseLoginModal} />
</div>
<div className="modal-body">
{otpPageSeen ? (
<h3>Please Enter the received Verification Code below.</h3>
) : (
<h3>You Will Receive a Code for Verification</h3>
)}
 
<form method="POST">
<label htmlFor="username">Enter Phone No.</label>
<div className="d-flex align-items-center login_modal">
<select
  id="countryCode"
  name="countryCode"
  className="form-select"
  onChange={loginChange}
  value={loginData.countryCode || ""}
  required
>
  <option value="" disabled>Select Country Code</option>
  {countryCodes.map((country) => (
    <option key={country.code} value={country.code}>
      {country.name} ({country.code})
    </option>
  ))}
</select>
<input type="text" style={{ display: 'none' }} autoComplete="one-time-code" />

<input type="text" name="mobile" className="form-control" placeholder="Enter Phone No." maxLength={10} pattern="[1-9]{1}[0-9]{9}"
onChange={loginChange}/>
</div>
{!otpPageSeen ? (
<button type="submit" name="getOTP" onClick={loginSubmit}  className={`text-danger mt-2 ${disabled ? 'disabled bg-gray' : ''}`}
      disabled={disabled} >
Get OTP
</button>
) : (
<>
<label htmlFor="password">OTP</label>



<div className="login_modal">
  <input
    type="text"                 
    className="form-control"
    name="otp"
    onChange={loginChange}
    placeholder="Enter OTP"
    pattern="[0-9]{5}"           
    maxLength={5}
    autoComplete="one-time-code" 
    inputMode="numeric"          
    required
  />
</div>




<div className="d-flex gap-2">
<button type="submit" name="verifyOTP" onClick={otpSubmit} className="text-danger mt-2"> Verify </button>
<button
      type="submit"
      name="getOTP"
      onClick={reSendOtpSubmit}
      className={`text-danger mt-2 ${disabled ? 'disabled bg-light text-black' : ''}`}
      disabled={disabled}
      style={{
        transition: 'opacity 0.3s ease', 
        opacity: disabled ? 0.6 : 1, 
        pointerEvents: disabled ? 'none' : 'auto',
      }}
    >
      {disabled ? `Resend OTP (${timer})` : 'Resend OTP'}
    </button>
</div>
</>
)}
</form>
<p className="pt-1 m-0">
<span className="pe-1">By Signing up, you agree to our</span>
<Link to="/terms-and-conditions"> Terms & Conditions </Link><span className="px-1">and</span> <Link to="/privacy-policy">Privacy Policy</Link></p>
</div>
</div>
</div>
</div>
</div>
{/*  */}
</>
);
};

export default Login;
