import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
const ChaughadiyaComponent = () => {
  // Static data
  const chaughadiyaData = {
    day: [
      { time: '07:22:23 - 09:04:17', muhurta: 'Morning' },
      { time: '09:04:18 - 10:46:12', muhurta: 'Mid-Morning' },
      { time: '10:46:13 - 12:28:07', muhurta: 'Noon' },
      { time: '12:28:08 - 14:10:02', muhurta: 'Afternoon' },
      { time: '14:10:03 - 15:51:57', muhurta: 'Late Afternoon' },
      { time: '15:51:58 - 17:33:52', muhurta: 'Evening' },
      { time: '17:33:53 - 19:15:47', muhurta: 'Night' },
      { time: '19:15:48 - 20:57:42', muhurta: 'Late Night' }
    ],
    night: [
      { time: '00:00:00 - 01:41:54', muhurta: 'Midnight' },
      { time: '01:41:55 - 03:23:48', muhurta: 'Late Night' },
      { time: '03:23:49 - 05:05:42', muhurta: 'Early Morning' },
      { time: '05:05:43 - 06:47:36', muhurta: 'Dawn' },
      { time: '06:47:37 - 08:29:30', muhurta: 'Morning' },
      { time: '08:29:31 - 10:11:24', muhurta: 'Mid-Morning' },
      { time: '10:11:25 - 11:53:18', muhurta: 'Noon' },
      { time: '11:53:19 - 13:35:12', muhurta: 'Afternoon' }
    ]
  };

  // Get current time
  const now = new Date();
  const currentTime = now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();

  // Convert time string to seconds since midnight
  const timeStringToSeconds = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  // Filter data based on current time
  const filterByTime = (timings) => {
    return timings.filter(item => {
      const [startTime, endTime] = item.time.split(' - ');
      const startSeconds = timeStringToSeconds(startTime);
      const endSeconds = timeStringToSeconds(endTime);
      return currentTime >= startSeconds && currentTime <= endSeconds;
    });
  };

  const filteredData = {
    day: filterByTime(chaughadiyaData.day),
    night: filterByTime(chaughadiyaData.night)
  };

  // Format data into a paragraph
  const formatChaughadiya = (filteredData) => {
    const dayParagraph = filteredData.day.map(item => `${item.time}: ${item.muhurta}`).join(', ');
    const nightParagraph = filteredData.night.map(item => `${item.time}: ${item.muhurta}`).join(', ');

    return `Day Timings: ${dayParagraph}. Night Timings: ${nightParagraph}.`;
  };

  const formattedData = formatChaughadiya(filteredData);

  return (
    <div>
     {/* ========= */}
   
    {/* ====== */}
      <p>{formattedData}</p>
     
    </div>
  );
};

export default ChaughadiyaComponent;
