import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../CssFiles/Ticketdetails.css";
import { FetchSingleTicket } from "../AllApi/AllApi";

const Ticketdetails = () => {
  const { ticketId } = useParams(); // Ensure ticketId is retrieved properly
 

  const SingleTicket = async () => {
    try {
      const res = await FetchSingleTicket(ticketId);
      
    } catch (error) {
      
    }
  };

  useEffect(() => {
    if (ticketId) {
      SingleTicket();
    }
  }, [ticketId]);

  return (
    <>
      {/* ========= */}
        
      {/* ====== */}
      {/* Ticket Details section start */}
      <section className="ticket_details_section">
        <div className="container">
          {/* row start */}
          <div className="row">
            {/* col start */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              {/*  */}
              <div className="ticket_deatls_flex_div">
                <h1>Ticket Details</h1>

                <div className="ticket_dt_link_flx">
                  <Link to="">My Tickets</Link>
                  <Link to="">Create Ticket</Link>
                </div>
              </div>
              {/*  */}

              {/*  */}
              <div className="status_div_main_div_flx">
                <div className="status_left_flx">
                  <p>
                    <b>
                      Status: <span> Answered</span>
                    </b>
                    <b>
                      Priority: <span>Normal</span>
                    </b>
                  </p>
                </div>
                <Link to="">
                  <i className="fa fa-reply" aria-hidden="true" /> Reply
                </Link>
              </div>
              {/*  */}

              {/*  */}
              <div className="ticket_dtrenewal_div">
                <h5>Please Provide Best Offer For Renewal</h5>
                <div className="main_div_renewal_flx_div">
                  <p>Created Date: 26 Jun 2024 12:17 PM</p>
                  <b>Last Update: 26 Jun 2024 12:18 PM</b>
                </div>
              </div>
              {/*  */}

              {/* card_ */}
              <div className="card_email_div">
                <p>
                  <a href>
                    <span className="fa fa-user" /> Jasmann Singh
                  </a>
                  <a href="mailto:jasmann013@gmail.com">
                    <span className="fa fa-envelope" /> jasmann013@gmail.com
                  </a>
                  <a href="tel:+91 9899894843">
                    <span className="fa fa-phone" /> +91 9899894843
                  </a>
                </p>
              </div>
              {/* card_ */}
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
      </section>
      {/* Ticket Details section end */}

      {/* chat box section start tckt  */}
      <section className="tckt_chat_box">
        <div className="container">
          {/* row start */}
          <div className="row">
            {/* col start */}
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="fixed_main_div">
                {/*  */}
                <div className="tckt_chat_box_main_div">
                  {/* tckt msg recevied start */}
                  <div className="tckt_msg_recevied_main_div">
                    <div className="receviedmsg_txt">
                      Hell, This side sneha yadav
                    </div>
                  </div>
                  {/* tckt msg recevied end */}

                  {/* tckt msg recevied start */}
                  <div className="tckt_msg_recevied_main_div">
                    <div className="receviedmsg_txt">
                      <img
                        src="../images/tlknd03.png"
                        alt="image"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  {/* tckt msg recevied end */}

                  {/* tckt msg send start */}
                  <div className="tckt_msg_send_main_div">
                    <div className="sendmsg_txt">Where do you live</div>
                  </div>
                  {/* tckt msg send end */}

                  {/* tckt msg send start */}
                  <div className="tckt_msg_send_main_div">
                    <div className="sendmsg_txt">
                      <img
                        src="../images/tlknd06.png"
                        alt="image"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  {/* tckt msg send end */}
                </div>

                {/* chat footer input */}
                <div className="chat-footer">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type a message"
                  />
                  <button type="submit">Send</button>
                </div>
                {/* chat footer input */}
              </div>
            </div>
            {/* col end */}
          </div>
          {/* row end */}
        </div>
      </section>
      {/* chat box section end tckt  */}
        
      {/*  */}
    </>
  );
};

export default Ticketdetails;
