import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../CssFiles/Ticket.css";
import { fetchTicketList, ReOpenSingleTicket } from "../AllApi/AllApi"; // Import your fetch function
import { parseISO, format } from "date-fns";
import toast, { Toaster } from "react-hot-toast";

import {
  decryptData,
  Domain_Url,
  isAuthenticated,
  isUserId,
} from "../components/Url/Url";

const Ticket = () => {
  const token = isAuthenticated; // Use `isAuthenticated` as a value
  const userId = isUserId; // Fetch the user ID
  const navigate = useNavigate();
  // Redirect if token or userId is null
  useEffect(() => {
    if (token === null || userId === null) {
      navigate("/");
    }
  }, [token, userId, navigate]);

  const [tickets, setTickets] = useState([]);
  const [activeTab, setActiveTab] = useState("all"); // Track active tab
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const filterMap = {
    all: "", // Fetch all tickets when the 'All' tab is active
    open: -1, // Use -1 for open tickets
    answered: 2, // Use 2 for answered tickets
    closed: 3, // Use 3 for closed tickets
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetchTicketList(
          "1234567890",
          filterMap[activeTab]
        );
        if (response.error) {
          setError(response.error);
        } else {
          setTickets(response.data || []); // Assuming the tickets are in the response.data array
        }
      } catch (err) {
        setError("Failed to load tickets");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [activeTab]); // Refetch data when tab changes

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // const formatDate = (dateString) => {
  //   try {
  //     const parsedDate = parseISO(dateString);
  //     return format(parsedDate, "yyyy-MM-dd HH:mm:ss");
  //   } catch (error) {
  //     return "Invalid Date";
  //   }
  // };

  const getStatusLabel = (status) => {
    if (status === "1") return { label: "Open", color: "orange" };
    if (status === "2") return { label: "Answered", color: "green" };
    if (status === "3") return { label: "Resolved", color: "blue" };
    // if (status === "2" || status === "3") return { label: 'Resolved', color: 'green' };
    return { label: "Unknown", color: "grey" };
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "Invalid Date"; // Check for invalid date

    // Format date as '18 September 2024'
    const day = date.getDate();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Format time as '2:00pm'
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12; // Convert to 12-hour format

    return `${day} ${month} ${year}, ${hours}:${minutes}${ampm}`;
  };

  const HandleFetchSingleTicket = (data) => {
    navigate(`/ticketdetails/${data?.ticketid}`);
  };

  const HandleReOpenSingleTicket = async (data) => {
   

    const res = await ReOpenSingleTicket(data?.ticketid);
   

    if (res.success === true) {
      toast.success("Ticket created successfully!");
    } else if (res.success === false && res.message) {
      toast.error(res.message); // Show the error message from the API
    } else {
      throw new Error(res?.error || "Something went wrong.");
    }
  };

  return (
    <>
      <Toaster />

        
      <section className="ticket_section">
        <div className="container">
          <div className="ticket_title_div">
            <h2>My Tickets</h2>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="ticket_tab-main_div">
                {/* Nav tabs */}
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        activeTab === "all" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("all")}
                    >
                      All
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        activeTab === "open" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("open")}
                    >
                      Open
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        activeTab === "answered" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("answered")}
                    >
                      Answered
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        activeTab === "closed" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("closed")}
                    >
                      Closed
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link to="/generateticket" className="nav-link">
                      Create Ticket
                    </Link>
                  </li>
                </ul>
                {/* Tab content */}
                <div className="tab-content" id="myTabContent">
                  {loading ? (
                    <div>Loading...</div>
                  ) : error ? (
                    <div>{error}</div>
                  ) : (
                    <div className="table_div_tabs">
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Subject</th>
                            <th>Create On</th>
                            {/* <th>Updated</th> */}
                            <th>Status</th>
                            <th>Re-Open</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tickets.length > 0 ? (
                            tickets.map((ticket, index) => {
                              const { label, color } = getStatusLabel(
                                ticket.status
                              );
                              return (
                                <tr key={ticket.id}>
                                  <td>{index + 1}</td>
                                  {/* <td onClick={HandleFetchSingleTicket()>{ticket.subject}</td> */}
                                  <td
                                    onClick={() =>
                                      HandleFetchSingleTicket(ticket)
                                    }
                                  >
                                    {ticket.subject}
                                  </td>
                                  <td>{formatDate(ticket.inserted)}</td>
                                  <td style={{ color }}>{label}</td>
                                  <td
                                    onClick={() =>
                                      HandleReOpenSingleTicket(ticket)
                                    }
                                  >
                                     <button>Re-Open</button>
                                    {/* {ticket.status === "3" && (
                                     
                                    )} */}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={5}>Data not found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        
    </>
  );
};

export default Ticket;
