import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { decryptData, isAuthenticated, isUserId } from './Url/Url';
import Cookies from 'js-cookie';
import Login from './Login/Login';

const Sidenavbar = ({userData}) => {
 
  const [showLoginModal, setShowLoginModal] = useState();
  function toggleLoginModal() {
    setShowLoginModal(!showLoginModal);
  }
const formatAmount = (amount) => {
  return new Intl.NumberFormat("en-IN").format(amount);
  };



// 
const menuRef = useRef(null);

const handleLinkClick = (event) => {
  const clickedLink = event.target;

  // Ensure the clicked link scrolls into view
  clickedLink.scrollIntoView({
    behavior: 'auto',
    block: 'center',
    inline: 'center'
  });
};


function  handleLogOut(){
  Cookies?.remove('token')
  window.location?.reload()
}

const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
const [OpenOffcanvas,  setOpenOffcanvs] =  useState(false);

function toggleOpenLoginModal() {
  setIsOpenLoginModal(!isOpenLoginModal);
  }

  const handleSignInClick = () => {
    setOpenOffcanvs(!OpenOffcanvas);
    
  }

  return (
    <>
{isOpenLoginModal && <Login closeModal={toggleOpenLoginModal} />}

    {/*============= mobile menu================= */}
<div className="main_div_sidebar">
<div className={`offcanvas offcanvas-start ${OpenOffcanvas ? "" : "hide"}`} tabIndex={-1} id="offcanvasWithBothOptions" data-bs-scroll="true" data-bs-backdrop="false">
    <div className="offcanvas-header"> 
    {!isAuthenticated?
      <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel" onClick={handleSignInClick}><a href="javascript:void(0);" onClick={toggleOpenLoginModal}>
      <img src="../images/a1.png" alt="login" /> SIGN IN/SIGN UP <i className="fa  fa-sign-in" /></a></h5>
      :<h5 className="offcanvas-title"><a href="#" >
        
        
        <img src={`${userData?.data?.profile}`} alt={userData?.data?.name} /> {userData?.data?.name}</a> <a href='/editprofile' className='fa fa-pencil-square-o'></a> </h5>}
      <button type="button" className="fa fa-times" data-bs-dismiss="offcanvas" aria-label="Close" />
    </div>
    <div className="offcanvas-body offcanvas_scroll">
      {/* Example single danger button */}
      <div className="mobile_navbar">
        <ul className="main_ul">
          {/* Horoscopes li */}
          <li><a href="/"> <img src="../images/Home.svg" alt="home" /> Home </a> <span data-bs-toggle="collapse" data-bs-target="#products-menu2" className="fa fa-angle-down" /></li>
          <div className="collapse" id="products-menu2">
            <div className="card card-body profile_card_body">
              <ul className="profile_ul">    
             <li> <a href="/services/astrology">Astrology</a></li>
<li><a href="/services/tarot">Tarot</a></li>
<li><a href="/services/psychologist">Psychologist</a></li>
<li><a href="/services/kp-system">KP System</a></li>
<li><a href="/services/lal-Kitab">Lal Kitab</a></li>
<li><a href="/services/palmistry">Palmistry</a></li>
<li><a href="/services/vastu">Vastu</a></li>
{/* <li><a href="/services/dietician">Dietician</a></li> */}
<li><a href="/services/pranayama">Pranayama</a></li>
<li><a href="/services/yoga-mudras">Yoga mudras</a></li>
<li><a href="/services/sun-rays-healing">Sun Rays Healing</a></li>
{/* <li><a href="/services/sound-and-words-healing">Sound and Words Healing</a></li> */}
<li><a href="/services/crystal-healing">Crystal Healing</a></li>
<li><a href="/services/acupressure-healing">Acupressure Healing</a></li>
<li><a href="/services/magnet-healing">Magnet Healing</a></li>
{/* <li><a href="/services/nature-healing">Nature Healing</a></li> */}
{/* <li><a href="/services/metal-healing">Metal Healing</a></li> */}
              </ul>
            </div>
          </div>
          {/*Horoscopes drop li end */}
          <li><a href="/free-kundli-online"> <img src="../images/Kundli.svg" alt="kundli" /> Kundli</a></li>
          <li><a href="/kundli-matching"> <img src="../images/lovely.svg" alt="Kundli Matching" /> Kundli Matching</a></li>
          {/* Horoscopes li */}
          <li><a href="javascript:void(0);"><img src="../images/Home.svg" alt="Horoscopes"/>  Horoscopes</a> <span data-bs-toggle="collapse" data-bs-target="#products-menu" className="fa fa-angle-down" /></li>
          <div className="collapse" id="products-menu">
            <div className="card card-body profile_card_body">
              <ul className="profile_ul">
                <li><a href="/horoscope/today-horoscope">Today's Horoscope</a></li>
                <li><a href="/horoscope/tomorrow-horoscope">Tomorrow's Horoscope</a></li>
                <li><a href="/horoscope/monthly-horoscope">Monthly Horoscope</a></li>
              </ul>
            </div>
          </div>
          {/*Horoscopes drop li end */}
         
          <li><a href="/panchang"> <img src="../images/moon.svg" alt="Panchang" /> Panchang</a></li>
          {/* <li><a href="/Samuhikpuja"> <img src="../images/Pooja.svg" alt="Samuhik Puja" /> Samuhik Puja</a></li> */}
          {/*<li><a href="blogs"> <img src="../images/Newspaper.png" alt=""> Current Affairs</a></li>*/}
          <li><a href="/Join-as-astrologer"> <img src="../images/Group76425.svg" alt="Join As An Astrologer" /> Join As An Astrologer</a></li>
          {/*  */}
          {/* {!isAuthenticated? (  <li ><a href="" onClick={toggleLoginModal}> <i className='bx bx-download text-dark f-2'></i> Download Kundli</a></li>): (<li ><a href=""> <i className='bx bx-download text-dark f-2'></i> Download Kundli</a></li>)} */}
          {/*  */}
          <li><a href="javascript:void(0);"> <img src="../images/noti.png" alt="Policies" /> Policies</a>
            <span data-bs-toggle="collapse" data-bs-target="#policies-menu" className="fa fa-angle-down" /></li>
          <div className="collapse" id="policies-menu">
            <div className="card card-body profile_card_body">
              <ul className="profile_ul">
              <li><a href="/about-us">About us</a></li>
                <li><a href="/disclaimer">Disclaimer</a></li>
                <li><a href="/terms-and-conditions">Terms &amp; Conditions</a></li>
                <li><a href="/refund-and-cancellation-policy">Refund &amp; Cancellation</a></li>
                <li><a href="/privacy-policy">Privacy Policy</a></li>
                {/* <li><a href="/pricingpolicy">Pricing Policy</a></li> */}
              </ul>
            </div>
          </div>

          {!isAuthenticated? (""): (
          <>
         <li><a href="/payment"><i className='bx bx-wallet-alt text-dark f-2'></i> Wallet Transactions</a></li>
         {/* <li><a href="/orderhistory">Order History</a></li> */}
         <li><a href="/ticket"><i className='bx bx-support text-dark f-2'></i> Customer Support</a></li>
        {/* <li><a href="/blockedastro">Reported Astrologers</a></li>  */}
           <li><a href="" onClick={handleLogOut}> <i className='fa fa-sign-out text-dark f-2' ></i> Sign Out</a></li>
           </>
           )}
         
         
        </ul>
        <ul className="left_icons_so_mobile">
          <li><a target="_blank" href=""><img src="../images/android.png" alt="Android App" /></a></li>
          <li><a target="_blank" href=""><img src="../images/apple.png" alt="iOS App" /></a></li>
          <li><a target="_blank" href="https://www.facebook.com/talkndhealofficial/"><img src="../images/fb.png" alt="Facebook" /></a></li>
          <li><a target="_blank" href="https://www.instagram.com/talkndhealdotcom/"><img src="../images/insta.png" alt="Instagram" /></a></li>
          <li><a target="_blank" href="https://x.com/TalkndHeal"><img src="../images/twitter.png" alt="Twitter" /></a></li>
          <li><a target="_blank" href="https://in.linkedin.com/company/talkndheal"><img src="../images/linkedin.png" alt="LinkedIn" /></a></li>
          <li><a target="_blank" href="https://www.youtube.com/@TalkndHeal"><img src="../images/yt.png" alt="Youtube" /></a></li>
        </ul>
      </div>
    </div>
  </div>
  {/*============= mobile menu end================= */}
  {/* mobile healing navbar section */}
  <div className="main_healing_nav_div">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          {/*  */}
          <div className="mobile_healing_nav menu-wrapper" ref={menuRef}>
      <div className="menu-item">
      <NavLink to="/services/astrology" onClick={handleLinkClick} activeClassName="active">Astrology</NavLink>
        <NavLink to="/services/tarot" onClick={handleLinkClick} activeClassName="active">Tarot</NavLink>
        <NavLink to="/services/psychologist" onClick={handleLinkClick} activeClassName="active">Psychologist</NavLink>
        <NavLink to="/services/kp-system" onClick={handleLinkClick} activeClassName="active">KP System</NavLink>
        <NavLink to="/services/lal-Kitab" onClick={handleLinkClick} activeClassName="active">Lal Kitab</NavLink>
        <NavLink to="/services/palmistry" onClick={handleLinkClick} activeClassName="active">Palmistry</NavLink>
        <NavLink to="/services/vastu" onClick={handleLinkClick} activeClassName="active">Vastu</NavLink>
        {/* <Link to="/services/dietician" onClick={handleLinkClick} activeClassName="active">Dietician</Link> */}
        <NavLink to="/services/pranayama" onClick={handleLinkClick} activeClassName="active">Pranayama</NavLink>
        <NavLink to="/services/yoga-mudras" onClick={handleLinkClick} activeClassName="active">Yoga Mudras</NavLink>
        <NavLink to="/services/sun-rays-healing" onClick={handleLinkClick} activeClassName="active">Sun Rays Healing</NavLink>
        <NavLink to="/services/sound-and-words-healing" onClick={handleLinkClick} activeClassName="active">Sound and Words Healing</NavLink>
        <NavLink to="/services/crystal-healing" onClick={handleLinkClick} activeClassName="active">Crystal Healing</NavLink>
        <NavLink to="/services/acupressure-healing" onClick={handleLinkClick} activeClassName="active">Acupressure Healing</NavLink>
        <NavLink to="/services/magnet-healing" onClick={handleLinkClick} activeClassName="active">Magnet Healing</NavLink>
        <NavLink to="/services/nature-healing" onClick={handleLinkClick} activeClassName="active">Nature Healing</NavLink>
        {/* <Link to="/services/metal-healing" onClick={handleLinkClick}>Metal Healing</Link> */}
      </div>
    </div>
          {/*  */}
        </div>
      </div>
    </div>
  </div>
  {/* end========= */}
</div>
{/*  */}


{/*===========mobile footer fixed call chat icons ============== */}
<div className="main_div_footer_fxd_chat">
  <div className="main_div_flx_div_footer">
    {/* home icon */}
    <div className="home_icon_div">
      <a href="/">
        <span><img src="../../images/homenormal.svg" alt="home" /></span>
        <b>Home</b>
      </a>
    </div>
    {/* home icon end*/}
    {/* 4 div icon */}
    <div className="btn_type_icon_div">
      <a href="javascript:void(0);">
        <span> <img src="../../images/chatnormal.svg" alt="chat" /></span>
        <b>Chat</b>
      </a>
      <a href="javascript:void(0);">
        <span><img src="../../images/callnormal.svg" alt="call" /></span>
        <b>Call</b>
      </a>
      <a href="javascript:void(0);">
        <span><img src="../../images/vcallnormal.svg" alt="video" /></span>
        <b>Video</b>
      </a>

      <a href="javascript:void(0);">
        <span><img src="../../images/Live.svg" alt="live" /></span>
        <b>Live</b>
      </a>
    </div>
    {/* 4 div icon end */}
   
   {/* profile icon */}
   {!isAuthenticated? <div className="home_icon_div" onClick={handleSignInClick}>
      <a href="javascript:void(0);" onClick={toggleOpenLoginModal}>
        <span><img src="../../images/profilenormal.svg" alt="profile" /></span>
        <b>Profile</b>
      </a>
    </div>: <div className="home_icon_div">
      <a href="/userprofile">
        <span><img src="../../images/profilenormal.svg" alt="profile" /></span>
        <b>Profile</b>
      </a>
    </div>}
   
    {/* profile icon end*/}
  </div>

     {/*  */}
<div className='overlay_comming_txt'>
  <p>Coming Soon</p>
</div>
      {/*  */}
</div>
{/*=========== mobile footer fixed call chat icons ============== */}


    </>
  );
};

export default Sidenavbar;
