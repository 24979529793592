import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../CssFiles/Generateticket.css";
import { generateTicketApi } from "../AllApi/AllApi";
import toast, { Toaster } from "react-hot-toast";
import {
  decryptData,
  Domain_Url,
  isAuthenticated,
  isUserId,
} from "../components/Url/Url";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string()
    .min(2, "Name must be at least 2 characters")
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  mobile: Yup.string()
    .required("Mobile number is required")
    .matches(/^[0-9]{10}$/, "Invalid mobile number"),
  subject: Yup.string().required("Subject is required"), // Add subject to validation
  description: Yup.string().required("Description is required"),
});

const Generateticket = () => {
  const token = isAuthenticated; // Use `isAuthenticated` as a value
  const userId = isUserId; // Fetch the user ID
  const navigate = useNavigate();
  // Redirect if token or userId is null
  useEffect(() => {
    if (token === null || userId === null) {
      navigate("/");
    }
  }, [token, userId, navigate]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    subject: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
      const response = await generateTicketApi(formData);
      
      if (response.error) {
        throw new Error(response.error);
      }
      toast.success("Ticket created successfully!");
      setFormData({
        name: "",
        email: "",
        mobile: "",
        subject: "",
        description: "",
      });
    } catch (error) {
  
      toast.error("Failed to create ticket. Please try again.");
    }
  };
  const HandleCreateTicket = async (values, actions) => {
    try {
      

      const token = isAuthenticated; // Ensure this returns a value
      if (!token) {
        throw new Error("User not authenticated");
      }

      const formData = new FormData(); // FormData for file uploads, if any

      // Append fields to FormData
      formData.append("name", values.name);
      formData.append("mobile", values.mobile);
      formData.append("email", values.email);
      formData.append("subject", values.subject);
      formData.append("description", values.description);

      // Loop through and log FormData key-value pairs for debugging
      for (let [key, value] of formData.entries()) {
      
      }

      // API call
      const res = await generateTicketApi(formData);
      actions.setSubmitting(false); // Stop the submitting process

      // Check API response and show appropriate messages
      if (res.success === true) {
        toast.success("Ticket created successfully!");
        actions.resetForm(); // Reset the form after success
      } else if (res.success === false && res.message) {
        toast.error(res.message); // Show the error message from the API
      } else {
        throw new Error(res?.error || "Something went wrong.");
      }
    } catch (error) {
     
      toast.error("Failed to create ticket. Please try again.");
      actions.setSubmitting(false);
    }
  };

  return (
    <>
        
      <section className="generateticket_section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="flex_generating_tkt">
                <h3>New Ticket</h3>
                <Link to="/ticket">My Ticket</Link>
              </div>
            </div>

            <div className="col-lg-6 col-md-8 col-sm-12 col-12">
              <div className="generating_form_div">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    name: "",
                    email: "",
                    mobile: "",
                    subject: "", // Added subject field to match validationSchema
                    description: "",
                    gender: "", // Added gender to match the form
                  }}
                  validationSchema={validationSchema}
                  onSubmit={HandleCreateTicket}
                >
                  {({ isSubmitting, values, handleChange, setFieldValue }) => (
                    <Form>
                      <div>
                        <div className="row ">
                          <div className="col-md-12 mt-1">
                            <label className="form-label">
                              Name <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="w-100">
                              <Field
                                type="text"
                                name="name"
                                className="p-0 px-2 m-0"
                                placeholder="Enter Name"
                                style={{ height: "40px", width: "100%" }}
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 mt-1">
                          <label>
                            <span>Email</span>
                            <span className="ps-1">(optional)</span>
                          </label>
                          <div className="w-100">
                            <Field
                              type="email"
                              name="email"
                              className="p-0 px-2 m-0"
                              placeholder="Enter your email"
                              style={{ height: "40px", width: "100%" }}
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="col-md-12 mt-1">
                            <label>
                              <span>Mobile</span>
                              <span className="text-danger ps-1">*</span>
                            </label>
                            <div className="w-100">
                              <Field
                                type="text"
                                name="mobile"
                                className="p-0 px-2 m-0"
                                placeholder="Mobile Number"
                                style={{ height: "40px", width: "100%" }}
                              />
                              <ErrorMessage
                                name="mobile"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="col-md-12 mt-1">
                            <label>
                              <span>Subject</span>
                              <span className="text-danger ps-1">*</span>
                            </label>
                            <div className="w-100">
                              <Field
                                as="select"
                                name="subject"
                                style={{ height: "40px", width: "100%" }}
                              >
                                <option value="" disabled>
                                  Select your issue
                                </option>
                                <option value="First Free Session">
                                  First Free Session
                                </option>
                                <option value="Too much wait time">
                                  Too much wait time
                                </option>
                                <option value="Unhappy with the free session">
                                  Unhappy with the free session
                                </option>
                                <option value="Missed the session">
                                  Missed the session
                                </option>
                                <option value="Payment Related">
                                  Payment Related
                                </option>
                                <option value="Previous Order">
                                  Previous Order
                                </option>
                                <option value="Astrologer didn't answer">
                                  Astrologer didn't answer
                                </option>
                                <option value="Got a different prediction">
                                  Got a different prediction
                                </option>
                                <option value="Remedies Issue">
                                  Remedies Issue
                                </option>
                                <option value="Technical issue">
                                  Technical issue
                                </option>
                                <option value="Offers & Coupons">
                                  Offers & Coupons
                                </option>
                                <option value="General FAQs">
                                  General FAQs
                                </option>
                                <option value="Some other issues">
                                  Some other issues
                                </option>
                              </Field>
                              <ErrorMessage
                                name="subject"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 mt-1">
                            <label>
                              <span>Description</span>
                              <span className="ps-1">(optional)</span>
                            </label>
                            <div className="w-100">
                              <Field
                                as="textarea" // Change from 'type="text"' to 'as="textarea"'
                                name="description"
                                className="p-0 px-2 m-0"
                                placeholder="Enter description"
                                style={{ height: "100px", width: "100%" }} // You can adjust the height as needed
                              />
                              <ErrorMessage
                                name="description"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="generating_btn mt-2">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              Create Ticket
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>

        
      <Toaster />
    </>
  );
};

export default Generateticket;
