import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getDecryptedCookie,
  isAuthenticated,
  isUserId,
  setEncryptedCookie,
} from "../components/Url/Url";
import {
  calculateTzApi,
  deleteKundaliApi,
  EditKundaliApi,
  getPlaceOfBirth,
  saveKundaliApi,
} from "../AllApi/AllApi";
import { OpenSaveChartApi } from "./AllapiData";
import Login from "../components/Login/Login";
import { toast } from "react-toastify";
import MyContext from "../Context/MyContext";

const MoreOptions = () => {
  const contextData = useContext(MyContext);
  const loginRes =
    contextData?.userDetails

 
  const locationD = useLocation();
  const [showLoginModal, setShowLoginModal] = useState();
  function toggleLoginModal() {
    setShowLoginModal(!showLoginModal);
  }
  // State to manage visibility of the more options
  const [showOptions, setShowOptions] = useState(false);
  const menuRef = useRef(null);
  // Function to toggle the visibility of more options
  const toggleOptions = () => {
    setShowOptions((prevState) => !prevState);
  };

  const kundaliDataReport = getDecryptedCookie("kimf");
 
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };
  function editChart() {
    window.location.reload();
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Your logic for handling clicks outside
    };
  
    // Add event listener for click events
    document.addEventListener("click", handleClickOutside, { capture: true, passive: true });
  
    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside, { capture: true, passive: true });
    };
  }, []);
  

  const [placeOfBirth, setPlaceOfBirth] = useState("");

  const [latForKundli, setLatForKundli] = useState(kundaliDataReport?.latForKundli);
  const [lonForKundli, setLonForKundli] = useState(kundaliDataReport?.lonForKundli);
  const [city, setCity] = useState(kundaliDataReport?.city,
  );
  const [PlaceTimeZone, setPlaceTimeZone] = useState(kundaliDataReport?.timezone);
  const [latLongData, setlatLongData] = useState(
    {
      latitude: kundaliDataReport?.latitude || null,
      longitude: kundaliDataReport?.longitude || null,
    }
  );

   const [cityId, setCityId] = useState(kundaliDataReport?.cityId);
  
  const [placeList, setPlaceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const date = new Date();
  const defaultKundaliData = {
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds(),
    day: date.getDate(),
    month: date.getMonth() + 1, // Months are 0-indexed, so we add 1
    year: date.getFullYear(),
  };
  const [kundaliData, setKundaliData] = useState({
    title:kundaliDataReport?.title,
    hours: kundaliDataReport?.hours,
    Name: kundaliDataReport?.Name,
    gender: kundaliDataReport?.gender,
    seconds: kundaliDataReport?.seconds,
    minutes: kundaliDataReport?.minutes,
    day: kundaliDataReport?.day,
    year: kundaliDataReport?.year,
    month: kundaliDataReport?.month,
    latitude: kundaliDataReport?.latForKundli || 12.9716, // Default latitude
    longitude: kundaliDataReport?.lonForKundli || 77.5946, // Default longitude
    placeofbirthcity:kundaliDataReport?.city || 110043
    // savedId:kundaliDataReport?.savedId || 3.3
  });
 
  const navigate = useNavigate();
  const [isFormComplete, setIsFormComplete] = useState(false);
  // Function to get Ayanamsa (example placeholder)
  const getAyanamsa = (latitude, longitude) => {
    // Implement the logic or use an API to get Ayanamsa
    return "N.C. LAHIRI 24°12'0\""; // Replace with actual Ayanamsa calculation or API call
  };
  const now = new Date();
  const defaultKundali = {
    title: "Mr",
    hours: now.getHours(),
    minutes: now.getMinutes(),
    seconds: now.getSeconds(),
    day: now.getDate(),
    month: now.getMonth() + 1, // Months are 0-indexed, so we add 1
    year: now.getFullYear(),
  };

  // Function to format timezone as GMT offset
  const getFormattedTimezone = () => {
    const offset = new Date().getTimezoneOffset() / 60; // offset in hours
    const sign = offset > 0 ? "-" : "+";
    const absOffset = Math.abs(offset);
    const hours = Math.floor(absOffset);
    const minutes = (absOffset - hours) * 60;
    return `GMT ${sign}${hours}${minutes ? `:${minutes}` : ""}`;
  };
  useEffect(() => {
    if (loginRes) {
      setKundaliData(prevData => ({
        ...prevData,
        title: kundaliDataReport?.title || loginRes?.title || prevData.title,
        hours: kundaliDataReport?.hours || loginRes?.hour || prevData.hours,
        Name: kundaliDataReport?.Name || loginRes?.name || prevData.Name,
        gender: kundaliDataReport?.gender || loginRes?.gender || prevData.gender,
        seconds: kundaliDataReport?.seconds || loginRes?.second || prevData.seconds,
        minutes: kundaliDataReport?.minutes || loginRes?.minute || prevData.minutes,
        day: kundaliDataReport?.day || loginRes?.day || prevData.day,
        year: kundaliDataReport?.year || loginRes?.year || prevData.year,
        month: kundaliDataReport?.month || loginRes?.month || prevData.month,
        latitude: kundaliDataReport?.latForKundli || loginRes?.latitude || prevData.latitude,
        longitude: kundaliDataReport?.lonForKundli || loginRes?.longitude || prevData.longitude,
        placeofbirthcity: kundaliDataReport?.city || loginRes?.PlaceOfBirth || prevData.placeofbirthcity,
        cityId: kundaliDataReport?.cityId || loginRes?.place_of_birth || prevData.cityId,
      }));
  
   
      const selectedData = {
        id: kundaliDataReport?.cityId || loginRes?.place_of_birth || null,
        city:kundaliDataReport?.city || loginRes?.PlaceOfBirth || null,
      
  
        latitude: kundaliDataReport?.latForKundli || loginRes?.latitude || null,
        longitude: kundaliDataReport?.lonForKundli || loginRes?.longitude || null,
      };
      const cityName = selectedData?.city;
      
  
      // Store the formatted city, state, country string
      const formattedCity = cityName
  
      setCity(formattedCity); // Set the city as a formatted string
      setCityId(selectedData.id); // Set the city ID
      setPlaceList([]); // Clear the list after selection
      setLatForKundli(selectedData.latitude); // Set the latitude for Kundli
      setLonForKundli(selectedData.longitude); // Set the longitude for Kundli
      setlatLongData(selectedData); // Set lat/long data
  
      timeZoneApi(selectedData.longitude, selectedData.latitude); // Call timezone API
    }
  }, [loginRes]); // Include kundaliDataReport if it can change
  
  // Function to handle changes and update data
  const kundaliDataChange = (e) => {
    const { name, value } = e.target;

    // Update Kundali data with input changes
    setKundaliData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };

      // Set default values for missing fields
      return {
        Name: updatedData.Name || "",
        title:updatedData.title || "",
        gender: updatedData.gender || "",
        day: updatedData.day || 1,
        month: updatedData.month || 1,
        year: updatedData.year || 1900,
        hours: updatedData.hours || 13,
        minutes: updatedData.minutes || 7,
        seconds: updatedData.seconds || 55,
        latitude: updatedData.latitude || 12.9716,
        place: updatedData.place,
        longitude: updatedData.longitude || 77.5946,
        timezone: updatedData.timezone || 5.5,
        ayanamsa: updatedData.ayanamsa || "ayanamsa",
      };
    });

    // Extract updated values to check completeness
    const { day, month, year, hours, minutes, seconds } = {
      ...kundaliData,
      [name]: value, // include the latest change
    };

    if (
      day &&
      month &&
      year &&
      hours !== undefined &&
      minutes !== undefined &&
      seconds !== undefined
    ) {
      // All required fields are filled, proceed with additional operations

      // Get timezone in GMT format
      const timezone = getFormattedTimezone();

      // Get latitude and longitude using Geolocation API
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          // Get Ayanamsa based on latitude and longitude
          const ayanamsa = getAyanamsa(latitude, longitude);

          // Update kundaliData with latitude, longitude, timezone, and ayanamsa
          setKundaliData((prevData) => ({
            ...prevData,
            latitude,
            longitude,
            timezone,
            ayanamsa,
          }));
        },
        (error) => {
           
          // Handle error or fallback here
        }
      );
    } else {
      // Not all fields are filled
       
    }

    // Check if form completion
    checkFormCompletion();
  };

  const formatAmount = (amount) => {
    if (isNaN(amount) || amount === null || amount === undefined) {
      return '-';
    }
    return new Intl.NumberFormat("en-IN").format(amount);
  };
  

  // Function to check if the form is complete
  const checkFormCompletion = () => {
    // , 'gender', 'day', 'month', 'year', 'hours', 'minutes', 'seconds', 'PlaceOfBirth'
    const requiredFields = ["title", "name"];
    const isComplete = requiredFields.every((field) => kundaliData[field]);
    setIsFormComplete(isComplete);
  };

  // Function to generate options
  const generateOptions = (start, end) => {
    return Array.from({ length: end - start + 1 }, (_, i) => (
      <option key={i + start} value={i + start}>
        {i + start}
      </option>
    ));
  };

  // Function to handle the generation of Kundali report
  const generateEditKundali = async (e) => {
    e?.preventDefault();
  
    // Check if kundaliDataReport has a savedId
    if (!kundaliDataReport?.savedId) {
      // Set the encrypted cookie with necessary data
      setEncryptedCookie("kimf", {
        ...kundaliData,
        cityId,
        city,
        lonForKundli,
        latForKundli,
        timezone: PlaceTimeZone,
        latitude: latLongData?.latitude || null,
        longitude: latLongData?.longitude || null,
      });
  
      // Show success toast
      toast.success('Successfully updated');
      
      // Reload the page after 1 second
      setTimeout(() => {
        window.location.reload();
      }, 1000);
  
    } else {
      // If the user is authenticated
      const userid = isUserId; // Assuming you have isUserId defined
      const savedId = kundaliDataReport?.savedId; // Assuming kundaliDataReport is not null
  
      const res = {
        ...kundaliData,
        userid,
        cityId,
        city,
        lonForKundli,
        latForKundli,
        savedId,
      };
  
      const token = isAuthenticated; // Assuming the token comes from authentication
  
      try {
        // Make API call to update kundali
        const res2 = await EditKundaliApi(res, token); 
        
        // Handle successful API call
        if (res2) {
          toast.success('Kundali updated successfully');
          window.location.reload();
        }
        
      } catch (error) {
        // Handle error while editing kundali
        toast.error('An error occurred while editing kundali.');
      }
    }
  };
  
  



  const timeZoneApi = async (longitude, latitude) => {
    try {
      const data = { lonForKundli: longitude, latForKundli: latitude };
       
      const res = await calculateTzApi(data);
       

      if (res?.status === true) {
        setPlaceTimeZone(res?.timezone); // Update state
        return res?.timezone; // Return timezone
      }

      return null;
    } catch (error) {
       
      return null;
    }
  };
  async function generateViewKundali(event, ele) {
    event.preventDefault(); // Prevent default button behavior

    // Wait for the timezone API to finish and get the result
    const timez = await timeZoneApi(ele?.longitude, ele?.latitude);

    if (timez) {
       
    } else {
       
    }

     

    // Prepare the object for the cookie
  
    const obj = {
      Name: ele?.name,
      ayanamsa: "ayanamsa",
      city: ele?.PlaceOfBirth,
      cityId: ele?.place_of_birth,
      day: ele?.day,
      gender: ele?.gender || "male",
      hours: ele?.hour,
      latForKundli: ele?.latitude || 5.66,
      latitude: 12.9716,
      lonForKundli: ele?.longitude || 5.55,
      longitude: 77.5946,
      minutes: ele?.minute,
      month: ele?.month,
      seconds: ele?.second,
      timezone: timez || 5.5, // Use the fetched timezone
      title: ele?.title || "Ms",
      year: ele?.year,
      savedId:ele?.id
    };

    // Set the encrypted cookie and navigate
    setEncryptedCookie("kimf", { ...obj });

    if (location.pathname === "/free-kundli-online") {
      navigate("/free-kundli-report"); // Navigate to '/freekundalireport'
      window.location.reload();
    } else {
      navigate("/free-kundli-report"); // Navigate to '/freekundalireport'
      window.location.reload();
    }
  }

  async function generateEditKundaliApi(event, ele) {
    event.preventDefault(); // Prevent default button behavior

    // Wait for the timezone API to finish and get the result
    const timez = await timeZoneApi(ele?.longitude, ele?.latitude);

    if (timez) {
       
    } else {
       
    }

     

    // Prepare the object for the cookie
    const obj = {
      Name: ele?.name,
      ayanamsa: "ayanamsa",
      city: ele?.PlaceOfBirth,
      cityId: ele?.cityId,
      day: ele?.day,
      gender: ele?.gender || "male",
      hours: ele?.hour,
      latForKundli: ele?.latitude || 5.66,
      latitude: 12.9716,
      lonForKundli: ele?.longitude || 5.55,
      longitude: 77.5946,
      minutes: ele?.minute,
      month: ele?.month,
      seconds: ele?.second,
      timezone: timez || 5.5, // Use the fetched timezone
      title: ele?.title || "Ms",
      year: ele?.year,
      savedId:ele?.id
    };

    // Set the encrypted cookie and navigate
    setEncryptedCookie("kimf", { ...obj });

    if (location.pathname === "/free-kundli-online") {
      navigate("/free-kundli-report"); // Navigate to '/free-kundli-report'
      window.location.reload();
    } else {
      navigate("/free-kundli-report"); // Navigate to '/freekundalireport'
      window.location.reload(); // Reload after navigating to '/freekundalireport'
    }
  }

  const deleteKundali = async (id) => {
    try {
      const token = isAuthenticated;
      const res = await deleteKundaliApi(id, token);
      setTimeout(() => {
        window.location.reload();
      }, 2000);

      // Optionally, update state or perform any other action upon successful deletion
    } catch (error) {
       
      // Handle error if needed
    }
  };

  // Example months array
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let debounceTimeout;

  const handleCityChange = (event) => {
    const newCity = event.target.value;

    // Clear previous debounce
    clearTimeout(debounceTimeout);

    // Update city and clear the place list
    setCity(newCity);
    setPlaceList([]);

    if (newCity.trim().length >= 3) {
      // Check if the input length is at least 3 characters
      // Set up debounce
      debounceTimeout = setTimeout(async () => {
        setLoading(true);
        setError(""); // Reset error state

        try {
          const result = await getPlaceOfBirth(newCity);
          setPlaceOfBirth(newCity); // Update the placeOfBirth with the current input

          if (result?.data && result.data.length > 0) {
            setPlaceList(result.data); // Update place list with API response if data exists
          } else {
            setPlaceList([]); // Clear the list if no data is returned
          }
        } catch (err) {
          setError("Failed to fetch place of birth");
           
          setPlaceList([]); // Clear the list on error
        } finally {
          setLoading(false);
        }
      }, 300); // Adjust the delay (300ms is common, you can tweak this)
    } else {
      // Clear placeOfBirth if input is less than 3 characters
      setPlaceOfBirth("");
      setPlaceList([]); // Clear place list if input is less than 3 characters
    }
  };
  const handleOptionClick = (data) => {
    const cityName = data?.name;
    const stateName = data?.state_name;
    const countryName = data?.country_name;

    // Store the formatted city, state, country string
    const formattedCity = `${cityName}, ${stateName}, ${countryName}`;

    setCity(formattedCity); // Set the city as a formatted string
    setCityId(data?.id); // Set the city ID
    setPlaceList([]); // Clear the list after selection
    setLatForKundli(data?.latitude);
    setLonForKundli(data?.longitude);
    setlatLongData(data);
     

    timeZoneApi(data?.longitude, data?.latitude);
  };

  
  const [cityNameData,  setCityNameData] = useState("");




  function submitKundali() {}

  //
  const [opensavechart, setopensavechart] = useState("");

 
    const fetchOpensavechart = async () => {
       
      const user = isUserId;
      const token = isAuthenticated;
      const data = { user, token };
       
      try {
        const res = await OpenSaveChartApi(data);
         
        if (res?.success === true) {
          setopensavechart(res?.data);
        } else {
           
        }
      } catch (error) {
         
      }
    };

  
  // astrologer services api end
  const [calculatedTimeZoneData, setCalculatedTimeZoneData] = useState();
  

  const [SavedkundliData, setSavedkundliData] = useState("");

  useEffect(() => {
    setSavedkundliData(kundaliDataReport);
  }, []);

   
  async function calculateTimeZoneForBoy(lat, lon, date) {
    const reqSendBoyData = { lat, lon, date };
     
    const res = await calculateTzApi(reqSendBoyData);
     
    setCalculatedTimeZoneData(res);
  }
  async function handlesaveKundli() {
     
  
    // Extracting data from the decrypted cookie
    const hours = kundaliDataReport?.hours ?? "02";
    const minutes = kundaliDataReport?.minutes ?? "01";
    const seconds = kundaliDataReport?.seconds ?? "02";
    const year = kundaliDataReport?.year;
    const month = kundaliDataReport?.month;
    const day = kundaliDataReport?.day;
    const latForKundliData = kundaliDataReport?.latForKundli;
    const lonForKundliData = kundaliDataReport?.lonForKundli;
  
    // Formatting the time and date
    const timeDay = `${hours}:${minutes}:${seconds}`;
    const dateDay = `${day}/${month}/${year}`;
  
    // User and token data
    const user = isUserId;
    const token = isAuthenticated;
    const userdata = { user, token };
  
    // Data to send in the API request
    const res2ReqSend = {
      day: day,
      month: month,
      year: year,
      seconds: seconds,
      minutes: minutes,
      hours: hours,
      Name: kundaliDataReport?.Name,
      pob: kundaliDataReport?.city,
      gender: kundaliDataReport?.gender,
      placeofbirthcity: kundaliDataReport?.city,
      placeofBirth: kundaliDataReport?.cityId,
      lat: latForKundliData,
      lon: lonForKundliData,
      tzone: calculatedTimeZoneData?.timezone ?? "5.5", // Assuming default timezone if missing
      userdata: userdata,
      latitude: latForKundliData,  // Correct latitude value from the report
      longitude: lonForKundliData, // Correct longitude value from the report
      timezone:kundaliDataReport?.timezone
    };
  
    try {
      // Debugging lat/lon
       
       
  
      // Making the API call
      const res = await saveKundaliApi(res2ReqSend);
  
      // Logging the response and refreshing the page
       
      window.location.reload();
    } catch (error) {
       
    }
  }

  function handleNoKundli() {
    window.location.reload();
  }

  //

  return (
    <>
      {/*  */}
      <div>
        <button className="more-option-toggle" onClick={toggleOptions}>
          <i className="fas fa-list" />
        </button>

        {showOptions && (
          <div className="free-kundli-more-option" ref={menuRef}>
            {/*  */}
            <Link to="/free-kundli-online">
              <button onclick="">
                <i className="fas fa-file" />{" "}
                <div className="more-option-tool-tip"> New Chart</div>
              </button>
            </Link>
            {/*  */}

             
 {locationD.pathname != '/free-kundli-online' &&<>
  {<button data-bs-toggle="modal" data-bs-target="#editchartmd" >
    <i className="fa fa-pencil-square" />
    <div className="more-option-tool-tip">
    Edit Chart
    </div>
  </button>}
 </>
 }
 

             

 

            {/*  */}
            {locationD.pathname != "/free-kundli-online" && (
              <>
                {!isAuthenticated ? (
                  <button onClick={toggleLoginModal}>
                    <i className="fa fa-floppy-o" />
                    <div className="more-option-tool-tip">Save Chart</div>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#savechartmd"
                  >
                    <i className="fa fa-floppy-o" />
                    <div className="more-option-tool-tip">Save Chart</div>
                  </button>
                )}
              </>
            )}
            {/*  */}

            {/*  */}
            {!isAuthenticated ? (
              <button onClick={toggleLoginModal}>
                <i className="fas fa-folder-open" />
                <div className="more-option-tool-tip">Open Chart</div>
              </button>
            ) : (
              <button
                type="button"
                onClick={fetchOpensavechart}
                data-bs-toggle="modal"
                data-bs-target="#openKundli"
              >
                <i className="fas fa-folder-open" />
                <div className="more-option-tool-tip">Open Chart</div>
              </button>
            )}

            {/*  */}
          </div>
        )}
      </div>

      {/*  */}

      {/* OPEN CHARTS POP UP start */}
      <div className="openkd_modal_div_m">
        <div className="modal fade" id="openKundli" tabIndex={-1}>
          <div className="modal-dialog modal-xl modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  <i className="fas fa-folder-open" /> Open Chart
                </h5>
                <button
                  type="button"
                  className="fas fa-close save-kundli-close"
                  data-bs-dismiss="modal"
                />
              </div>
              <div className="modal-body">
                <div className="kundli_modal_table">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th>S.no.</th>
                        <th>Name</th>
                        <th>Gender</th>
                        <th>DOB</th>
                        <th>Time</th>
                        <th>Place</th>
                        <th>Saved On</th>
                        <th colSpan={3}>Open Chart</th>
                      </tr>
                      {Array.isArray(opensavechart) &&
                      opensavechart.length > 0 ? (
                        opensavechart?.map((ele, index) => (
                          <tr
                            key={index}
                            onclick={() => generateViewKundali(ele)}
                          >
                            <td>{index+1}</td>
                            <td>{ele?.name}</td>
                            <td>{ele?.gender}</td>
                            <td>
                              {ele?.day}/{ele?.month}/{ele?.year}
                            </td>
                            <td>
                              {ele?.hour}:{ele?.minute}:{ele?.second}
                            </td>
                            <td>{ele?.PlaceOfBirth}</td>
                            <td>{ele?.updated_at}</td>
                            <td className="view_btn">
                              {" "}
                              <button
                                onClick={(e) => generateViewKundali(e, ele)}
                              >
                                View
                              </button>
                            </td>

                           
                            <td className="view_btn">
                              {" "}
                              <button onClick={() => deleteKundali(ele?.id)}>
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="9">No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Open Chart end modal  */}

      {/* Save Chart start modal  */}
      <div className="openkd_modal_div_m">
        <div className="modal fade" id="savechartmd" tabIndex={-1}>
          <div className="modal-dialog modal-md modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  <i className="fas fa-folder-open" /> Save Chart
                </h5>
                <button
                  type="button"
                  className="fas fa-close save-kundli-close"
                  data-bs-dismiss="modal"
                />
              </div>
              <div className="modal-body">
                <div className="save_chart_div_m">
                  <p>do you want to save this chart?</p>
                  <div className="save_btn_div">
                    <button
                      className="btn btn-md"
                      onClick={() => handlesaveKundli()}
                    >
                      yes
                    </button>
                    <button
                      type="submit"
                      className="btn btn-md"
                      data-bs-dismiss="modal"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
      {/* Save Chart end modal  */}

      {/* Edit Chart start modal  */}
      <div className="openkd_modal_div_m">
        <div className="modal fade" id="editchartmd" tabIndex={-1}>
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  <i className="fas fa-folder-open" /> Edit Chart
                </h5>
                <button
                  type="button"
                  className="fas fa-close save-kundli-close"
                  data-bs-dismiss="modal"
                />
              </div>
              <div className="modal-body">
              <form
                  action="/free-kundli-online"
                  method="POST"
                  id="myForm"
                  autofill="off"
                >
                  <div className="kundlibirth_form_main_div">
                    {/* nested row start */}
                    <div className="row">
                     
                      {/* nested col start */}
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <label>Name</label>
                        <div className="input-group mb-3">
  <select
    className="slct_form"
    name="title"
    value={kundaliData?.title || ""}
    onChange={kundaliDataChange}
    id="inputGroupSelect01"
  >
    <option value="">Select One</option>
    <option value="Mr">Mr.</option>
    <option value="Ms">Ms.</option>
    <option value="Mrs">Mrs.</option>
  </select>
  
  <input
    type="text"
    className="form-control"
    id="name"
    name="Name"
    value={kundaliData?.Name || ""}
    onChange={kundaliDataChange}
    placeholder="Name"
    required
  />
</div>

                      </div>
                      {/* nested col end */}
                      {/* nested col start */}
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
<label>Gender</label>
<select
  id="inputState"
  onChange={kundaliDataChange}
  value={kundaliData?.gender || ""}
  className="form-select"
  name="gender"
  required
>
  <option value="">Select Gender</option>
  <option value="male">Male</option>
  <option value="female">Female</option>
</select>
</div>

                      {/* nested col end */}
                      {/* nested col start */}
                      <div className="col-md-6">
                        <label>Date Of Birth</label>
                        <div className="dob-inputs">
                          <select
                            className="form-select"
                            name="day"
                            value={kundaliData?.day}
                            onChange={kundaliDataChange}
                            required
                          >
                            <option value="" disabled>
                              Day
                            </option>
                            {generateOptions(1, 31)}
                          </select>

                          <select
                            className="form-select"
                            name="month"
                            value={kundaliData?.month}
                            onChange={kundaliDataChange}
                            required
                          >
                            <option value="" disabled>
                              Month
                            </option>
                            {months.map((month, index) => (
                              <option key={index + 1} value={index + 1}>
                                {month}
                              </option>
                            ))}
                          </select>

                          <select
                            className="form-select"
                            name="year"
                            value={kundaliData?.year}
                            onChange={kundaliDataChange}
                            required
                          >
                            <option value="" disabled>
                              Year
                            </option>
                            {generateOptions(1900, 2024)}
                          </select>
                        </div>
                      </div>
                      {/* nested col end */}

                      {/* nested col start */}
                      <div class="col-md-6">
                        <label>Time Of Birth (24 Hour Format)</label>
                        <div class="tob-inputs">
                          <select
                            class="form-select"
                            name="hours"
                            value={kundaliData?.hours}
                            onChange={kundaliDataChange}
                            required
                          >
                            <option disabled>Hrs.</option>
                            {/* <!-- Generate hours from 0 to 23 --> */}
                            {[...Array(24).keys()].map((hour) => (
                              <option
                                value={hour}
                                key={hour}
                                selected={hour === 13}
                              >
                                {hour}
                              </option>
                            ))}
                          </select>

                          <select
                            class="form-select"
                            name="minutes"
                            value={kundaliData?.minutes}
                            onChange={kundaliDataChange}
                            required
                          >
                            <option disabled>Mins.</option>
                            {/* <!-- Generate minutes from 0 to 59 --> */}
                            {[...Array(60).keys()].map((minute) => (
                              <option
                                value={minute}
                                key={minute}
                                selected={minute === 7}
                              >
                                {minute}
                              </option>
                            ))}
                          </select>

                          <select
                            class="form-select"
                            name="seconds"
                            value={kundaliData?.seconds}
                            onChange={kundaliDataChange}
                            required
                          >
                            <option disabled>Secs.</option>
                            {/* <!-- Generate seconds from 0 to 59 --> */}
                            {[...Array(60).keys()].map((second) => (
                              <option
                              
                                key={second}
                                selected={second === 55}
                              >
                                {second}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      {/* nested col end */}

                      {/* nested col start */}

                      <div className="col-md-12">
                        <div className="placbirth_parent_div">
                          <label htmlFor="placeOfBirth">Place Of Birth</label>
                          <div className="placeOfBirthInputDiv">
                            <input
                              type="text"
                              className="form-control"
                             
                              value={city==null?kundaliData?.placeofbirthcity:city}
                              onChange={handleCityChange}
                              placeholder="Place Of Birth *"
                              name="placeofbirthcity"
                              id="placeOfBirth"
                              required
                              autoComplete="off"
                              aria-haspopup="true"
                              aria-expanded={placeList?.length > 0}
                              aria-controls="placeOfBirthList"
                            />
                            <input
                              type="hidden"
                              name="placeofbirth"
                              id="city_id"
                              value={cityId || ""}
                              required
                            />
                            {placeList?.length > 0 && (
                              <div
                                id="placeOfBirthList"
                                className="placeOfBirthList scroll-style list_div_sug"
                                role="listbox"
                              >
                                {placeList.map((option) => (
                                  <div
                                    key={option.id}
                                    className="placeOfBirthListItem"
                                    onClick={() => handleOptionClick(option)}
                                    role="option"
                                    aria-selected={cityId === option.id}
                                  >
                                    {" "}
                                    {option.name} {option.state_name}{" "}
                                    {option.country_name}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* nested col end */}

                      {/* nested col start */}
                      <div className="col-12">
                        <div
                          className={`horo-btn ${
                            !isFormComplete ? "disabled" : ""
                          }`}
                        >
                          <button
                            type="submit"
                           
                            onClick={generateEditKundali}
                          >
                            Edit Kundli
                          </button>
                        </div>
                      </div>

                      {/* nested col end */}
                    </div>
                    {/* nested row end */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Edit Chart end modal  */}
      {showLoginModal && <Login closeModal={toggleLoginModal} />}
    </>
  );
};

export default MoreOptions;
