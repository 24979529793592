import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../CssFiles/contactus.css';
import LoadingPolicies from '../components/LoadingComponent/LoadingPolicies';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { contactUsApi } from '../AllApi/AllApi'; // Import the API function

const Contactus = () => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  useEffect(() => {
    setLoading(false);
  }, []);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    const re = /^\+?\d{10}$/;
    return re.test(String(phone));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, email, phone, message } = formData;

    if (name.length < 3) {
      toast.error('Name must be at least 3 characters long');
      return;
    }

    if (!validateEmail(email)) {
      toast.error('Invalid email address');
      return;
    }

    if (!validatePhone(phone)) {
      toast.error('Invalid phone number');
      return;
    }

    if (message.trim() === '') {
      toast.error('Message cannot be empty');
      return;
    }

     

    try {
      const response = await contactUsApi(formData);

      if (response.error) {
        throw new Error(response.error);
      }

      toast.success('Message sent successfully!');
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: ''
      });
    } catch (error) {
      toast.error('Failed to send message. Please try again.');
    }
  };

  return (
    <>
    
        
      {!loading ? (
        <section className="contact_us_page_section">
          <div className="container">
            <div className="contact_page_title_div">
              <h2>Get In Touch With <span>Talk ND Heal</span></h2>
            </div>
            <div className="row">
              <div className="col-lg-5 col-md-6 col-sm-12 col-12">
                <div className="main_div_contactus_page">
                  <div className="contact_us_flx_div_main">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        {/* <i className="bx bxs-phone-call" /> */}
                      </div>
                      {/* <div className="flex-grow-1 ms-3"> */}
                        {/* <div className="contact_page_num_div">
                          <b>Mobile No.:</b>
                          <a href="tel:+91 9876543210">  +91 9876543210</a>
                        </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="contact_us_flx_div_main">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <i className="bx bx-mail-send" />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <div className="contact_page_num_div">
                          <b>E-Mail ID:</b>
                          <a href="mailto:contact@talkndheal.com">contact@talkndheal.com</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="contact_us_flx_div_main">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <i className="bx bxs-map-pin" />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <div className="contact_page_num_div">
                          <b>Address:</b>
                          <p>308, Tower B, Pioneer Urban Square, Golf Course Extn., Sector - 62, Gurugram, Haryana - 122002 (INDIA)</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-6 col-sm-12 col-12">

              <p>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1754.5741576336002!2d77.08714888865661!3d28.41478124394619!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d22103423f609%3A0x2ac39834101fc03f!2sPioneer%20Urban%20Square%2C%20Sector%2062%2C%20Ghata%2C%20Gurugram%2C%20Haryana%20122102!5e0!3m2!1sen!2sin!4v1725626767010!5m2!1sen!2sin"
                  height="300px"
                  width="100%"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </p>
                {/* <div className="contact_page_form">
                  <form method="POST" className="contact-form" autoComplete="off" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="input-group mb-3">
                          <span className="input-group-text fa fa-user" />
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Name*"
                            required
                            minLength={3}
                            value={formData.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="input-group mb-3">
                          <span className="input-group-text fa fa-envelope" />
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Email Id*"
                            minLength={11}
                            required
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="input-group mb-3">
                          <span className="input-group-text fa fa-phone" />
                          <input
                            type="tel"
                            className="form-control"
                            minLength={10}
                            maxLength={10}
                            name="phone"
                            placeholder="Mobile No.*"
                            required
                            value={formData.phone}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="input-group">
                          <span className="input-group-text fa fa-commenting" />
                          <textarea
                            name="message"
                            className="form-control"
                            placeholder="Message*"
                            required
                            value={formData.message}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-2">
                        <div className="conatct_page_btn_div">
                          <button type="submit" className="btn btn-md">Send Message</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div> */}
              </div>
            </div>
            <br />
            <div className="col-lg-12">
              
            </div>
          </div>
        </section>
      ) : (
        <LoadingPolicies />
      )}
        
      <ToastContainer />
    </>
  );
};

export default Contactus;